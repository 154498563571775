import AttributePicker from "components/AttributePicker/AttributePicker"
import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import Button from "components/UI/elements/Button/Button"
import FileField from "components/UI/elements/FileField/FileField"
import Paper from "components/UI/elements/Paper"
import Page from "components/UI/Page/Page"
import { jsonFile, maxFileSize } from "helpers/validators.helper"
import { useForm, Controller } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { Attribute } from "resources/attribute/attributeTypes"
import {
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationChannel,
} from "resources/channel/channelTypes"
import { getRoutePath } from "routes"
import styles from "./PushNotificationsChannel.module.scss"
import {
  useCreatePushNotificationApp,
  useDeletePushNotificationApp,
  useFetchPushNotificationApps,
  useFetchPushNotificationFirebaseApps,
  useModifyPushNotificationApp,
} from "resources/channel/channelQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Badge from "components/UI/elements/Badge/Badge"
import { useState } from "react"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { ApplicationModal } from "./ApplicationModal/ApplicationModal"
import { featureFlags } from "featureFlags"

export type PushNotificationsChannelFormValues = {
  firebaseFileList: FileList | null
  registrationTokenId: Attribute["id"] | null
}

const MAX_UPLOAD_BYTES = 2 * 1024 * 1024

type PushNotificationsChannelFormProps = {
  channel: PushNotificationChannel
  onSubmit: (values: PushNotificationsChannelFormValues) => void
  isSubmitting: boolean
}

export default function PushNotificationsChannelForm({
  channel,
  isSubmitting,
  onSubmit,
}: PushNotificationsChannelFormProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedApp, setSelectedApp] = useState<PushNotificationApp>()

  const history = useHistory()

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    control,
  } = useForm<PushNotificationsChannelFormValues>({
    defaultValues: { registrationTokenId: channel.registration_token_attribute_id },
    mode: "onChange",
  })

  const { data: applications, isLoading: isLoadingApplications } = useFetchPushNotificationApps()
  const { data: firebaseApps, isLoading: isLoadingFirebaseApps } =
    useFetchPushNotificationFirebaseApps()

  const { mutate: createApp, isLoading: isCreating } = useCreatePushNotificationApp()
  const { mutate: modifyApp, isLoading: isModifying } = useModifyPushNotificationApp()
  const { mutate: deleteApp, isLoading: isDeleting } = useDeletePushNotificationApp()

  const closeModal = () => {
    setModalOpen(false)
    setSelectedApp(undefined)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    setSelectedApp(undefined)
  }

  const onModalSubmit = (data: PushNotificationAppPayload) => {
    if (selectedApp)
      modifyApp(
        {
          data,
          id: selectedApp.id,
        },
        {
          onSuccess: closeModal,
        },
      )
    else
      createApp(
        { data },
        {
          onSuccess: closeModal,
        },
      )
  }

  const onFirebaseFileDrop = (files: FileList) => {
    if (files && files[0])
      setValue("firebaseFileList", files, { shouldDirty: true, shouldValidate: true })
  }

  const isLoading = isLoadingApplications || isLoadingFirebaseApps

  const { firebase_account_data } = channel

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <Page
          title="Mobile Push channel"
          headerContent={
            <div className={styles.header}>
              <Button
                color="grey"
                variant="outlined"
                onClick={() => {
                  history.push(getRoutePath("administration.channels"))
                }}
              >
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitting} disabled={!isDirty || !isValid}>
                Save
              </Button>
            </div>
          }
        >
          <Paper hasHeader className={styles.config}>
            <div className={styles.configRow}>
              <div className={styles.description}>
                <div>
                  <h3>Firebase configuration file</h3>
                </div>
              </div>
              <div className={styles.configDetails}>
                {firebase_account_data && (
                  <div className={styles.firebaseDetails}>
                    <span className={styles.configuredMarkup}>Configured</span>
                  </div>
                )}
                <FileUploadDnD
                  onFileDrop={onFirebaseFileDrop}
                  label={`${firebase_account_data ? "re" : ""}configure firebase`}
                >
                  <Controller
                    control={control}
                    name="firebaseFileList"
                    rules={{
                      validate: {
                        maxFileSize: maxFileSize(MAX_UPLOAD_BYTES),
                        jsonFile,
                      },
                    }}
                    render={({ field: { value, ref, onBlur, onChange } }) => (
                      <div>
                        <FileField
                          ref={ref}
                          error={errors.firebaseFileList?.message}
                          accept=".json"
                          value={value?.[0]?.name}
                          onBlur={onBlur}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const target = event.target as HTMLInputElement
                            const { files } = target
                            if (files && files[0]) onChange(files)
                          }}
                          className={styles.fileUploadField}
                        />
                        <p className={styles.fileUploadDesc}>
                          Firebase configuration JSON file. Max size: 2MB
                        </p>
                      </div>
                    )}
                  />
                </FileUploadDnD>
              </div>
            </div>
            {featureFlags.MOBILE_PUSH_MULTI_APPS && (
              <div className={styles.configRow}>
                <div className={styles.description}>
                  <div>
                    <h3>Application settings</h3>
                    <p>Application list is based on your Firebase configuration.</p>
                  </div>
                </div>
                <div className={styles.configDetails}>
                  {isLoading && <LoadingIndicator />}
                  {!isLoading && (
                    <div className={styles.applications}>
                      {applications?.map(application => {
                        const hasAnyAndroidApp = firebaseApps
                          ? firebaseApps.android_apps.some(({ app_id }) =>
                              application.app_ids.includes(app_id),
                            )
                          : false
                        const hasAnyIosApp = firebaseApps
                          ? firebaseApps.ios_apps.some(({ app_id }) =>
                              application.app_ids.includes(app_id),
                            )
                          : false

                        return (
                          <div key={application.id} className={styles.application}>
                            <div className={styles.name}>
                              <span className={styles.text}>{application.name}</span>
                              {hasAnyAndroidApp && (
                                <Badge
                                  background="white"
                                  icon={["fab", "android"]}
                                  text="Android"
                                />
                              )}
                              {hasAnyIosApp && (
                                <Badge background="white" icon={["fab", "apple"]} text="iOS" />
                              )}
                            </div>
                            <div className={styles.buttons}>
                              <IconButton
                                color="grey"
                                icon="pencil-alt"
                                variant="transparent"
                                onClick={() => {
                                  setSelectedApp(application)
                                  setModalOpen(true)
                                }}
                              />
                              <IconButton
                                color="red"
                                icon="trash-alt"
                                variant="transparent"
                                onClick={() => {
                                  setSelectedApp(application)
                                  setDeleteModalOpen(true)
                                }}
                              />
                            </div>
                          </div>
                        )
                      })}
                      <Button
                        variant="link"
                        onClick={() => setModalOpen(true)}
                        className={styles.addApplication}
                      >
                        + Add application
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.configRow}>
              <div className={styles.description}>
                <div>
                  <h3>Registration token attribute</h3>
                </div>
              </div>
              <div className={styles.configDetails}>
                <Controller
                  control={control}
                  name="registrationTokenId"
                  render={({ field }) => (
                    <AttributePicker value={field.value} onChange={field.onChange} withDimensions />
                  )}
                />
              </div>
            </div>
          </Paper>
        </Page>
      </form>

      <ApplicationModal
        isLoading={isCreating || isModifying}
        open={modalOpen}
        application={selectedApp}
        handleClose={closeModal}
        onSubmit={onModalSubmit}
      />
      <ConfirmModal
        isLoading={isDeleting}
        open={deleteModalOpen}
        action="delete"
        item={selectedApp?.name}
        title="Are you sure?"
        type="delete"
        what="application"
        handleClose={closeDeleteModal}
        handleConfirm={() =>
          deleteApp({ id: selectedApp!.id }, { onSuccess: () => closeDeleteModal() })
        }
      />
    </>
  )
}
