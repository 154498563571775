import React from "react"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { update, whereEq } from "ramda"

import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import { ReactComponent as OneColIcon } from "images/one-col.svg"
import { ReactComponent as TwoColsIcon } from "images/two-col.svg"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventSourceIds } from "resources/event/eventQueries"
import { ActiveEvents, ActiveSources, Layout, View } from "../types"

import styles from "./SourceFilter.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

type SourceFilterProps = {
  layoutPickerVisible: boolean
  layout: Layout
  activeEvents: ActiveEvents
  activeSources: ActiveSources
  sources: Array<Source>
  view: View
  setActiveEvents: (newValue: ActiveEvents) => void
  setActiveSources: (newValue: ActiveSources) => void
  setLayout: (newLayout: Layout) => void
}

export default function SourceFilter({
  activeEvents,
  activeSources,
  layout,
  setActiveEvents,
  setActiveSources,
  setLayout,
  sources,
  view,
  layoutPickerVisible = true,
}: SourceFilterProps) {
  const { data: timelineAvailableSourceIds = [] } = useFetchEventSourceIds()

  const onSourceToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [, sourceId] = event.currentTarget.name.split(".")

    const sourceIndex = activeSources.findIndex(whereEq({ id: sourceId }))
    const source = activeSources[sourceIndex]
    const newFilter = update(sourceIndex, { ...source, active: !source.active }, activeSources)

    setActiveSources(newFilter)

    const sourceEvents = activeEvents.filter(whereEq({ source_id: sourceId }))
    const sourceEventIds = sourceEvents.map(({ id }) => id)

    if (source.active) {
      const newEventsFilter = activeEvents.map(e => {
        if (sourceEventIds.includes(e.id)) return { ...e, active: false }
        else return e
      })

      setActiveEvents(newEventsFilter)
    } else {
      const newEventsFilter = activeEvents.map(e => {
        if (sourceEventIds.includes(e.id)) return { ...e, active: true }
        else return e
      })

      setActiveEvents(newEventsFilter)
    }
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader={true} className={styles.paper}>
        <h3>Sources</h3>
        <div className={styles.sourcesWrapper}>
          <div className={styles.sources}>
            {activeSources.map(({ id, active }) => {
              if (view === "timeline" && !timelineAvailableSourceIds.includes(id)) return null

              const source = sources.find(whereEq({ id: id }))
              if (!source) return null

              const color = source.frontend_settings?.color

              return (
                <div key={id} className={styles.source}>
                  <Checkbox
                    checked={active}
                    id={`sources.${id}`}
                    label={
                      <>
                        <div
                          className={classNames(styles.sourceIcon, styles[color], {
                            [styles.checked]: active,
                            [styles.disabled]: !active,
                          })}
                        >
                          <SrcDstIcon source={source} white />
                        </div>
                        {source.name}
                      </>
                    }
                    name={`sources.${id}`}
                    variant="secondary"
                    onChange={onSourceToggle}
                    className={styles.checkbox}
                  />
                </div>
              )
            })}
          </div>
          <div
            className={classNames(styles.layoutPicker, { [styles.hidden]: !layoutPickerVisible })}
          >
            <Tippy content="Two columns" placement="bottom">
              <div>
                <IconButton
                  color="grey"
                  customIcon={<TwoColsIcon />}
                  variant="transparent"
                  onClick={() => setLayout("two-cols")}
                  className={classNames(styles.layoutButton, {
                    [styles.active]: layout === "two-cols",
                  })}
                  data-testid="two-cols-button"
                />
              </div>
            </Tippy>
            <Tippy content="Single column" placement="bottom">
              <div>
                <IconButton
                  color="grey"
                  customIcon={<OneColIcon />}
                  variant="transparent"
                  onClick={() => setLayout("one-col")}
                  className={classNames(styles.layoutButton, {
                    [styles.active]: layout === "one-col",
                  })}
                  data-testid="one-col-button"
                />
              </div>
            </Tippy>
          </div>
        </div>
      </Paper>
    </div>
  )
}
