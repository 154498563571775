import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { equals, prop, sort, update } from "ramda"
import api from "resources/endpoints"
import {
  SegmentExportDestination,
  SegmentExportDestinationModifyPayload,
} from "./exportDestinationTypes"
import { ascend } from "utilities/comparators"

export const DESTINATION_ALL_QK: QueryKey = ["exportDestination", "all"]

function useDestinationsQuery<T>(
  config?: UseQueryOptions<SegmentExportDestination[], unknown, T, QueryKey>,
) {
  return useQuery(DESTINATION_ALL_QK, api.exportDestination.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

export function useFetchAllDestinations(
  { sortByName }: { sortByName?: boolean } = {},
  config?: UseQueryOptions<
    SegmentExportDestination[],
    unknown,
    SegmentExportDestination[],
    QueryKey
  >,
) {
  return useDestinationsQuery({
    ...config,
    select: sortByName ? sort(ascend(prop("name"))) : undefined,
  })
}

export function useFetchDestinationsMap() {
  return useDestinationsQuery({
    select: destinations =>
      Object.fromEntries(destinations.map(destination => [destination.id, destination])),
  })
}

export function useFetchDestinationById(id: SegmentExportDestination["id"]) {
  return useDestinationsQuery({
    select: (destinations: SegmentExportDestination[]) =>
      destinations.find(destination => destination.id === id) ?? null,
  })
}

export function useDeleteDestination() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: SegmentExportDestination["id"] }) => api.exportDestination.delete(id),
    {
      onSuccess: () => {
        // Cannot setQueryData because the API response doesn't return the id of the deleted entity
        queryClient.refetchQueries(DESTINATION_ALL_QK)
        showToast("Destination deleted.")
      },
    },
  )
}

export function useModifyDestination() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      data,
    }: {
      id: SegmentExportDestination["id"]
      data: SegmentExportDestinationModifyPayload
    }) => api.exportDestination.modify(id, data),
    {
      onSuccess: ({ segment_export_destination }, { data }) => {
        queryClient.setQueryData<SegmentExportDestination[]>(DESTINATION_ALL_QK, data => {
          if (!data) return

          const index = data.findIndex(({ id }) => segment_export_destination.id === id)

          return index === -1
            ? data.concat(segment_export_destination)
            : update(index, segment_export_destination, data)
        })
        showToast(
          equals(Object.keys(data), ["mi_workspace_id"])
            ? "Workspace assigned."
            : "Destination modified.",
        )
      },
    },
  )
}
