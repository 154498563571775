import appsflyer from "images/logos/appsflyer.png"
import analytics from "images/logos/analytics.png"
import axform from "images/logos/axform.png"
import bank_app from "images/logos/bank_app.png"
import bigquery from "images/logos/bigquery.png"
import bl from "images/logos/bl.png"
import cabaret from "images/logos/cabaret.png"
import criteo from "images/logos/criteo.png"
import csv_file from "images/logos/csv_file.png"
import customer_support from "images/logos/customer_support.png"
import db_table from "images/logos/db_table.png"
import dummy from "images/logos/dummy.png"
import e_wallet from "images/logos/e_wallet.png"
import emails from "images/logos/emails.png"
import envelope from "images/logos/envelope.png"
import exponea from "images/logos/exponea.png"
import facebook from "images/logos/facebook.png"
import firebase from "images/logos/firebase.png"
import fire_engine_red from "images/logos/fire-engine-red.png"
import fuego from "images/logos/fuego.png"
import gads from "images/logos/gads.png"
import gam from "images/logos/gam.png"
import google360 from "images/logos/google-360.png"
import gossamer from "images/logos/gossamer.png"
import gumbo from "images/logos/gumbo.png"
import hubspot from "images/logos/hubspot.png"
import kafka from "images/logos/kafka.png"
import klaviyo from "images/logos/klaviyo.png"
import kly from "images/logos/kly.png"
import ldap from "images/logos/ldap.png"
import locatorlogic from "images/logos/locatorlogic.png"
import macaroni_and_cheese from "images/logos/macaroni-and-cheese.png"
import magento from "images/logos/magento.png"
import magnews from "images/logos/magnews.png"
import mailchimp from "images/logos/mailchimp.png"
import marketo from "images/logos/marketo.png"
import medium_aquamarine from "images/logos/medium-aquamarine.png"
import medium_purple from "images/logos/medium-purple.png"
import meiro_events from "images/logos/meiro.png"
import mobile_app from "images/logos/mobile_app.png"
import ms_dynamic_365 from "images/logos/microsoft_dynamics.png"
import optimizely from "images/logos/optimizely.png"
import persian_blue from "images/logos/persian-blue.png"
import pipedrive from "images/logos/pipedrive.png"
import prestashop from "images/logos/prestashop.png"
import primary from "images/logos/primary.png"
import pure_cloud from "images/logos/pure_cloud.png"
import push_notifications from "images/logos/push_notifications.png"
import salesforce from "images/logos/salesforce.png"
import sitecore from "images/logos/sitecore.png"
import sklik from "images/logos/sklk.png"
import smart_emailing from "images/logos/smart_emailing.png"
import snowplow from "images/logos/snowplow.png"
import sport from "images/logos/sport.png"
import tiktok from "images/logos/tiktok.png"
import trade_desk from "images/logos/trade_desk.png"
import twitter from "images/logos/twitter.png"
import vidio from "images/logos/vidio.png"
import web from "images/logos/web.png"
import woo from "images/logos/woo.png"
import wordpress from "images/logos/wordpress.png"
import yellow_bot from "images/logos/yellow_bot.png"
import yellow_canary from "images/logos/yellow_canary.png"
import white_appsflyer from "images/logos/white_appsflyer.png"
import white_analytics from "images/logos/white_analytics.png"
import white_axform from "images/logos/white_axform.png"
import white_bank_app from "images/logos/white_bank_app.png"
import white_bigquery from "images/logos/white_bigquery.png"
import white_bl from "images/logos/white_bl.png"
import white_cabaret from "images/logos/white_cabaret.png"
import white_criteo from "images/logos/white_criteo.png"
import white_csv_file from "images/logos/white_csv_file.png"
import white_customer_support from "images/logos/white_customer_support.png"
import white_db_table from "images/logos/white_db_table.png"
import white_dummy from "images/logos/white_dummy.png"
import white_e_wallet from "images/logos/white_e_wallet.png"
import white_emails from "images/logos/white_emails.png"
import white_envelope from "images/logos/white_envelope.png"
import white_exponea from "images/logos/white_exponea.png"
import white_facebook from "images/logos/white_facebook.png"
import white_firebase from "images/logos/white_firebase.png"
import white_fire_engine_red from "images/logos/white_fire-engine-red.png"
import white_fuego from "images/logos/white_fuego.png"
import white_gads from "images/logos/white_gads.png"
import white_gam from "images/logos/white_gam.png"
import white_google360 from "images/logos/white_google-360.png"
import white_gossamer from "images/logos/white_gossamer.png"
import white_gumbo from "images/logos/white_gumbo.png"
import white_hubspot from "images/logos/white_hubspot.png"
import white_kafka from "images/logos/white_kafka.png"
import white_klaviyo from "images/logos/white_klaviyo.png"
import white_kly from "images/logos/white_kly.png"
import white_ldap from "images/logos/white_ldap.png"
import white_locatorlogic from "images/logos/white_locatorlogic.png"
import white_macaroni_and_cheese from "images/logos/white_macaroni-and-cheese.png"
import white_magento from "images/logos/white_magento.png"
import white_magnews from "images/logos/white_magnews.png"
import white_mailchimp from "images/logos/white_mailchimp.png"
import white_marketo from "images/logos/white_marketo.png"
import white_medium_aquamarine from "images/logos/white_medium-aquamarine.png"
import white_medium_purple from "images/logos/white_medium-purple.png"
import white_meiro_events from "images/logos/white_meiro.png"
import white_mobile_app from "images/logos/white_mobile_app.png"
import white_ms_dynamic_365 from "images/logos/white_microsoft_dynamics.png"
import white_optimizely from "images/logos/white_optimizely.png"
import white_persian_blue from "images/logos/white_persian-blue.png"
import white_pipedrive from "images/logos/white_pipedrive.png"
import white_prestashop from "images/logos/white_prestashop.png"
import white_primary from "images/logos/white_primary.png"
import white_pure_cloud from "images/logos/white_pure_cloud.png"
import white_push_notifications from "images/logos/white_push_notifications.png"
import white_salesforce from "images/logos/white_salesforce.png"
import white_sitecore from "images/logos/white_sitecore.png"
import white_sklik from "images/logos/white_sklk.png"
import white_snowplow from "images/logos/white_snowplow.png"
import white_smart_emailing from "images/logos/white_smart_emailing.png"
import white_sport from "images/logos/white_sport.png"
import white_tiktok from "images/logos/white_tiktok.png"
import white_trade_desk from "images/logos/white_trade_desk.png"
import white_twitter from "images/logos/white_twitter.png"
import white_vidio from "images/logos/white_vidio.png"
import white_web from "images/logos/white_web.png"
import white_woo from "images/logos/white_woo.png"
import white_wordpress from "images/logos/white_wordpress.png"
import white_yellow_bot from "images/logos/white_yellow_bot.png"
import white_yellow_canary from "images/logos/white_yellow_canary.png"

// dummy ones
import a_1 from "images/logos/a_1.png"
import a_2 from "images/logos/a_2.png"
import a_3 from "images/logos/a_3.png"
import a_4 from "images/logos/a_4.png"
import b_1 from "images/logos/b_1.png"
import b_2 from "images/logos/b_2.png"
import b_3 from "images/logos/b_3.png"
import b_4 from "images/logos/b_4.png"
import c_1 from "images/logos/c_1.png"
import c_2 from "images/logos/c_2.png"
import c_3 from "images/logos/c_3.png"
import c_4 from "images/logos/c_4.png"
import d_1 from "images/logos/d_1.png"
import d_2 from "images/logos/d_2.png"
import d_3 from "images/logos/d_3.png"
import d_4 from "images/logos/d_4.png"
import e_1 from "images/logos/e_1.png"
import e_2 from "images/logos/e_2.png"
import e_3 from "images/logos/e_3.png"
import e_4 from "images/logos/e_4.png"
import f_1 from "images/logos/f_1.png"
import f_2 from "images/logos/f_2.png"
import f_3 from "images/logos/f_3.png"
import f_4 from "images/logos/f_4.png"
import g_1 from "images/logos/g_1.png"
import g_2 from "images/logos/g_2.png"
import g_3 from "images/logos/g_3.png"
import g_4 from "images/logos/g_4.png"
import h_1 from "images/logos/h_1.png"
import h_2 from "images/logos/h_2.png"
import h_3 from "images/logos/h_3.png"
import h_4 from "images/logos/h_4.png"
import white_a_1 from "images/logos/white_a_1.png"
import white_a_2 from "images/logos/white_a_2.png"
import white_a_3 from "images/logos/white_a_3.png"
import white_a_4 from "images/logos/white_a_4.png"
import white_b_1 from "images/logos/white_b_1.png"
import white_b_2 from "images/logos/white_b_2.png"
import white_b_3 from "images/logos/white_b_3.png"
import white_b_4 from "images/logos/white_b_4.png"
import white_c_1 from "images/logos/white_c_1.png"
import white_c_2 from "images/logos/white_c_2.png"
import white_c_3 from "images/logos/white_c_3.png"
import white_c_4 from "images/logos/white_c_4.png"
import white_d_1 from "images/logos/white_d_1.png"
import white_d_2 from "images/logos/white_d_2.png"
import white_d_3 from "images/logos/white_d_3.png"
import white_d_4 from "images/logos/white_d_4.png"
import white_e_1 from "images/logos/white_e_1.png"
import white_e_2 from "images/logos/white_e_2.png"
import white_e_3 from "images/logos/white_e_3.png"
import white_e_4 from "images/logos/white_e_4.png"
import white_f_1 from "images/logos/white_f_1.png"
import white_f_2 from "images/logos/white_f_2.png"
import white_f_3 from "images/logos/white_f_3.png"
import white_f_4 from "images/logos/white_f_4.png"
import white_g_1 from "images/logos/white_g_1.png"
import white_g_2 from "images/logos/white_g_2.png"
import white_g_3 from "images/logos/white_g_3.png"
import white_g_4 from "images/logos/white_g_4.png"
import white_h_1 from "images/logos/white_h_1.png"
import white_h_2 from "images/logos/white_h_2.png"
import white_h_3 from "images/logos/white_h_3.png"
import white_h_4 from "images/logos/white_h_4.png"

export const iconsMapping = {
  appsflyer,
  analytics,
  axform,
  bank_app,
  bigquery,
  bl,
  cabaret,
  criteo,
  csv_file,
  customer_support,
  db_table,
  dummy,
  e_wallet,
  emails,
  envelope,
  exponea,
  facebook,
  firebase,
  "fire-engine-red": fire_engine_red,
  fuego,
  gads,
  gam,
  google360,
  gossamer,
  gumbo,
  hubspot,
  kafka,
  klaviyo,
  kly,
  ldap,
  locatorlogic,
  "macaroni-and-cheese": macaroni_and_cheese,
  magento,
  magnews,
  mailchimp,
  marketo,
  "medium-aquamarine": medium_aquamarine,
  "medium-purple": medium_purple,
  meiro_events,
  mobile_app,
  ms_dynamic_365,
  optimizely,
  "persian-blue": persian_blue,
  pipedrive,
  prestashop,
  primary,
  pure_cloud,
  push_notifications,
  salesforce,
  sitecore,
  sklik,
  snowplow,
  sport,
  smart_emailing,
  tiktok,
  trade_desk,
  twitter,
  vidio,
  web,
  woo,
  wordpress,
  yellow_canary,
  yellow_bot,
  white_appsflyer,
  white_analytics,
  white_axform,
  white_bank_app,
  white_bigquery,
  white_bl,
  white_cabaret,
  white_criteo,
  white_csv_file,
  white_customer_support,
  white_db_table,
  white_dummy,
  white_e_wallet,
  white_emails,
  white_envelope,
  white_exponea,
  white_facebook,
  white_firebase,
  "white_fire-engine-red": white_fire_engine_red,
  white_fuego,
  white_gads,
  white_gam,
  white_google360,
  white_gossamer,
  white_gumbo,
  white_hubspot,
  white_kafka,
  white_klaviyo,
  white_kly,
  white_ldap,
  white_locatorlogic,
  "white_macaroni-and-cheese": white_macaroni_and_cheese,
  white_magento,
  white_magnews,
  white_mailchimp,
  white_marketo,
  "white_medium-aquamarine": white_medium_aquamarine,
  "white_medium-purple": white_medium_purple,
  white_meiro_events,
  white_mobile_app,
  white_ms_dynamic_365,
  white_optimizely,
  "white_persian-blue": white_persian_blue,
  white_pipedrive,
  white_prestashop,
  white_primary,
  white_pure_cloud,
  white_push_notifications,
  white_salesforce,
  white_sitecore,
  white_sklik,
  white_smart_emailing,
  white_snowplow,
  white_sport,
  white_tiktok,
  white_trade_desk,
  white_twitter,
  white_vidio,
  white_web,
  white_woo,
  white_wordpress,
  white_yellow_bot,
  white_yellow_canary,
  a_1,
  a_2,
  a_3,
  a_4,
  b_1,
  b_2,
  b_3,
  b_4,
  c_1,
  c_2,
  c_3,
  c_4,
  d_1,
  d_2,
  d_3,
  d_4,
  e_1,
  e_2,
  e_3,
  e_4,
  f_1,
  f_2,
  f_3,
  f_4,
  g_1,
  g_2,
  g_3,
  g_4,
  h_1,
  h_2,
  h_3,
  h_4,
  white_a_1,
  white_a_2,
  white_a_3,
  white_a_4,
  white_b_1,
  white_b_2,
  white_b_3,
  white_b_4,
  white_c_1,
  white_c_2,
  white_c_3,
  white_c_4,
  white_d_1,
  white_d_2,
  white_d_3,
  white_d_4,
  white_e_1,
  white_e_2,
  white_e_3,
  white_e_4,
  white_f_1,
  white_f_2,
  white_f_3,
  white_f_4,
  white_g_1,
  white_g_2,
  white_g_3,
  white_g_4,
  white_h_1,
  white_h_2,
  white_h_3,
  white_h_4,
}
