import React, { ComponentPropsWithRef, forwardRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import ErrorTippy from "../ErrorTippy/ErrorTippy"
import WhitespaceWarning from "../WhitespaceWarning/WhitespaceWarning"
import { hasTrailingWhitespaces } from "helpers/validators.helper"

import styles from "./TextInput.module.scss"
import { isNil } from "ramda"

export type TextInputProps = {
  error?: string
  label?: string
  labelPosition?: "top" | "left"
  showWhitespaceWarning?: boolean
} & ComponentPropsWithRef<"input">

const TextInput = forwardRef(function TextInput(
  {
    className,
    error,
    id,
    label,
    name,
    type,
    onChange,
    labelPosition = "top",
    showWhitespaceWarning = true,
    ...props
  }: TextInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState(props?.value)

  const inputId = id ?? name

  const onBlur = () => setFocused(false)
  const onFocus = () => setFocused(true)

  const inputProps: ComponentPropsWithRef<"input"> = {
    ...props,
    name,
    ref,
    id: inputId,
    onBlur,
    onFocus,
    onChange: event => {
      setValue(event.target.value)

      if (
        type === "number" &&
        !isNil(props.max) &&
        typeof props.max === "number" &&
        parseFloat(event.target.value) > props.max
      ) {
        return
      }

      onChange?.(event)
    },
  }

  const hasWhitespaceWarning =
    showWhitespaceWarning &&
    !error &&
    value &&
    typeof value === "string" &&
    hasTrailingWhitespaces(value) &&
    focused
  const whitespaceWarning = hasWhitespaceWarning && (
    <WhitespaceWarning className={classNames({ [styles.warning]: labelPosition === "left" })} />
  )

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.hasError]: !!error,
        [styles.hasWarning]: hasWhitespaceWarning,
      })}
      data-testid={`text-field-${name}`}
    >
      <div className={classNames(styles.wrapper, { [styles.row]: labelPosition === "left" })}>
        {label && <label htmlFor={inputId}>{label}</label>}
        {type === "password" ? (
          <ErrorTippy disabled={!error} content={error}>
            <div className={styles.password}>
              <input {...inputProps} type={passwordVisible ? "text" : "password"} />
              <span
                data-testid="eye-button"
                onClick={() => setPasswordVisible(prev => !prev)}
                className={styles.icon}
              >
                <FontAwesomeIcon icon={["far", passwordVisible ? "eye-slash" : "eye"]} />
              </span>
              {whitespaceWarning}
            </div>
          </ErrorTippy>
        ) : (
          <ErrorTippy disabled={!error} content={error}>
            <div className={styles.warningWrapper}>
              <input {...inputProps} type={type ?? "text"} />
              {whitespaceWarning}
            </div>
          </ErrorTippy>
        )}
      </div>
    </div>
  )
})

export default TextInput
