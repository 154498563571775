import { showToast, useToast } from "app/toast"
import { Email } from "resources/email/emailTypes"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { EmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { RouteName, getRoutePath } from "routes"
import { TOAST } from "sharedConstants"

const SOMETHING_WENT_WRONG_MESSAGE =
  "There was an error. Please, let us know about it at help@meiro.io"

type ErrorDataBase = {
  message: string
  link?: { title: string; url: string }
}

type PushNotificationErrorData = {
  error_type: "segment_used_in_push_notification"
  push_notifications: Array<Pick<MobilePushNotification, "id" | "name">>
}

type PopUpWebBannerNotificationErrorData = {
  error_type: "segment_used_in_popup_web_banner"
  popup_web_banners: Array<Pick<PopupWebBanner, "id" | "name">>
}

type EmbeddedWebBannerNotificationErrorData = {
  error_type: "segment_used_in_embedded_web_banner"
  embedded_web_banners: Array<Pick<EmbeddedWebBanner, "id" | "name">>
}

type EmailErrorData = {
  error_type: "segment_used_in_email"
  emails: Array<Pick<Email, "id" | "name">>
}

type PushNotificationAppErrorData = {
  error_type: "app_used_in_push_notification"
  push_notifications: Array<Pick<MobilePushNotification, "id" | "name">>
}

export type ErrorData = ErrorDataBase &
  Partial<
    | PushNotificationErrorData
    | PopUpWebBannerNotificationErrorData
    | EmbeddedWebBannerNotificationErrorData
    | EmailErrorData
    | PushNotificationAppErrorData
  >
type ErrorType =
  | PushNotificationErrorData["error_type"]
  | PopUpWebBannerNotificationErrorData["error_type"]
  | EmbeddedWebBannerNotificationErrorData["error_type"]
  | EmailErrorData["error_type"]
  | PushNotificationAppErrorData["error_type"]

const errorTypePathMapping: Record<ErrorType, RouteName> = {
  segment_used_in_push_notification: "channels.mobile-push.detail",
  segment_used_in_popup_web_banner: "channels.popup-banners.detail",
  segment_used_in_embedded_web_banner: "channels.native-banners.detail",
  segment_used_in_email: "channels.emails.detail",
  app_used_in_push_notification: "channels.mobile-push.detail",
}

const dispatchErrorMessageWithRoutes = (
  message: string,
  errorType: ErrorType,
  entities: Array<{ id: string; name: string }>,
) => {
  const routes = entities.map(({ id, name }) => ({
    title: name,
    path: getRoutePath(errorTypePathMapping[errorType], {
      id,
    }),
  }))

  showToast(message, TOAST.TYPE.ERROR, undefined, false, undefined, routes)
}

export const displayErrorMessage = (data?: ErrorData) => {
  let message = data?.message

  if (message === "unknown user") message = undefined
  if (message === "Internal Server Error") message = SOMETHING_WENT_WRONG_MESSAGE

  if (!message) return

  const previousMessage = useToast.getState().toast?.message
  if (previousMessage === SOMETHING_WENT_WRONG_MESSAGE) {
    const previousTimestamp = useToast.getState().toast?.timestamp
    if (!previousTimestamp || Date.now() - previousTimestamp > 6000) {
      showToast(message, TOAST.TYPE.ERROR, undefined, false, data?.link)
    }
  } else {
    const errorType = data?.error_type
    if (errorType === "segment_used_in_push_notification") {
      dispatchErrorMessageWithRoutes(message, errorType, data!.push_notifications!)
      return
    }
    if (errorType === "segment_used_in_popup_web_banner") {
      dispatchErrorMessageWithRoutes(message, errorType, data!.popup_web_banners!)
      return
    }
    if (errorType === "segment_used_in_embedded_web_banner") {
      dispatchErrorMessageWithRoutes(message, errorType, data!.embedded_web_banners!)
      return
    }
    if (errorType === "segment_used_in_email") {
      dispatchErrorMessageWithRoutes(message, errorType, data!.emails!)
      return
    }
    if (errorType === "app_used_in_push_notification") {
      dispatchErrorMessageWithRoutes(message, errorType, data!.push_notifications!)
      return
    }

    showToast(message, TOAST.TYPE.ERROR, undefined, false, data?.link)
  }
}
