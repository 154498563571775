import { useEffect } from "react"
import { assocPath, isNil, update, whereEq } from "ramda"
import create from "zustand"

import { Segment, SegmentCountsMessage, SegmentCountsStore } from "./segmentTypes"
import { socket } from "context/socket"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"

export const useSegmentCountsStore = create<SegmentCountsStore>(set => ({
  conditionsNumbers: [],
  conditionsNumbersFlat: [],
  exportDestinationResultsCount: [],
  clear: () =>
    set(() => ({
      conditionsNumbers: [],
      conditionsNumbersFlat: [],
      conditionsResultsCount: undefined,
      customersTotalCount: undefined,
      dataLoadEndTime: undefined,
      dataLoadStartTime: undefined,
      error: undefined,
      exportDestinationResultsCount: [],
      baseSegmentSize: undefined,
    })),
  setConditionsNumbers: (conditionPosition, count) =>
    set(state => {
      const path = conditionPosition.length === 0 ? [0] : conditionPosition
      const conditionsNumbers = assocPath(path, count, state.conditionsNumbers)
      return {
        conditionsNumbers,
        conditionsNumbersFlat: conditionsNumbers.flat(Infinity),
      }
    }),
  setConditionsResultsCount: conditionsResultsCount =>
    set(() => ({
      conditionsResultsCount: conditionsResultsCount,
    })),
  setCustomersTotalCount: customersTotalCount =>
    set(() => ({
      customersTotalCount,
    })),
  setDataLoadEndTime: dataLoadEndTime =>
    set(() => ({
      dataLoadEndTime,
    })),
  setDataLoadStartTime: dataLoadStartTime =>
    set(() => ({
      dataLoadStartTime,
    })),
  setError: error =>
    set(() => ({
      error,
    })),
  setExportDestinationResultsCount: args =>
    set(state => {
      const index = state.exportDestinationResultsCount.findIndex(
        whereEq({ exportDestinationId: args.exportDestinationId }),
      )

      return {
        exportDestinationResultsCount:
          index !== -1
            ? update(index, args, state.exportDestinationResultsCount)
            : [...state.exportDestinationResultsCount, args],
      }
    }),
  setBaseSegmentSize: baseSegmentSize => set({ baseSegmentSize }),
}))

export const emitSegmentCounts = ({
  segmentId,
  refreshCache,
}: {
  segmentId: Segment["id"] | null
  refreshCache?: boolean
}) => {
  if (segmentId) {
    if (refreshCache !== undefined)
      socket.emit("segment_counts", { segment_id: segmentId, refresh_cache: refreshCache })
    else socket.emit("segment_counts", { segment_id: segmentId })
  }
}

export const useListenSegmentCounts = (segmentId: Segment["id"] | null) => {
  const {
    setConditionsNumbers,
    setConditionsResultsCount,
    setCustomersTotalCount,
    setDataLoadEndTime,
    setDataLoadStartTime,
    setExportDestinationResultsCount,
    setBaseSegmentSize,
    setError,
  } = useSegmentCountsStore()

  useEffect(() => {
    if (segmentId)
      socket.on("segment_counts_response", (msg: SegmentCountsMessage) => {
        if (msg.segment_id === segmentId) {
          if (msg.error && msg.count_type !== "export_destination_results_count") {
            setError(msg.error)
            showToast(msg.error, TOAST.TYPE.ERROR)
          } else {
            if (!msg.count_type) {
              setDataLoadEndTime(msg.data_load_end_time)
              setDataLoadStartTime(msg.data_load_start_time)
            } else {
              switch (msg.count_type) {
                case "condition_results_count": {
                  if (Array.isArray(msg.condition_position) && !isNil(msg.count)) {
                    setConditionsNumbers(msg.condition_position, msg.count)
                  }
                  break
                }
                case "customers_total_count": {
                  setCustomersTotalCount(msg.count)
                  break
                }
                case "export_destination_results_count": {
                  if (!isNil(msg.count) && !isNil(msg.destination_id) && !isNil(msg.total_count))
                    setExportDestinationResultsCount({
                      count: msg.count,
                      exportDestinationId: msg.destination_id,
                      totalCount: msg.total_count,
                    })

                  break
                }
                case "segment_results_count": {
                  setConditionsResultsCount(msg.count)
                  break
                }
                case "lookalike_base_segment_results_count": {
                  setBaseSegmentSize(msg.count)
                  break
                }
              }
            }
          }
        }
      })

    return () => {
      if (segmentId) socket.off("segment_counts_response")
    }
  }, [
    segmentId,
    setConditionsNumbers,
    setConditionsResultsCount,
    setCustomersTotalCount,
    setDataLoadEndTime,
    setDataLoadStartTime,
    setError,
    setExportDestinationResultsCount,
    setBaseSegmentSize,
  ])
}
