import Button from "components/UI/elements/Button/Button"
import React, { useState } from "react"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import styles from "./PopupWebBannersList.module.scss"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import Image from "./popup-web-banners.svg"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import { PopupWebBanner, PopupWebBannerSort } from "resources/webBanner/popupWebBanner/popupWBTypes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import PopupWebBannerSettingsForm from "../components/PopupWebBannerSettingsForm/PopupWebBannerSettingsForm"
import {
  useDeletePopupWB,
  useFetchAllPopupWB,
  useModifyPopupWB,
} from "resources/webBanner/popupWebBanner/popupWBQueries"
import SearchField from "components/UI/elements/SearchField"
import create from "zustand"
import { OrderDir } from "types/util"
import { assoc } from "ramda"
import { Link } from "react-router-dom"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import Table, { Column, Modified, Name } from "components/Table/Table"

const pageTitle = "Pop-up banner campaigns"

type PopupWBSelectionState = {
  orderBy: PopupWebBannerSort
  orderDir: OrderDir
  searchTerm: string
  setSort: (orderBy: PopupWebBannerSort) => void
  setSearchTerm: (searchTerm: string) => void
}

const useStore = create<PopupWBSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function PopupWebBannersList() {
  const history = useHistory()
  const hasAccess = useHasAccess()

  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()

  const { data: systemInfo, isLoading: isLoadingSystemInfo } = useFetchSystemInfo()
  const {
    data: webBanners,
    isLoading: isLoadingPopupWBs,
    isFetching: isFetchingPopupWB,
  } = useFetchAllPopupWB(
    { searchTerm: searchTerm.trim(), orderBy, orderDir },
    { enabled: hasAccess.webBanners.view },
  )
  const deleteMutation = useDeletePopupWB()
  const modifyMutation = useModifyPopupWB()

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    banner: null as PopupWebBanner | null,
  })

  const toggleBannerDisabled = (webBanner: PopupWebBanner) =>
    modifyMutation.mutateAsync({ id: webBanner.id, data: { disabled: !webBanner.disabled } })

  const closeDeleteBannerModal = () => setDeleteModal(assoc("open", false))

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id: deleteModal.banner!.id },
      {
        onSuccess: () => {
          closeDeleteBannerModal()
        },
      },
    )

  // `isLoading` is true if the query is disabled and stays true. We have to check `isFetching` to
  //  exclude that scenario from this if statement.
  if (isLoadingSystemInfo || (isLoadingPopupWBs && isFetchingPopupWB))
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )

  if (!systemInfo?.me_connection_url)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Pop-up web banners", className: styles.image, src: Image }}>
          <h1>Pop-up web banners</h1>
          <strong>
            To set this up, please contact{" "}
            <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
              support@meiro.io
            </a>{" "}
            or get in touch with your Meiro account manager!
          </strong>
          <p>
            Powerful set of personalization features will unlock opportunity to target your visitors
            with highly tailored content and special promos served at the right time. Several
            formats are available.
          </p>
          <p>
            Check out examples of{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-image"
              target="_blank"
              rel="noreferrer"
            >
              image
            </a>{" "}
            or{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html"
              target="_blank"
              rel="noreferrer"
            >
              HTML
            </a>{" "}
            web banners.
          </p>
        </MarketingContent>
      </Page>
    )

  if (!hasAccess.webBanners.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Pop-up web banners", className: styles.image, src: Image }}>
          <h1>Pop-up web banners</h1>
          <strong>
            It seems like you don't have access to the Pop-up web banners tab. If you want to know
            more about your access settings, please contact your administrator.
          </strong>
          <p>
            The Personalization tab offers pop-up web banners, embedded web banners and promo codes
            for your orchestrated omnichannel personalization strategy.
          </p>
          <p>
            In the Pop-up web banners tab, you can easily create pop-up web banners displayed on
            your website with settings of your choice.
          </p>
          <p>
            Check out examples of{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-image"
              target="_blank"
              rel="noreferrer"
            >
              image
            </a>
            {" & "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html"
              target="_blank"
              rel="noreferrer"
            >
              HTML
            </a>{" "}
            pop-up web banners and{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/pop-up-web-banners-quick-tutorial-step-by-step"
              target="_blank"
              rel="noreferrer"
            >
              web banners tutorial
            </a>
            .
          </p>
        </MarketingContent>
      </Page>
    )

  const isEmpty = webBanners?.length === 0

  const columns: Column<PopupWebBanner>[] = [
    {
      id: "name",
      label: "Name",
      onSort() {
        setSort("name")
      },
      gridTemplate: "1fr",
      renderCell: webBanner => (
        <div data-testid="td-pwb-name">
          <Name name={webBanner.name} />
        </div>
      ),
    },

    {
      id: "priority",
      label: "Priority",
      onSort() {
        setSort("priority")
      },
      gridTemplate: "max-content",
      renderCell: webBanner => webBanner.settings.priority,
    },
    {
      id: "disabled",
      label: "Active",
      onSort() {
        setSort("disabled")
      },
      gridTemplate: "max-content",
      renderCell: webBanner => (
        <ToggleButton
          value={!webBanner.disabled}
          handleToggle={() => toggleBannerDisabled(webBanner)}
          disabled={!hasAccess.webBanners.edit}
        />
      ),
    },
    {
      id: "created",
      label: "Modified at",
      onSort() {
        setSort("created")
      },
      gridTemplate: "max-content",
      renderCell: webBanner => (
        <Modified modifiedAt={webBanner.created} modifiedBy={webBanner.user_id} />
      ),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: webBanner => (
        <div className={styles.actions}>
          <Link
            to={{
              pathname: getRoutePath("channels.popup-banners.detail", {
                id: webBanner.id,
              }),
              state: { goBack: true },
            }}
            className={styles.editLink}
          >
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="pencil-alt"
              tooltip="Edit"
            />
          </Link>
          <IconButton
            color="red"
            size="xs"
            onClick={() => {
              setDeleteModal({
                open: true,
                banner: webBanner,
              })
            }}
            variant="outlined"
            icon="trash-alt"
            tooltip="Delete"
            disabled={!hasAccess.webBanners.edit}
            data-testid="cta-delete-pwb"
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{
              value: searchTerm,
              onChange: setSearchTerm,
            }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            data-testid="pwb-search-field"
            wrapperClassName={styles.searchField}
          />
          <Button
            data-testid="create-banner"
            onClick={() => {
              history.push({
                pathname: getRoutePath("channels.popup-banners.create"),
                state: { goBack: true },
              })
            }}
            disabled={!hasAccess.webBanners.edit}
          >
            + Create Pop-up Web Banner
          </Button>
        </>
      }
    >
      <PopupWebBannerSettingsForm />
      <Paper className={styles.bannersBox}>
        <div className={styles.trashButtonWrapper}>
          <Link to={getRoutePath("channels.popup-banners.trash")}>
            <Button type="button" color="grey" variant="outlined" icon="trash-alt" iconStyle="far">
              Trash
            </Button>
          </Link>
        </div>
        {webBanners && (
          <Table
            data={webBanners}
            emptyMessage="No banners found."
            columns={columns}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        )}
      </Paper>
      {isEmpty && (
        <MarketingContent img={{ alt: "Pop-up web banners", className: styles.image, src: Image }}>
          <h1>Pop-up web banners</h1>
          <p>
            Powerful set of personalization features will unlock opportunity to target your visitors
            with highly tailored content and special promos served at the right time. Several
            formats are available.
          </p>
          <p>
            Check out examples of{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-image"
              target="_blank"
              rel="noreferrer"
            >
              image
            </a>{" "}
            or{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html"
              target="_blank"
              rel="noreferrer"
            >
              HTML
            </a>{" "}
            web banners.
          </p>
        </MarketingContent>
      )}
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        action="delete"
        what="pop-up web banner"
        item={deleteModal.banner?.name}
        handleClose={closeDeleteBannerModal}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}
