import Button from "components/UI/elements/Button/Button"
import { propEq } from "ramda"
import React, { PureComponent } from "react"
import {
  useFetchCurrentUser,
  useHasAccess,
  useToggleFavoriteCustomer,
} from "resources/user/currentUserQueries"
import PropTypes from "prop-types"
import styles from "./HeaderActions.module.scss"
import { SocketContext } from "context/socket"
import { withRouter } from "react-router-dom"
import { getRoutePath } from "routes"
import { useFetchSegmentCustomers } from "resources/segment/segment/segmentQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import {
  useCustomerSearch,
  useCustomerSearchStore,
} from "resources/customer/search/customerSearchQueries"

class HeaderActions extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      usedCustomersNavigation: false,
      navigatingForward: false,
      navigatingBackward: false,
      forwardButtonDisabled: false,
      isTogglingFavorite: false,
    }
  }

  componentDidMount() {
    const {
      customerIds,
      loadMoreSegmentCustomers,
      hasMoreSegmentCustomers,
      profileIteratorIndex,
      segmentId,
    } = this.props
    if (profileIteratorIndex !== null) {
      // chech if visited customer is not last in mem, if yes, fetch more when possible
      if (!segmentId) {
        // customer search pagination
        if ([customerIds.length - 1, customerIds.length - 2].includes(profileIteratorIndex)) {
          if (profileIteratorIndex === customerIds.length - 1)
            this.setState({ forwardButtonDisabled: true })
        }
      } else {
        // segment customers preview
        if ([customerIds.length - 1, customerIds.length - 2].includes(profileIteratorIndex)) {
          if (hasMoreSegmentCustomers) loadMoreSegmentCustomers()
          else if (profileIteratorIndex === customerIds.length - 1)
            this.setState({ forwardButtonDisabled: true })
        }
      }
    }
  }

  componentWillUnmount() {
    this.context.off("segment_customer_entities_response")
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerDataReady &&
      (this.state.navigatingForward || this.state.navigatingBackward)
    ) {
      this.setState({
        navigatingForward: false,
        navigatingBackward: false,
      })
    }

    const {
      customerIds,
      profileIteratorIndex,
      segmentId,
      loadMoreSegmentCustomers,
      hasMoreSegmentCustomers,
    } = this.props
    if (profileIteratorIndex !== null && profileIteratorIndex !== prevProps.profileIteratorIndex) {
      if (segmentId) {
        if (profileIteratorIndex === customerIds.length - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        } else if (profileIteratorIndex === customerIds.length - 2 && hasMoreSegmentCustomers) {
          loadMoreSegmentCustomers()
        }
      } else {
        if (profileIteratorIndex === customerIds.length - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        }
      }
    }
  }

  toggleFavoriteCustomer = async () => {
    this.setState({ isTogglingFavorite: true })
    try {
      const { toggleFavoriteCustomer, customerId } = this.props
      await toggleFavoriteCustomer(customerId)
    } catch {
      // noop
    }
    this.setState({ isTogglingFavorite: false })
  }

  previousCustomer = () => {
    const { customerIds, profileIteratorIndex, history, setIndex } = this.props
    if (!this.state.navigatingBackward) {
      this.setState({
        navigatingBackward: true,
        usedCustomersNavigation: true,
        forwardButtonDisabled: false,
      })
      if (customerIds.length > 0) {
        const newIndex = profileIteratorIndex - 1
        if (newIndex >= 0) {
          const customerId = customerIds[newIndex]
          if (customerId) {
            setIndex(newIndex)
            history.replace({
              pathname: getRoutePath("profiles.detail", { id: customerId }),
              state: history.location.state,
            })
          }
        }
      }
    }
  }

  nextCustomer = () => {
    const { customerIds, profileIteratorIndex, history, setIndex } = this.props
    if (!this.state.navigatingForward) {
      this.setState({
        navigatingForward: true,
        usedCustomersNavigation: true,
      })
      if (customerIds.length > 0) {
        const newIndex = profileIteratorIndex + 1
        const customerId = customerIds[newIndex]
        if (customerId) {
          setIndex(newIndex)
          history.replace({
            pathname: getRoutePath("profiles.detail", { id: customerId }),
            state: history.location.state,
          })
        }
      }
    }
  }

  render() {
    const { isCustomerFavorite, profileIteratorIndex } = this.props
    const { navigatingForward, navigatingBackward, forwardButtonDisabled, isTogglingFavorite } =
      this.state

    const showAsFavorite =
      (isCustomerFavorite && !isTogglingFavorite) || (!isCustomerFavorite && isTogglingFavorite)
    return (
      <div className={styles.headerActions}>
        {profileIteratorIndex !== null && (
          <Button
            color="grey"
            icon="chevron-left"
            iconStyle="far"
            loading={navigatingBackward}
            variant="outlined"
            onClick={this.previousCustomer}
            disabled={profileIteratorIndex === 0}
          >
            Previous profile
          </Button>
        )}
        <Button
          color={showAsFavorite ? "primary" : "grey"}
          icon="star"
          iconStyle={showAsFavorite ? "fas" : "far"}
          variant={showAsFavorite ? "solid" : "outlined"}
          onClick={this.toggleFavoriteCustomer}
        >
          Favorite
        </Button>
        {profileIteratorIndex !== null && (
          <Button
            color="grey"
            icon="chevron-right"
            iconPosition="end"
            iconStyle="far"
            loading={navigatingForward}
            variant="outlined"
            onClick={this.nextCustomer}
            disabled={forwardButtonDisabled}
          >
            Next profile
          </Button>
        )}
      </div>
    )
  }
}

HeaderActions = withRouter(HeaderActions)

HeaderActions.propTypes = {
  customerId: PropTypes.string.isRequired,
}

export default props => {
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()
  const toggleFavoriteCustomer = useToggleFavoriteCustomer()

  const { index, segmentId, setIndex } = useProfileIteratorStore()
  const { attributeId, searchTerm, isFavoritesList } = useCustomerSearchStore()
  const { data: queryResult } = useCustomerSearch({
    attributeId,
    searchTerm,
    isFavoritesList,
  })

  const {
    data: segmentCustomers,
    hasNextPage: hasMoreSegmentCustomers,
    fetchNextPage: loadMoreSegmentCustomers,
  } = useFetchSegmentCustomers(segmentId, { enabled: !!segmentId })

  let customerIds = []

  if (segmentId) {
    customerIds = segmentCustomers.map(customer => customer.id)
  } else if (Array.isArray(queryResult?.customers)) {
    customerIds = queryResult.customers.map(customer => customer.customer_entity_id)
  }

  const isCustomerFavorite = currentUser.cdp_settings?.favourite_customers?.some(
    propEq(props.customerId, "customer_entity_id"),
  )

  return (
    <HeaderActions
      {...props}
      hasAccess={hasAccess}
      toggleFavoriteCustomer={toggleFavoriteCustomer}
      isCustomerFavorite={isCustomerFavorite}
      hasMoreSegmentCustomers={hasMoreSegmentCustomers}
      loadMoreSegmentCustomers={loadMoreSegmentCustomers}
      customerIds={customerIds}
      profileIteratorIndex={index}
      segmentId={segmentId}
      setIndex={setIndex}
    />
  )
}
