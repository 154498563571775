import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { append, descend, prop, remove, sort } from "ramda"
import { useParams } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import EventGroupsCountsChart from "../EventGroupsCountsChart/EventGroupsCountsChart"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import { timeResolutionRangeText } from "helpers/date.helper"
import {
  useFetchCustomerEventGroups,
  useFetchCustomerEvents,
} from "resources/customer/event/customerEventQueries"
import { CustomerEventGroup } from "resources/customer/event/customerEventTypes"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventsMap } from "resources/event/eventQueries"
import { DATE_FMT, ITEMS_PER_PAGE } from "sharedConstants"
import CustomerEvent from "../CustomerEvent/CustomerEvent"

import styles from "./TimelineEventGroups.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { DBtimestampToDate } from "utilities/date"
import { format, subMonths } from "date-fns"

type TimelineEventGroupsProps = {
  sources: Array<Source>
}

const TIME_RESOLUTION = "day"

export default function TimelineEventGroups({ sources }: TimelineEventGroupsProps) {
  const { id } = useParams<{ id: string }>()

  const [openedGroups, setOpenedGroups] = useState<Array<string>>([])
  const [lastOpen, setLastOpen] = useState<string | undefined>()

  const scrollToRef = useRef<HTMLDivElement>(null)

  const { data, isLoading, isSuccess } = useFetchCustomerEventGroups({ customer_entity_id: id })

  const customerEventChartData: Array<{ events_count: number; start_date: string; date: string }> =
    []

  const scrollTo = useCallback(() => {
    if (scrollToRef && scrollToRef.current)
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
  }, [scrollToRef])

  useEffect(() => {
    if (lastOpen !== undefined) scrollTo()
  }, [lastOpen, scrollTo])

  const toggle = (key: string) => {
    const isOpen = openedGroups.find(v => v === key)
    if (!isOpen) setLastOpen(key)

    setOpenedGroups(prev => {
      const index = prev.findIndex(v => v === key)
      return index === -1 ? append(key, prev) : remove(index, 1, prev)
    })
  }

  if (data && data.customer_event_groups.length > 0) {
    data.customer_event_groups.forEach(group => {
      if (customerEventChartData.length === 0) {
        customerEventChartData.push({
          events_count: group.events_count,
          start_date: group.start_date,
          date: timeResolutionRangeText(group.start_date, group.end_date, "month"),
        })
      } else {
        let lastEntry = customerEventChartData[customerEventChartData.length - 1]
        const date = timeResolutionRangeText(group.start_date, group.end_date, "month")
        if (lastEntry.date === date) {
          // sum
          customerEventChartData[customerEventChartData.length - 1].events_count +=
            group.events_count
        } else {
          // fill gaps ... append
          while (date !== lastEntry.date) {
            customerEventChartData.push({
              events_count: 0,
              start_date: format(
                subMonths(DBtimestampToDate(lastEntry.start_date), 1),
                DATE_FMT.DB_DATETIME,
              ),
              date: format(subMonths(DBtimestampToDate(lastEntry.start_date), 1), DATE_FMT.MONTH),
            })
            lastEntry = customerEventChartData[customerEventChartData.length - 1]
          }
          customerEventChartData[customerEventChartData.length - 1].events_count +=
            group.events_count
        }
      }
    })
  }

  if (isLoading) return <LoadingIndicator />

  if (isSuccess && data?.customer_event_groups.length === 0)
    return (
      <Paper className={styles.noResultsFound}>
        <p className={styles.timelineMessage}>No events found for the customer.</p>
      </Paper>
    )

  return (
    <>
      {customerEventChartData.length > 1 && (
        <EventGroupsCountsChart data={customerEventChartData} />
      )}
      <div className={styles.customerTimeline}>
        {data?.customer_event_groups.map(group => {
          const key = `${group.start_date}/${group.end_date}`
          const isOpen = !!openedGroups.find(v => v === key)

          return (
            <EventGroup
              key={key}
              ref={scrollToRef}
              isLastOpened={lastOpen === key}
              isOpen={isOpen}
              group={group}
              sources={sources}
              toggle={toggle}
            />
          )
        })}
      </div>
    </>
  )
}

type EventGroupProps = {
  group: CustomerEventGroup<0>
  sources: Array<Source>
  toggle: (groupKey: string) => void
  isLastOpened?: boolean
  isOpen?: boolean
}

const EventGroup = forwardRef<HTMLDivElement, EventGroupProps>(
  ({ group, sources, toggle, isLastOpened = false, isOpen = false }, ref) => {
    const { id } = useParams<{ id: string }>()

    const { data: eventsMap = {} } = useFetchEventsMap({
      includeHidden: true,
    })

    const {
      data: customerEvents,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      isSuccess,
    } = useFetchCustomerEvents<0>(
      {
        customer_entity_id: id,
        start_date: group.start_date,
        end_date: group.end_date,
        limit: ITEMS_PER_PAGE,
        load_full_structure: 0,
        order_by: "event_time",
        order_dir: "DESC",
      },
      { enabled: isOpen },
    )

    const orderedActiveSources = sort(descend(prop("count")), group.sources_events_counts)

    return (
      <div
        className={classNames(styles.groupRow, { [styles.opened]: isOpen })}
        ref={isLastOpened ? ref : null}
      >
        <div className={styles.openedSticky}>
          <span className={styles.timelineText}>
            {timeResolutionRangeText(group.start_date, group.end_date, TIME_RESOLUTION)}
          </span>
          <Paper className={styles.groupBox}>
            <div className={styles.countsWrapper}>
              {orderedActiveSources.map(sourceCount => {
                const source = sources.find(({ id }) => id === sourceCount.source_id)
                if (!source) return null

                return (
                  <EventGroupSource key={source.id} count={sourceCount.count} source={source} />
                )
              })}
            </div>
            <div data-testid="expand-button" className={styles.moreWrapper}>
              <IconButton
                color="grey"
                onClick={_ => toggle(`${group.start_date}/${group.end_date}`)}
                className={classNames(styles.groupActionButton, {
                  [styles.loading]: isOpen && (isLoading || isFetchingNextPage),
                })}
                iconStyle="far"
                icon={isOpen ? "angle-double-up" : "angle-double-down"}
                variant="outlined"
              />
            </div>
          </Paper>
        </div>
        {isOpen && (
          <>
            {isSuccess && customerEvents.length === 0 && (
              <Paper className={classNames(styles.noResultsFound, styles.eventsNotFound)}>
                <p>No results found, consider to modify filter settings.</p>
              </Paper>
            )}
            {isSuccess &&
              customerEvents.length > 0 &&
              customerEvents.map(customerEvent => (
                <div key={customerEvent.id}>
                  <CustomerEvent
                    dataSource={sources.find(({ id }) => id === customerEvent.source_id)}
                    customerEvent={customerEvent}
                    event={eventsMap[customerEvent.event_id]}
                  />
                </div>
              ))}
            {isSuccess && hasNextPage && (
              <div className={styles.loadMoreWrapper}>
                <Button
                  color="grey"
                  loading={isFetchingNextPage}
                  variant="outlined"
                  onClick={() => fetchNextPage()}
                >
                  Show more events
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    )
  },
)

type EventGroupSourceProps = {
  source: Source
  count: number
}

const EventGroupSource = ({ source, count }: EventGroupSourceProps) => {
  const color = source.frontend_settings?.color

  return (
    <div className={styles.countEntry}>
      <div className={classNames(styles.iconContainer, styles[color])}>
        <SrcDstIcon source={source} className={styles.sourceIcon} white />
      </div>
      <div className={styles.countInfo}>
        <span className={styles.count}>{count}x</span>
        <span className={styles.sourceName}>{source.name}</span>
      </div>
    </div>
  )
}
