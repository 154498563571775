import {
  TrashItemType,
  FetchTrashItemsParams,
  FetchTrashItemsResponse,
  TrashItem,
} from "./trashTypes"
import { api } from "api"
import {
  emailToTrashItemTransform,
  embeddedWebBannerToTrashItemTransform,
  mobilePushNotificationToTrashItemTransform,
  popupWebBannerToTrashItemTransform,
  segmentExportDestinationToTrashItemTransform,
  segmentToTrashItemTransform,
  userRoleToTrashItemTransform,
  userToTrashItemTransform,
} from "./utilities/entityToTrashItemTransform"
// import store from "store"
// import { restoreExportDestination } from "resources/exportDestination/exportDestinationActions"
import { queryClient } from "app/queryClient"
import { UserFull } from "resources/user/userTypes"
import { update } from "ramda"
import { USER_ALL_QK } from "resources/user/userQueries"
import { DESTINATION_ALL_QK } from "resources/exportDestination/exportDestinationQueries"

export const fetchTrashItems = async ({
  offset,
  searched_text,
  item_type,
}: FetchTrashItemsParams): Promise<FetchTrashItemsResponse> => {
  switch (item_type) {
    case "custom_segments": {
      const res = await api.segment.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_segments.map(segmentToTrashItemTransform),
      }
    }
    case "featured_segments": {
      const res = await api.featuredSegment.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_featured_segments.map(segmentToTrashItemTransform),
      }
    }
    case "smart_segments": {
      const res = await api.smartSegment.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_smart_segments.map(segmentToTrashItemTransform),
      }
    }
    case "lookalike_segments": {
      const res = await api.lookalikeSegment.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_lookalike_segments.map(segmentToTrashItemTransform),
      }
    }
    case "embedded_web_banners": {
      const res = await api.embeddedWebBanner.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_embedded_web_banners.map(embeddedWebBannerToTrashItemTransform),
      }
    }
    case "popup_web_banners": {
      const res = await api.popupWebBanner.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_popup_web_banners.map(popupWebBannerToTrashItemTransform),
      }
    }
    case "segment_export_destinations": {
      const res = await api.exportDestination.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_segment_export_destinations.map(
          segmentExportDestinationToTrashItemTransform,
        ),
      }
    }
    case "user_roles": {
      const res = await api.userRole.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_user_roles.map(userRoleToTrashItemTransform),
      }
    }
    case "users": {
      const res = await api.user.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_users.map(userToTrashItemTransform),
      }
    }
    case "push_notifications": {
      const res = await api.mobilePushNotification.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_push_notifications.map(mobilePushNotificationToTrashItemTransform),
      }
    }
    case "emails": {
      const res = await api.email.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_emails.map(emailToTrashItemTransform),
      }
    }
    default:
      throw new Error("Unknown trash item type")
  }
}

export const restoreTrashItem = async (
  id: string | number,
  itemType: TrashItemType,
): Promise<{ restoredTrashItem: TrashItem }> => {
  switch (itemType) {
    case "custom_segments": {
      return {
        restoredTrashItem: segmentToTrashItemTransform(
          (await api.segment.restoreDeleted(id as number)).segment,
        ),
      }
    }
    case "featured_segments": {
      return {
        restoredTrashItem: segmentToTrashItemTransform(
          (await api.featuredSegment.restoreDeleted(id as number)).segment,
        ),
      }
    }
    case "smart_segments": {
      return {
        restoredTrashItem: segmentToTrashItemTransform(
          (await api.smartSegment.restoreDeleted(id as number)).segment,
        ),
      }
    }
    case "lookalike_segments": {
      return {
        restoredTrashItem: segmentToTrashItemTransform(
          (await api.lookalikeSegment.restoreDeleted(id as number)).segment,
        ),
      }
    }
    case "embedded_web_banners": {
      return {
        restoredTrashItem: embeddedWebBannerToTrashItemTransform(
          (await api.embeddedWebBanner.restoreDeleted(id as string)).embedded_web_banner,
        ),
      }
    }
    case "popup_web_banners": {
      return {
        restoredTrashItem: popupWebBannerToTrashItemTransform(
          (await api.popupWebBanner.restoreDeleted(id as string)).popup_web_banner,
        ),
      }
    }
    case "user_roles": {
      return {
        restoredTrashItem: userRoleToTrashItemTransform(
          await api.userRole.restoreDeleted(id as number),
        ),
      }
    }
    case "users": {
      const restoredUser = await api.user.restoreDeleted(id as number)

      queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => {
        if (!data) {
          return
        }

        const index = data.findIndex(({ id }) => id === restoredUser.id)

        return index === -1 ? data.concat(restoredUser) : update(index, restoredUser, data)
      })

      return {
        restoredTrashItem: userToTrashItemTransform(restoredUser),
      }
    }
    case "segment_export_destinations": {
      const restoredDestination = (await api.exportDestination.restoreDeleted(id as number))
        .segment_export_destination

      queryClient.invalidateQueries(
        { queryKey: DESTINATION_ALL_QK, refetchType: "all" },
        { throwOnError: false },
      )

      return {
        restoredTrashItem: segmentExportDestinationToTrashItemTransform(restoredDestination),
      }
    }
    case "push_notifications": {
      return {
        restoredTrashItem: mobilePushNotificationToTrashItemTransform(
          (await api.mobilePushNotification.restoreDeleted(id as string)).push_notification,
        ),
      }
    }
    case "emails": {
      return {
        restoredTrashItem: emailToTrashItemTransform(
          (await api.email.restoreDeleted(id as string)).email,
        ),
      }
    }
    default:
      throw new Error("Unknown trash item type")
  }
}
