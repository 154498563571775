import React, { lazy, Suspense, useState } from "react"
import { useHistory } from "react-router-dom"
import create from "zustand"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import Paper from "components/UI/elements/Paper"
import CreateStitchingCategoryModal from "components/CreateStitchingCategoryModal/CreateStitchingCategoryModal"
import {
  useFetchAllStitchingCategories,
  useDeleteStitchingCategory,
} from "resources/stitchingCategory/stitchingCategoryQueries"
import {
  StitchingCategory,
  StitchingCategorySelectionState,
} from "resources/stitchingCategory/stitchingCategoryTypes"
import { getRoutePath } from "routes"
import { MODAL } from "sharedConstants"

import "./IdentityStitchingList.scss"
import Table, { Column, Name } from "components/Table/Table"
const IdentityStitchingGraph = lazy(
  () => import("../components/IdentityStitchingGraph/IdentityStitchingGraph"),
)

const useStore = create<StitchingCategorySelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default function IdentityStitchingList() {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedStitchingCategory, setSelectedStitchingCategory] = useState<
    StitchingCategory | undefined
  >()

  const history = useHistory()

  const { orderBy, orderDir, setSort } = useStore()
  const { data: stitchingCategories = [], isSuccess: areStitchingCategoriesFulfilled } =
    useFetchAllStitchingCategories({ orderBy, orderDir })

  const deleteMutation = useDeleteStitchingCategory()
  const deleteStitchingCategory = () => {
    if (!selectedStitchingCategory) return

    deleteMutation.mutateAsync(
      { id: selectedStitchingCategory.id },
      {
        onSuccess: () => setSelectedStitchingCategory(undefined),
      },
    )
  }

  const columns: Column<StitchingCategory>[] = [
    {
      id: "name",
      label: "Stitching category",
      onSort() {
        setSort("name")
      },
      gridTemplate: "1fr",
      renderCell: ({ name }) => <Name name={name} />,
    },
    {
      id: "count",
      label: "Rules",
      onSort() {
        setSort("count")
      },
      gridTemplate: "1fr",
      renderCell: ({ stitching_rules }) => stitching_rules.length,
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: stitchingCategory => {
        const isSystem = stitchingCategory.stitching_rules.some(({ is_system }) => is_system)

        return (
          <div className="buttons">
            <IconButton
              data-testid={`edit-${stitchingCategory.id}`}
              color="black"
              size="xs"
              onClick={() => {
                history.push(
                  getRoutePath("administration.identity-stitching.detail", {
                    id: stitchingCategory.id,
                  }),
                )
              }}
              icon="pencil-alt"
              tooltip="Edit"
              variant="outlined"
            />
            <IconButton
              data-testid={`delete-${stitchingCategory.id}`}
              disabled={isSystem}
              color="red"
              size="xs"
              onClick={() => setSelectedStitchingCategory(stitchingCategory)}
              className="left-margin"
              icon="trash-alt"
              tooltip={
                isSystem
                  ? "Cannot delete stitching category containing system stitching rules"
                  : "Delete"
              }
              variant="outlined"
            />
          </div>
        )
      },
    },
  ]

  return (
    <Page
      className="setup-identity-stitching"
      title="Identity stitching"
      headerContent={
        <Button onClick={() => setCreateModalOpen(true)}>+ Create stitching category</Button>
      }
    >
      <div data-testid="identity-stitching-list" className="setup-identity-stitching-content">
        {!areStitchingCategoriesFulfilled && <LoadingIndicator />}

        {areStitchingCategoriesFulfilled && (
          <Paper noPaddingTop>
            <Table
              data={stitchingCategories}
              columns={columns}
              emptyMessage={'Click on "Create Stitching Rule" to get started.'}
              sortBy={orderBy}
              sortDir={orderDir}
            />
          </Paper>
        )}

        {areStitchingCategoriesFulfilled && stitchingCategories.length > 0 && (
          <Suspense fallback={<LoadingIndicator />}>
            <IdentityStitchingGraph />
          </Suspense>
        )}
      </div>

      <ConfirmModal
        open={!!selectedStitchingCategory}
        type={MODAL.TYPE.DELETE}
        handleClose={() => setSelectedStitchingCategory(undefined)}
        handleConfirm={deleteStitchingCategory}
        title="Are you sure?"
        action="delete"
        what="identinty stitching rule for attribute"
        item={selectedStitchingCategory?.name}
        isLoading={deleteMutation.isLoading}
      />
      <CreateStitchingCategoryModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onConfirm={({ id }) => {
          history.push(getRoutePath("administration.identity-stitching.detail", { id }))
        }}
      />
    </Page>
  )
}
