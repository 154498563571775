import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { api } from "api"
import { showToast } from "app/toast"
import { find, prop, reject, sort, update, whereEq } from "ramda"
import { USER_ALL_QK } from "resources/user/userQueries"
import { SYSTEM_ROLE_ID } from "sharedConstants"
import { ascend } from "utilities/comparators"
import { UserRole, UserRoleCreatePayload, UserRoleModifyPayload } from "./userRoleTypes"

export const USER_ROLE_ALL_QK: QueryKey = ["userRole", "all"]

function useUserRolesQuery<T>(config?: UseQueryOptions<UserRole[], unknown, T, QueryKey>) {
  return useQuery(USER_ROLE_ALL_QK, api.userRole.listAll, config)
}

export function useFetchAllUserRoles() {
  return useUserRolesQuery({ select: reject(whereEq({ id: SYSTEM_ROLE_ID })) })
}

export function useFetchUserRoleById(id: UserRole["id"]) {
  return useUserRolesQuery({ select: find(whereEq({ id })) })
}

export function useFetchUserRoleOptions() {
  return useUserRolesQuery({
    select: data =>
      data
        .filter(({ id }) => id !== SYSTEM_ROLE_ID)
        .map(({ id, name }) => ({ label: name, value: id })),
  })
}

export function useFetchUserRolesMap() {
  return useUserRolesQuery<Record<UserRole["id"], UserRole>>({
    select: roles => Object.fromEntries(roles.map(role => [role.id, role])),
  })
}

export function useCreateUserRole() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: UserRoleCreatePayload }) => api.userRole.create(data), {
    onSuccess: role => {
      queryClient.setQueryData<UserRole[]>(USER_ROLE_ALL_QK, data => {
        if (!data) {
          return
        }

        return sort(ascend(prop("name")), data.concat(role))
      })
      queryClient.invalidateQueries([USER_ALL_QK])
      showToast("User role created.")
    },
  })
}

export function useModifyUserRole() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: UserRole["id"]; data: UserRoleModifyPayload }) =>
      api.userRole.modify(id, data),
    {
      onSuccess: role => {
        queryClient.setQueryData<UserRole[]>(USER_ROLE_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(whereEq({ id: role.id }))

          const newData =
            index === -1 ? sort(ascend(prop("name")), data.concat(role)) : update(index, role, data)

          return newData
        })
        queryClient.invalidateQueries([USER_ALL_QK])
        showToast("User role modified.")
      },
    },
  )
}

export function useDeleteUserRole() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: UserRole["id"] }) => api.userRole.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<UserRole[]>(USER_ROLE_ALL_QK, data => {
        if (!data) {
          return
        }
        return reject(whereEq({ id }), data)
      })
      queryClient.invalidateQueries([USER_ALL_QK])
      showToast("User role deleted.")
    },
  })
}
