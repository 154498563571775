import React, { lazy, PureComponent, Suspense, useEffect } from "react"
import { ReactSortable } from "react-sortablejs"

// ui components
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import InsightForm from "./components/InsightForm"
import ConfirmModal from "components/UI/components/ConfirmModal"

// helpers
import { SEGMENT_ANALYTICS_FUNCTIONS, MODAL } from "sharedConstants"

import "./Insights.scss"
import {
  getCompoundAttributeSubAttribute,
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import classNames from "classnames"
import create from "zustand"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import { isEmpty } from "ramda"
import { differenceInDays } from "date-fns"
import { DBtimestampToDate } from "utilities/date"
import {
  useCreateAttributeAggregation,
  useDeleteAttributeAggregation,
  useFetchAllAttributeAggregations,
  useModifyAttributeAggregation,
  useReorderAttributeAggregations,
} from "resources/attributeAggregation/attributeAggregationQueries"
const DummyInsight = lazy(() => import("./components/DummyInsight"))

const CONDITION_HAS_VALUE = [
  SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
]

const CONDITION_HAS_COUNT = [
  SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
]

const CONDITION_HAS_TILE_DISPLAY = [
  SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
]

const CONDITION_HAS_TWO_VALUES = [SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value]

const DragHandle = ({ moving, disabled }) => (
  <IconButton
    className={classNames("drag-button", { moving, disabled })}
    color="grey"
    icon="grip-vertical"
    variant="transparent"
    disabled={disabled}
    tooltip={disabled ? "Dragging is disabled when items are filtered." : undefined}
  />
)

const SortableItem = ({
  value,
  compareValue,
  attribute,
  subAttribute,
  toggleModeFunction,
  toggleConfirmModal,
  onMouseOver,
  onMouseOut,
  showTooltip,
  moving,
  id,
  draggingDisabled,
}) => {
  return (
    <div className={`sortable-tile-wrapper`} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}>
      <div className="tile-with-buttons">
        <div onClick={toggleModeFunction} className="tile-clickable">
          <Suspense fallback={<LoadingIndicator />}>
            <DummyInsight
              id={id}
              name={value.name}
              compareValue={compareValue}
              funcType={value.function}
              attribute={attribute}
              attributeDeleted={!attribute}
              subAttribute={subAttribute}
              color={value.frontend_settings?.color}
              displayType={value.frontend_settings?.tile_type ?? "chart"}
              count={value.settings?.count}
              showNewBadge={differenceInDays(new Date(), DBtimestampToDate(value.created)) < 8}
            />
          </Suspense>
        </div>
        <div className={`action-buttons ${showTooltip === true ? "shown" : "hidden"}`}>
          <IconButton
            color="grey"
            className="edit-button"
            onClick={toggleModeFunction}
            iconStyle="far"
            icon="pencil"
            variant="transparent"
          />
          <DragHandle moving={moving} disabled={draggingDisabled} />
          <IconButton
            color="grey"
            onClick={toggleConfirmModal(value.attribute_id, value.id, value.name)}
            className="trash-button"
            icon="trash-alt"
            variant="transparent"
          />
        </div>
      </div>
    </div>
  )
}

class Insights extends PureComponent {
  afterSaveAction = "list"

  constructor(props) {
    super(props)
    this.state = {
      mode: "table",
      editingAggregation: {},
      deleteModal: {
        open: false,
        itemName: "",
        aggregationId: null,
        attributeId: null,
        loading: false,
      },
      tooltip: null,
      moving: false,
      dragging: false,
      unsavedThingsModal: {
        show: false,
        step: null,
      },
    }
  }

  toggleMode =
    (aggregation = {}) =>
    () => {
      this.setState(prevState => ({
        mode: prevState.mode === "table" ? "form" : "table",
        editingAggregation: aggregation,
        tooltip: null,
      }))
    }

  saveAnalyticsForm = async data => {
    if (!this.props.isSaving) {
      const { editingAggregation } = this.state
      const color = data.color ?? null
      const tileType = data.tile_type
      const frontendSettings = {}
      if (!isEmpty(editingAggregation)) {
        // update existing
        let settingsValue = null
        if (CONDITION_HAS_VALUE.includes(data.function)) {
          settingsValue = {
            value: data.value,
          }
        } else if (CONDITION_HAS_COUNT.includes(data.function)) {
          settingsValue = {
            count: Math.round(+data.count),
          }
        } else if (CONDITION_HAS_TWO_VALUES.includes(data.function)) {
          settingsValue = {
            value_from: data.value_from,
            value_to: data.value_to,
          }
        }
        if (CONDITION_HAS_TILE_DISPLAY.includes(data.function)) {
          frontendSettings.tile_type = tileType
        }
        const reqObjectData = {
          attribute_id: data.attribute_id,
          sub_attribute_id: data.subattribute ? data.subattribute.value : null,
          name: data.name,
          function: data.function,
          description: data.description ? data.description : null,
          settings: settingsValue,
          frontend_settings: {
            ...editingAggregation.frontend_settings,
            ...frontendSettings,
            color,
          },
        }
        try {
          await this.props.modifyAttributeAggregation({
            id: editingAggregation.id,
            data: reqObjectData,
          })
          this.setState(() => ({
            mode: "table",
          }))
        } catch (err) {
          if (err.response?.status === 404) {
            this.props.refetchAttributeAggregations()
            this.setState({
              mode: "table",
            })
          }
        }
      } else {
        // create new
        try {
          if (CONDITION_HAS_TILE_DISPLAY.includes(data.function)) {
            frontendSettings.tile_type = tileType
          }
          await this.createNewAttributeAggregation({
            ...data,
            frontend_settings: { ...frontendSettings, color },
          })
          this.setState(
            () => ({
              mode: "table",
            }),
            () => {
              // After removing redux-form, we can't reset the form from the outside, so instead we
              // go back to the table and then back to the form to force a remount of InsightForm
              if (this.afterSaveAction === "form") {
                this.toggleMode()()
              }
            },
          )
        } catch (err) {}
      }
    }
  }

  createNewAttributeAggregation = async formData => {
    const reqObjectData = {
      name: formData.name,
      function: formData.function,
      frontend_settings: formData.frontend_settings,
      attribute_id: formData.attribute_id,
      sub_attribute_id: formData.subattribute ? formData.subattribute.value : null,
    }
    if (formData.description) {
      reqObjectData["description"] = formData.description
    }
    if (formData.value && CONDITION_HAS_VALUE.includes(formData.function)) {
      reqObjectData["settings"] = { value: formData.value }
    } else if (formData.count && CONDITION_HAS_COUNT.includes(formData.function)) {
      reqObjectData["settings"] = { count: Math.round(+formData.count) }
    } else if (
      formData.value_from &&
      formData.value_to &&
      CONDITION_HAS_TWO_VALUES.includes(formData.function)
    ) {
      const { value_from, value_to } = formData
      reqObjectData["settings"] = { value_from, value_to }
    }
    return await this.props.createAttributeAggregation({ data: reqObjectData })
  }

  onSaveClick = type => () => {
    this.afterSaveAction = type
  }

  toggleConfirmModal = (attributeId, aggregationId, itemName) => evt => {
    if (evt) {
      evt.stopPropagation()
    }

    if (attributeId && aggregationId) {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: true,
          attributeId,
          aggregationId,
          itemName,
        },
      }))
    } else {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: false,
        },
      }))
    }
  }

  deleteAttributeAggregation = async () => {
    const { deleteModal } = this.state
    try {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          loading: true,
        },
      }))
      await this.props.deleteAttributeAggregation({ id: deleteModal.aggregationId })
      this.setState(prevState => ({
        mode: "table",
        deleteModal: {
          ...prevState.deleteModal,
          open: false,
          loading: false,
        },
      }))
    } catch (err) {
      if (err.response?.status === 404) {
        // attribute aggregation was already deleted
        this.props.refetchAttributeAggregations()
        this.setState(prevState => ({
          mode: "table",
          deleteModal: {
            ...prevState.deleteModal,
            open: false,
            loading: false,
          },
        }))
      } else {
        this.setState(prevState => ({
          deleteModal: {
            ...prevState.deleteModal,
            loading: false,
          },
        }))
      }
    }
  }

  onSortEnd = async evt => {
    const { oldIndex, newIndex } = evt
    if (oldIndex !== newIndex) {
      const { attributeAggregations } = this.props
      const destinationAggregation = attributeAggregations[newIndex]
      const sourceAggregation = attributeAggregations[oldIndex]
      try {
        this.setState({
          moving: true,
        })
        await this.props.moveAttributeAggregation({
          id: sourceAggregation.id,
          fromIndex: sourceAggregation.order_index,
          toIndex: destinationAggregation.order_index,
        })
        this.setState({ moving: false })
      } catch (err) {
        this.setState({ moving: false })
      }
    }
  }

  onMouseOver = aggregationId => () => {
    const { dragging, tooltip } = this.state
    if (tooltip !== aggregationId && !dragging) {
      this.setState({
        tooltip: aggregationId,
      })
    }
  }

  onMouseOut = aggregationId => () => {
    const { dragging, tooltip } = this.state
    if (tooltip === aggregationId && !dragging) {
      this.setState({
        tooltip: null,
      })
    }
  }

  closeUnsavedInsightModal = () => {
    this.setState({
      unsavedThingsModal: {
        show: false,
        step: null,
      },
    })
  }

  render() {
    const { mode, editingAggregation, deleteModal, tooltip, moving } = this.state
    const {
      areAttributesFulfilled,
      attributesMapById,
      filters,
      isSaving,
      attributeAggregations: insights,
    } = this.props
    const { searchTerm, setSearchTerm, sourceId, setSourceId } = filters

    if (!areAttributesFulfilled || !insights) {
      return (
        <Page title="Insights">
          <LoadingIndicator />
        </Page>
      )
    }

    let filteredInsights = insights
    if (searchTerm) {
      filteredInsights = filteredInsights.filter(item =>
        item.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
      )
    }
    if (sourceId) {
      filteredInsights = filteredInsights.filter(
        item => attributesMapById[item.attribute_id]?.source.id === sourceId,
      )
    }
    const isFiltered = filteredInsights !== insights

    let headerTitle = "Insights"
    if (mode === "form" && isEmpty(editingAggregation)) {
      headerTitle = "Create insight"
    } else if (mode === "form" && !isEmpty(editingAggregation)) {
      headerTitle = "Edit insight"
    }

    const initialValues = {
      tile_type: "chart",
      count: 1,
    }

    if (!isEmpty(editingAggregation)) {
      initialValues["name"] = editingAggregation.name
      initialValues["attribute_id"] = editingAggregation.attribute_id
      initialValues["description"] = editingAggregation.description
      initialValues["color"] = editingAggregation.frontend_settings?.color
      initialValues["tile_type"] = editingAggregation.frontend_settings?.tile_type ?? "chart"
      const attribute = attributesMapById[editingAggregation.attribute_id]
      if (attribute) {
        if (isAttributeCompound(attribute.data_type)) {
          const subAttribute = getCompoundAttributeSubAttribute(
            editingAggregation.sub_attribute_id,
            attribute.data_type,
          )
          if (subAttribute) {
            initialValues["subattribute"] = {
              ...subAttribute,
              label: subAttribute.name,
              value: subAttribute.id,
            }
          }
        }
        initialValues["function"] = editingAggregation.function
      }
      if (CONDITION_HAS_VALUE.includes(editingAggregation.function)) {
        initialValues["value"] = editingAggregation.settings?.value
      } else if (CONDITION_HAS_COUNT.includes(editingAggregation.function)) {
        initialValues["count"] = editingAggregation.settings?.count
      } else if (CONDITION_HAS_TWO_VALUES.includes(editingAggregation.function)) {
        initialValues["value_from"] = editingAggregation.settings?.value_from
        initialValues["value_to"] = editingAggregation.settings?.value_to
      }
    }

    const insightsList = filteredInsights.map((row, index) => {
      const attribute = attributesMapById[row.attribute_id]
      let subAttribute
      if (isAttributeCompound(attribute?.data_type) && row.sub_attribute_id) {
        const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
        subAttribute = subAttributes.find(subAttr => subAttr.id === row.sub_attribute_id)
      }
      let dataTooltipPlacement = index % 4 === 3 ? "left" : "right"
      const compareValue =
        row.settings?.value_from && row.settings?.value_to
          ? [row.settings.value_from, row.settings.value_to]
          : row.settings?.value
      return (
        <SortableItem
          id={row.id}
          key={`item-${row.id}`}
          index={index}
          value={row}
          compareValue={compareValue}
          attribute={attribute}
          subAttribute={subAttribute}
          toggleModeFunction={this.toggleMode(row)}
          toggleConfirmModal={this.toggleConfirmModal}
          onMouseOver={this.onMouseOver(row.id)}
          onMouseOut={this.onMouseOut(row.id)}
          showTooltip={tooltip === row.id}
          moving={moving}
          disabled={moving === true}
          dataTooltipPlacement={dataTooltipPlacement}
          draggingDisabled={isFiltered}
        />
      )
    })

    return (
      <Page
        className="admin-segment-analytics"
        title={headerTitle}
        headerContent={
          <>
            {mode === "form" && (
              <div className="form-action-buttons">
                <Button
                  className="cancel"
                  color="grey"
                  variant="outlined"
                  onClick={this.toggleMode()}
                >
                  Cancel
                </Button>
                {!isEmpty(initialValues) && (
                  <Button
                    className="delete-button"
                    color="red"
                    icon="trash-alt"
                    variant="outlined"
                    onClick={this.toggleConfirmModal(
                      editingAggregation.attribute_id,
                      editingAggregation.id,
                      editingAggregation.name,
                    )}
                  >
                    Delete
                  </Button>
                )}
                {isEmpty(editingAggregation) && (
                  <Button
                    color="grey"
                    type="submit"
                    form="insightForm"
                    variant="outlined"
                    onClick={this.onSaveClick("form")}
                    className="save-create"
                    loading={isSaving && this.afterSaveAction === "form"}
                    disabled={isSaving && this.afterSaveAction !== "form"}
                  >
                    Save and create new one
                  </Button>
                )}
                <Button
                  data-testid="save-insight"
                  type="submit"
                  form="insightForm"
                  onClick={this.onSaveClick("list")}
                  loading={isSaving && this.afterSaveAction === "list"}
                  disabled={isSaving && this.afterSaveAction !== "list"}
                >
                  Save
                </Button>
              </div>
            )}
            {mode === "table" && (
              <>
                <SearchWithSourceSelect
                  searchValue={searchTerm}
                  setSearchValue={setSearchTerm}
                  selectValue={sourceId}
                  setSelectValue={setSourceId}
                  placeholder="Search for insights"
                />
                <div className="actions-wrapper">
                  <Button onClick={this.toggleMode()}>+ Create insight</Button>
                </div>
              </>
            )}
          </>
        }
      >
        {mode === "form" && (
          <InsightForm onSubmit={this.saveAnalyticsForm} initialValues={initialValues} />
        )}
        {mode === "table" &&
          (filteredInsights.length === 0 ? (
            <Paper className="admin-segment-analytics-content">
              <p className="info-message">
                {insights.length === 0
                  ? 'Click on "Create Insight" to get started.'
                  : "No insights found."}
              </p>
            </Paper>
          ) : (
            <div className="admin-segment-analytics-content-wrapper">
              <p className="info-message">
                Values are <strong>randomized</strong> and for <strong>preview only</strong>.
              </p>

              {isFiltered ? (
                <div className="admin-segment-analytics-content tiles-section">{insightsList}</div>
              ) : (
                <ReactSortable
                  list={filteredInsights}
                  setList={() => {}}
                  onStart={() => this.setState({ dragging: true })}
                  onEnd={() => this.setState({ dragging: false })}
                  onUpdate={this.onSortEnd}
                  animation={200}
                  handle=".drag-button"
                  className={`admin-segment-analytics-content tiles-section`}
                >
                  {insightsList}
                </ReactSortable>
              )}
            </div>
          ))}
        <ConfirmModal
          open={deleteModal.open}
          handleClose={this.toggleConfirmModal()}
          handleConfirm={this.deleteAttributeAggregation}
          title="Are you sure?"
          action="delete"
          what="insight"
          item={deleteModal.itemName}
          isLoading={deleteModal.loading}
          type={MODAL.TYPE.DELETE}
        />
      </Page>
    )
  }
}

const filtersInitialState = {
  searchTerm: "",
  sourceId: null,
}

const useFiltersStore = create(set => ({
  ...filtersInitialState,

  reset: () => set(filtersInitialState),

  setSearchTerm: searchTerm => set({ searchTerm }),
  setSourceId: sourceId => set({ sourceId }),
}))

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const filters = useFiltersStore()
  useEffect(() => filters.reset, [filters.reset])

  const {
    data: attributeAggregations,
    isSuccess: areAttributeAggregationsFulfilled,
    refetch: refetchAttributeAggregations,
  } = useFetchAllAttributeAggregations(false)

  const { isLoading: isCreating, mutateAsync: createAttributeAggregation } =
    useCreateAttributeAggregation()
  const { isLoading: isModifying, mutateAsync: modifyAttributeAggregation } =
    useModifyAttributeAggregation()
  const { mutateAsync: deleteAttributeAggregation } = useDeleteAttributeAggregation()
  const { mutateAsync: moveAttributeAggregation } = useReorderAttributeAggregations()

  const isSaving = isCreating || isModifying

  return (
    <Insights
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      filters={filters}
      attributeAggregations={attributeAggregations}
      areAttributeAggregationsFulfilled={areAttributeAggregationsFulfilled}
      refetchAttributeAggregations={refetchAttributeAggregations}
      createAttributeAggregation={createAttributeAggregation}
      modifyAttributeAggregation={modifyAttributeAggregation}
      deleteAttributeAggregation={deleteAttributeAggregation}
      moveAttributeAggregation={moveAttributeAggregation}
      isSaving={isSaving}
    />
  )
}
