import React, { useState } from "react"
import parser from "cron-parser"
import { format, isPast } from "date-fns"
import { format as formatTz, utcToZonedTime } from "date-fns-tz"

import RepetitiveScheduleForm from "./RepetitiveScheduleForm/RepetitiveScheduleForm"
import ScheduleBar from "./ScheduleBar/ScheduleBar"
import ScheduleButton from "./ScheduleButton/ScheduleButton"
import StaticScheduleForm, {
  StaticScheduleFormFields,
} from "./StaticScheduleForm/StaticScheduleForm"
import { showToast } from "app/toast"
import useClickOutHandler from "hooks/useClickOutHandler"
import useKeyListener from "hooks/useKeyListener"
import { SegmentScheduleCron } from "resources/segment/segment/segmentTypes"
import {
  SCHEDULE_TYPE,
  schedulesApiToForm,
  schedulesFormToApi,
} from "resources/segment/segment/utilities/segmentSchedulesUtils"
import { DATE_FMT } from "sharedConstants"

import styles from "./Scheduler.module.scss"

const formatInTimeZone = (date: Date, pattern: string, tz: string) =>
  formatTz(utcToZonedTime(date, tz), pattern, { timeZone: tz })

type SchedulerProps = {
  permission: "disabled" | "view" | "edit"
  repetitiveSchedules: Array<SegmentScheduleCron>
  onSaveRepetitiveSchedules: (schedules: Array<SegmentScheduleCron>) => Promise<any> | undefined
  displayToast?: boolean
  enableStaticSchedule?: boolean
  id?: number
  staticSchedule?: string
  tooltip?: string
  tooltipDisabled?: boolean
  onSaveStaticSchedule?: (staticSchedule?: string) => Promise<any> | undefined
}

export default function Scheduler({
  id,
  permission,
  repetitiveSchedules,
  staticSchedule,
  tooltip,
  tooltipDisabled,
  onSaveRepetitiveSchedules,
  onSaveStaticSchedule,
  displayToast = false,
  enableStaticSchedule = false,
}: SchedulerProps) {
  const [repetitiveModalOpen, setRepetitiveModalOpen] = useState(false)
  const [staticModalOpen, setStaticModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const {
    buttonRef,
    isOpen: isBarOpen,
    ref: barRef,
    close: closeBar,
    toggle: toggleBar,
  } = useClickOutHandler()

  useKeyListener("Escape", closeBar)

  const saveSchedules = (values: { schedules: Array<any> }) => {
    if (!onSaveRepetitiveSchedules) return

    const newSchedules: Array<SegmentScheduleCron> = schedulesFormToApi(values)

    if (newSchedules.length > 0) setIsSaving(true)
    if (newSchedules.length === 0) setIsDeleting(true)

    const saveRequest = onSaveRepetitiveSchedules(newSchedules)

    if (saveRequest && typeof saveRequest.then === "function") {
      saveRequest
        .then(() => {
          if (displayToast) {
            if (values.schedules.length === 0) {
              showToast("Schedule deleted.")
            } else {
              showToast("Schedule set.")
            }
          }

          setRepetitiveModalOpen(false)
        })
        .catch(() => {})
        .finally(() => {
          setIsDeleting(false)
          setIsSaving(false)
        })
    } else {
      setIsDeleting(false)
      setIsSaving(false)
      setRepetitiveModalOpen(false)
    }
  }

  const saveScheduleTime = (values?: StaticScheduleFormFields) => {
    if (!onSaveStaticSchedule) return

    if (values) setIsSaving(true)
    else setIsDeleting(true)

    let saveRequest: Promise<any> | undefined = undefined
    if (values) {
      const dateTime = new Date(values.date.valueOf())
      const utcDateTime = new Date(
        Date.UTC(
          dateTime.getFullYear(),
          dateTime.getMonth(),
          dateTime.getDate(),
          parseInt(values.hours),
          parseInt(values.minutes),
        ),
      )

      saveRequest = onSaveStaticSchedule(
        formatInTimeZone(utcDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxx", "UTC"),
      )
    } else saveRequest = onSaveStaticSchedule()
    if (saveRequest && typeof saveRequest.then === "function") {
      saveRequest
        .then(() => {
          setStaticModalOpen(false)
        })
        .catch(() => {})
        .finally(() => {
          setIsDeleting(false)
          setIsSaving(false)
        })
    } else {
      setIsDeleting(false)
      setIsSaving(false)
      setStaticModalOpen(false)
    }
  }

  let nextRepetitiveSchedule: string | undefined = undefined
  if (repetitiveSchedules.length > 0) {
    const schedulesCronStrings = repetitiveSchedules.map(
      schedule =>
        `${schedule.minute} ${schedule.hour} ${schedule.day} ${schedule.month} ${schedule.day_of_week}`,
    )

    let nextSchedulesDates: Array<Array<Date>> = []
    try {
      const intervals = schedulesCronStrings.map(cronString =>
        parser.parseExpression(cronString, { utc: true }),
      )

      nextSchedulesDates = intervals.map(interval => {
        let dates: Array<Date> = []
        for (let i = 0; i < 3; i++) {
          dates[i] = new Date(interval.next().toISOString())
        }
        return dates
      })
    } catch {}

    nextRepetitiveSchedule =
      format(
        nextSchedulesDates
          .flat()
          .sort((a, b) => a.getTime() - b.getTime())
          .slice(0, 1)[0],
        "EEE H:mm",
      ) ?? undefined
  }

  const nextStaticSchedule = staticSchedule
    ? format(new Date(staticSchedule), DATE_FMT.DATETIME)
    : undefined

  return (
    <div className={styles.wrapper}>
      <ScheduleButton
        ref={buttonRef}
        disabled={
          !id ||
          permission === "disabled" ||
          (permission !== "edit" && (repetitiveSchedules.length === 0 || !staticSchedule))
        }
        past={staticSchedule ? isPast(new Date(staticSchedule)) : false}
        toggled={isBarOpen}
        withToggle={enableStaticSchedule}
        daysCount={nextRepetitiveSchedule ? repetitiveSchedules.length : undefined}
        schedule={nextRepetitiveSchedule || nextStaticSchedule}
        tooltip={tooltip}
        tooltipDisabled={tooltipDisabled}
        onClick={() => {
          if (enableStaticSchedule && repetitiveSchedules.length === 0 && !staticSchedule)
            toggleBar()
          else if (staticSchedule) setStaticModalOpen(true)
          else setRepetitiveModalOpen(true)
        }}
        onToggle={toggleBar}
      />
      {isBarOpen && (
        <ScheduleBar
          ref={barRef}
          onSelect={type => {
            if (type === "repetitive") setRepetitiveModalOpen(true)
            else setStaticModalOpen(true)
            closeBar()
          }}
        />
      )}
      {repetitiveModalOpen && (
        <RepetitiveScheduleForm
          isDeleting={isDeleting}
          isEditable={permission === "edit"}
          isSaving={isSaving}
          open={repetitiveModalOpen}
          schedules={
            Array.isArray(repetitiveSchedules) && repetitiveSchedules.length > 0
              ? schedulesApiToForm(repetitiveSchedules)
              : [
                  {
                    days: [1, 2, 3, 4, 5, 6, 7],
                    type: SCHEDULE_TYPE.ONCE,
                    hour: "",
                    minute: "",
                  },
                ]
          }
          onClose={() => setRepetitiveModalOpen(false)}
          onSubmit={saveSchedules}
        />
      )}
      {staticModalOpen && (
        <StaticScheduleForm
          isDeleting={isDeleting}
          isEditable={permission === "edit"}
          isSaving={isSaving}
          open={staticModalOpen}
          schedule={staticSchedule}
          onClose={() => setStaticModalOpen(false)}
          onDelete={saveScheduleTime}
          onSubmit={saveScheduleTime}
        />
      )}
    </div>
  )
}
