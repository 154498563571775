import React from "react"
import Paper from "components/UI/elements/Paper"
import "./Sources.scss"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table, { Column, Name } from "components/Table/Table"
import { Source } from "resources/dataSource/dataSourceTypes"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import Datetime from "components/UI/elements/Datetime/Datetime"

export default function Sources() {
  const { data: sources = [], isSuccess: areSourcesFulfilled } = useFetchAllDataSources()

  const columns: Column<Source>[] = [
    {
      id: "icon",
      gridTemplate: "max-content",
      renderCell: source => (
        <div className="icon">
          <SrcDstIcon source={source} />
        </div>
      ),
    },
    {
      id: "name",
      label: "Source",
      gridTemplate: "1fr",
      renderCell: source => (
        <div>
          <Name name={source.name} /> <NewBadge created={source.created} />
        </div>
      ),
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "3fr",
      renderCell: ({ description }) => description ?? "—",
    },
    {
      id: "created",
      label: "Date added",
      gridTemplate: "max-content",
      renderCell: ({ created }) => <Datetime datetime={created} />,
    },
  ]

  return (
    <Page className="sources-destinations" title="Sources">
      {!areSourcesFulfilled && <LoadingIndicator />}

      {areSourcesFulfilled && (
        <Paper noPaddingTop>
          <Table emptyMessage="No connected source found." columns={columns} data={sources} />
        </Paper>
      )}
    </Page>
  )
}
