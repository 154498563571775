import React from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { getRoutePath } from "routes"
import "./EventsList.scss"
import { useFetchAllEvents, useModifyEvent } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import create from "zustand"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import Table, { Name } from "components/Table/Table"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import Datetime from "components/UI/elements/Datetime/Datetime"
import classNames from "classnames"
import { always } from "ramda"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import { Link } from "react-router-dom"
import { Source } from "resources/dataSource/dataSourceTypes"
import { EventFull } from "resources/event/eventTypes"

type FilterState = {
  searchTerm: string
  sourceId: Source["id"] | null
  reset: () => void
  setSearchTerm: (searchTerm: string) => void
  setSourceId: (sourceId: Source["id"] | null) => void
}

const filtersInitialState = {
  searchTerm: "",
  sourceId: null,
}

export const useFiltersStore = create<FilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSearchTerm: searchTerm => set({ searchTerm }),
  setSourceId: sourceId => set({ sourceId }),
}))

export default function EventsList() {
  const filters = useFiltersStore()
  const { searchTerm, setSearchTerm, sourceId, setSourceId } = filters
  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents(
    {
      includeHidden: true,
      orderBy: "name",
      orderDir: "ASC",
      searchTerm: searchTerm.trim(),
      sourceId: sourceId ?? undefined,
    },
    {
      refetchOnMount: "always",
    },
  )

  const { data: currentUser } = useFetchCurrentUser()
  const { mutateAsync: modifyUser, isLoading } = useModifyUser()
  const toggleShowHidden = () => {
    if (!currentUser) return

    modifyUser({
      id: currentUser.id,
      data: {
        frontend_settings: {
          ...(currentUser.frontend_settings ?? {}),
          showHiddenEvents: !currentUser.frontend_settings?.showHiddenEvents ?? false,
        },
      },
    })
  }

  const showHidden = currentUser?.frontend_settings?.showHiddenEvents ?? false

  const isFiltering = Boolean(searchTerm || sourceId || !showHidden)
  const filteredEvents = showHidden
    ? events
    : events.filter(({ is_hidden, source }) => is_hidden === 0 && source.is_hidden === 0)

  const columns = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "2fr",
      renderCell: (event: EventFull) => (
        <div data-testid="events-name">
          <Name name={event.name} /> {event.is_system === 1 && <SystemBadge />}{" "}
          <NewBadge created={new Date(event.created)} />
        </div>
      ),
    },
    {
      id: "source",
      label: "Data source",
      gridTemplate: "1fr",
      renderCell: (event: EventFull) => (
        <div className="source-cell-content">
          <div className="source-icon">
            <SrcDstIcon source={event.source} />
          </div>
          <span className="source-name">{event.source.name}</span>
        </div>
      ),
    },
    {
      id: "priority",
      label: "Priority",
      gridTemplate: "max-content",
      renderCell: (event: EventFull) =>
        event.priority === 1 && (
          <div data-testid="priority">
            <FontAwesomeIcon className="priority-icon" icon={["fas", "check"]} />
          </div>
        ),
    },
    {
      id: "autoload",
      label: "Autoload",
      gridTemplate: "max-content",
      renderCell: (event: EventFull) =>
        event.auto_load?.enabled && (
          <div data-testid="autoload">
            <FontAwesomeIcon className="autoload-icon" icon={["fas", "check"]} />
          </div>
        ),
    },
    {
      id: "created",
      label: "Date added",
      gridTemplate: "max-content",
      renderCell: (event: EventFull) => <Datetime datetime={event.created} />,
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: (event: EventFull) => (
        <div className="actions" data-testid="actions">
          <Link to={getRoutePath("administration.events.detail", { id: event.id })}>
            <IconButton
              data-testid="edit-button"
              color="black"
              size="xs"
              icon="pencil-alt"
              tooltip="Edit"
              variant="outlined"
            />
          </Link>

          {!event.is_system && <HideButton event={event} />}
        </div>
      ),
    },
  ]

  return (
    <Page
      className="setup-events"
      title="Events"
      headerContent={
        <>
          <SearchWithSourceSelect
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            selectValue={sourceId}
            setSelectValue={setSourceId}
            showHiddenSources
            placeholder="Search for events"
          />
          <Link to={getRoutePath("administration.events.create")}>
            <Button>+ Create event</Button>
          </Link>
        </>
      }
    >
      {!areEventsFulfilled && <LoadingIndicator />}

      {areEventsFulfilled && (
        <Paper noPaddingTop={filteredEvents.length > 0}>
          <div className="top-bar">
            <div>Show hidden events</div>
            <ToggleButton
              value={showHidden}
              handleToggle={toggleShowHidden}
              size="sm"
              isLoading={isLoading}
            />
          </div>

          <Table
            data={filteredEvents}
            columns={columns}
            emptyMessage={
              isFiltering ? "Nothing found." : 'Click on "Create Event" to get started.'
            }
            getRowClassName={event => classNames({ "is-hidden": event.is_hidden })}
            getRowTestId={always("one-event-table")}
          />
        </Paper>
      )}
    </Page>
  )
}

function HideButton({ event }: { event: EventFull }) {
  const modifyMutation = useModifyEvent()
  const toggleEventIsHidden = (event: EventFull) =>
    modifyMutation.mutate({ id: event.id, data: { is_hidden: event.is_hidden ? 0 : 1 } })

  return (
    <IconButton
      data-testid="hide-button"
      color="red"
      size="xs"
      onClick={() => toggleEventIsHidden(event)}
      disabled={event.source.is_hidden === 1}
      icon={event.is_hidden ? "eye" : "eye-slash"}
      iconStyle="far"
      tooltip={event.source.is_hidden === 1 ? "Hidden source" : event.is_hidden ? "Show" : "Hide"}
      variant="outlined"
      loading={modifyMutation.isLoading}
    />
  )
}
