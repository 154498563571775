import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import AttributeCreate from "./AttributeCreate/AttributeCreate"
import AttributeDetail from "./AttributeDetail/AttributeDetail"
import AttributesList, { useFiltersStore } from "./AttributesList/AttributesList"

export default function AttributesSetup() {
  const { reset } = useFiltersStore() as { reset: () => void }
  useEffect(() => reset, [reset])

  return (
    <Switch>
      <Route path={getRoutePath("administration.attributes")} exact component={AttributesList} />
      <Route path={getRoutePath("administration.attributes.create")} component={AttributeCreate} />
      <Route path={getRoutePath("administration.attributes.detail")} component={AttributeDetail} />
    </Switch>
  )
}
