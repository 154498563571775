import React, { useState, useEffect, lazy, Suspense } from "react"
import IconButton from "components/UI/elements/IconButton/IconButton"

import Modal from "../../elements/Modal"
import Button from "../../elements/Button/Button"

import styles from "./ExpandableAceEditor.module.scss"
import classNames from "classnames"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type ExpandableAceEditorProps = {
  input: any
  expandModalTitle?: string
  label: string
  meta?: any
  className?: string
  width?: string
  height?: string
  disabled?: boolean
}

const ExpandableAceEditor = ({
  input,
  expandModalTitle,
  label,
  meta: { touched, error } = {},
  className,
  width = "100%",
  height = "80px",
  disabled,
}: ExpandableAceEditorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [expandAceValue, setExpandAceValue] = useState<string>(input.value)

  useEffect(() => {
    setExpandAceValue(input.value)
  }, [open, input.value])

  const confirm = () => {
    input.onChange(expandAceValue)
    closeExpandModal()
  }

  const closeExpandModal = () => {
    setOpen(false)
  }

  const openExpandModal = () => {
    setOpen(true)
  }

  return (
    <div
      className={classNames(styles.aceEditorContainer, "ace-editor-wrapper", className, {
        error: touched && error,
      })}
    >
      {label && <p className="label-like">{label}</p>}
      <div className={styles.aceEditorWrapperInner}>
        <Suspense fallback={<LoadingIndicator />}>
          <AceEditor
            value={input.value}
            onChange={input.onChange}
            mode="pgsql"
            theme="github"
            editorProps={{ $blockScrolling: true }}
            width={width}
            height={height}
            wrapEnabled={true}
            className="ace-editor"
            errorMessage={touched && error ? error : undefined}
            readOnly={disabled}
          />
        </Suspense>
        <IconButton
          color="grey"
          className={styles.expandButton}
          onClick={openExpandModal}
          data-testid="expand-button"
          icon="expand-alt"
          iconStyle="far"
          tooltip="Expand"
          variant="outlined"
        />
        <Modal
          open={open}
          handleClose={closeExpandModal}
          title={expandModalTitle ?? label ?? ""}
          className=""
          customCloseIconName="compress-alt"
          customCloseIconStyle="far"
          size="large"
        >
          <div className={styles.textareaExpandModal}>
            <AceEditor
              mode="pgsql"
              theme="github"
              name="name"
              onChange={setExpandAceValue}
              editorProps={{ $blockScrolling: true }}
              width="717px"
              height="400px"
              wrapEnabled={true}
              className="ace-editor"
              value={expandAceValue}
              focus
              errorMessage={touched && error ? error : undefined}
              readOnly={disabled}
            />
            <div className={styles.modalButtons}>
              {disabled ? (
                <Button color="grey" size="md" variant="text" onClick={closeExpandModal}>
                  Close
                </Button>
              ) : (
                <>
                  <Button color="grey" size="md" variant="text" onClick={closeExpandModal}>
                    Cancel
                  </Button>
                  <Button size="md" onClick={confirm}>
                    Confirm
                  </Button>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default ExpandableAceEditor
