import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { api } from "api"
import {
  BlockEmailPayload,
  CreateEmailPayload,
  Email,
  EmailListResponse,
  EmailSelectionOptions,
  ModifyEmailPayload,
  UnblockEmailPayload,
} from "./emailTypes"
import { showToast } from "app/toast"
import { ascend, descend } from "utilities/comparators"
import { isNil, prop, sort, update } from "ramda"
import { useCallback } from "react"
import { sortByDateProp } from "resources/utils"

export const EMAIL = "email" as const
export const EMAIL_ALL_QK: QueryKey = [EMAIL, "all"]

function useEmailsQuery<T = EmailListResponse>(
  config?: UseQueryOptions<EmailListResponse, unknown, T, QueryKey>,
) {
  return useQuery(EMAIL_ALL_QK, api.email.list, config)
}

export const useFetchAllEmails = (
  config?: UseQueryOptions<EmailListResponse, unknown, EmailListResponse, QueryKey>,
  options?: EmailSelectionOptions,
) =>
  useEmailsQuery({
    ...config,
    select: data => {
      if (!options) return data
      const { emails } = data

      const filteredEmails = options.searchTerm
        ? emails.filter(({ name }) => name.toLowerCase().includes(options.searchTerm.toLowerCase()))
        : emails

      let sortedEmails: Array<Email> = []
      if (options.orderBy === "last_activation")
        sortedEmails = sortByDateProp(filteredEmails, options.orderDir, "last_activation")
      else {
        const comparator = options.orderDir === "ASC" ? ascend : descend
        sortedEmails = sort(comparator(prop(options.orderBy)), filteredEmails)
      }

      return { ...data, emails: sortedEmails }
    },
  })

export function useFetchEmailById(id?: Email["id"] | null) {
  const select = useCallback(
    ({ emails }: EmailListResponse) => emails.find(email => email.id === id) ?? null,
    [id],
  )

  return useEmailsQuery({
    enabled: !isNil(id),
    select,
  })
}

export function useCreateEmail() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: CreateEmailPayload }) => api.email.create(data), {
    onSuccess: ({ email }) => {
      queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
        if (!data) {
          return
        }

        // TODO: Do we want to sort here? The new email will jump into correct position after refetch
        return { ...data, emails: data.emails.concat(email) }
      })
      showToast("Email created.")
    },
  })
}

export function useModifyEmail() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Email["id"]; data: ModifyEmailPayload }) => api.email.modify(id, data),
    {
      onSuccess: ({ email }) => {
        queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.emails.findIndex(({ id }) => id === email.id)

          const emails =
            index === -1 ? data.emails.concat(email) : update(index, email, data.emails)

          return { ...data, emails }
        })
      },
    },
  )
}

export const useDeleteEmail = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Email["id"] }) => api.email.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<EmailListResponse>(EMAIL_ALL_QK, data => {
        if (!data) return

        const emails = data.emails.filter(item => item.id !== id)

        return { ...data, emails }
      })
      showToast("Email deleted.")
    },
  })
}

export const useBlockEmail = () =>
  useMutation((data: BlockEmailPayload) => api.email.block(data), {
    onSuccess() {
      showToast("Triggered email blocking.")
    },
  })
export const useUnblockEmail = () =>
  useMutation((data: UnblockEmailPayload) => api.email.unblock(data), {
    onSuccess() {
      showToast("Triggered email unblocking.")
    },
  })
