import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import RolesList from "./RolesList/RolesList"
import RoleCreate from "./RoleCreate/RoleCreate"
import RoleDetail from "./RoleDetail/RoleDetail"

export default function Roles() {
  return (
    <Switch>
      <Route path={getRoutePath("administration.roles")} exact component={RolesList} />
      <Route
        path={getRoutePath("administration.roles.trash")}
        render={() => <Trash itemType="user_roles" />}
      />
      <Route path={getRoutePath("administration.roles.create")} component={RoleCreate} />
      <Route path={getRoutePath("administration.roles.detail")} component={RoleDetail} />
    </Switch>
  )
}
