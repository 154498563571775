import React from "react"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { getRoutePath } from "routes"
import AttributeId from "components/UI/components/AttributeId/AttributeId"
import "./AttributesList.scss"
import {
  useFetchActiveLabels,
  useFetchAllAttributes,
  useModifyAttribute,
} from "resources/attribute/attributeQueries"
import create from "zustand"
import { without } from "ramda"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table from "components/Table/Table"
import classNames from "classnames"
import SearchWithSourceSelect from "components/UI/components/SearchWithSourceSelect/SearchWithSourceSelect"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import NewBadge from "components/UI/elements/NewBadge/NewBadge"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import { Link } from "react-router-dom"
import { AttributeFull, AttributeSort } from "resources/attribute/attributeTypes"
import { OrderDir } from "types/util"
import { Label } from "resources/attributeLabel/attributeLabelTypes"
import { Source } from "resources/dataSource/dataSourceTypes"

type FilterState = {
  orderBy: AttributeSort
  orderDir: OrderDir
  labelIds: Label["id"][]
  searchTerm: string
  sourceId: Source["id"] | null
  addLabelId: (labelId: Label["id"]) => void
  removeLabelId: (labelId: Label["id"]) => void
  reset: () => void
  setSearchTerm: (searchTerm: string) => void
  setSourceId: (sourceId: Source["id"] | null) => void
  setSort: (orderBy: AttributeSort) => void
}

const filtersInitialState = {
  orderBy: "name" as AttributeSort,
  orderDir: "ASC" as OrderDir,
  labelIds: [],
  searchTerm: "",
  sourceId: null,
}

export const useFiltersStore = create<FilterState>(set => ({
  ...filtersInitialState,
  reset: () => set(filtersInitialState),
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
  addLabelId: labelId =>
    set(({ labelIds }) => ({
      labelIds: labelIds.includes(labelId) ? labelIds : labelIds.concat(labelId),
    })),
  removeLabelId: labelId => set(({ labelIds }) => ({ labelIds: without([labelId], labelIds) })),
  setSourceId: sourceId => set({ sourceId }),
}))

export default function AttributesList() {
  const filters = useFiltersStore()
  const {
    orderBy,
    orderDir,
    labelIds,
    searchTerm,
    sourceId,
    addLabelId,
    removeLabelId,
    setSearchTerm,
    setSort,
    setSourceId,
  } = filters

  const { data: attributesLabels } = useFetchActiveLabels()

  // Only filter by labels that are assigned to some attributes to prevent a bug where a label is
  // removed from all labels and the filter by label is maintained, returning empty table
  const filteredLabelIds = attributesLabels
    ? labelIds.filter(id => attributesLabels.some(label => label.id === id))
    : labelIds

  const { data: attributes = [], isSuccess: areAttributesFulfilled } = useFetchAllAttributes(
    {
      includeHidden: true,
      orderBy,
      orderDir,
      searchTerm: searchTerm.trim(),
      labelIds: filteredLabelIds,
      sourceId: sourceId ?? undefined,
    },
    { refetchOnMount: "always" },
  )

  const { data: currentUser } = useFetchCurrentUser()
  const { mutateAsync: modifyUser, isLoading: isModifying } = useModifyUser()
  const toggleShowHidden = () => {
    if (!currentUser) return

    modifyUser({
      id: currentUser.id,
      data: {
        frontend_settings: {
          ...(currentUser.frontend_settings ?? {}),
          showHiddenAttributes: !currentUser.frontend_settings?.showHiddenAttributes ?? false,
        },
      },
    })
  }

  const showHidden = currentUser?.frontend_settings?.showHiddenAttributes ?? false

  const renderSelectedLabels = () => {
    if (!labelIds || labelIds.length === 0) return null

    return labelIds.map(labelId => {
      const label = attributesLabels?.find(label => label.id === labelId)
      if (!label) return null
      return (
        <Tag key={label.id} clickable color="primary" onClick={() => removeLabelId(label.id)}>
          {label.name}
        </Tag>
      )
    })
  }

  const columns = [
    {
      id: "name",
      label: "Name",
      onSort: () => setSort("name"),
      gridTemplate: "1.2fr",
      renderCell: (attribute: AttributeFull) => (
        <div className="attribute-name-cell">
          <div className="attribute-name">
            {attribute.name} {attribute.is_system === 1 && <SystemBadge />}{" "}
            <NewBadge created={attribute.created} />
          </div>
          {isAttributeCompound(attribute.data_type) && (
            <ul className="sub-attrs">
              {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                <li key={subAttr.id}>{subAttr.name}</li>
              ))}
            </ul>
          )}
          <AttributeId id={attribute.id} />
        </div>
      ),
    },
    {
      id: "source",
      label: "Data source",
      onSort: () => setSort("source"),
      gridTemplate: "1fr",
      renderCell: (attribute: AttributeFull) => (
        <>
          <SrcDstIcon source={attribute.source} className="source-icon" />
          <div className="source-name">{attribute.source.name}</div>
        </>
      ),
    },
    {
      id: "labels",
      label: "Labels",
      onSort: () => setSort("labels"),
      gridTemplate: "1fr",
      renderCell: (attribute: AttributeFull) => (
        <div className="attribute-tags">
          {attribute.tags?.map(tag => (
            <Tag
              key={tag.id}
              color="primary"
              onClick={() => addLabelId(tag.id)}
              className="attribute-label"
            >
              {tag.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "1fr",
      renderCell: (attribute: AttributeFull) => (
        <div className="attribute-description">{attribute.description}</div>
      ),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: (attribute: AttributeFull) => (
        <div className="actions">
          <Link to={getRoutePath("administration.attributes.detail", { id: attribute.id })}>
            <IconButton
              color="black"
              variant="outlined"
              size="xs"
              icon="pencil-alt"
              tooltip="Edit"
            />
          </Link>
          {attribute.is_system !== 1 && <HideButton attribute={attribute} />}
        </div>
      ),
    },
  ]

  const filteredAttributes = showHidden
    ? attributes
    : attributes?.filter(({ is_hidden, source }) => is_hidden === 0 && source.is_hidden === 0)
  const isFiltering = Boolean(searchTerm || sourceId || labelIds.length > 0 || !showHidden)

  return (
    <Page
      className="setup-attributes"
      title="Attributes"
      headerContent={
        <>
          <SearchWithSourceSelect
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            selectValue={sourceId}
            setSelectValue={setSourceId}
            showHiddenSources
            placeholder="Search for attributes"
          />
          <Link to={getRoutePath("administration.attributes.create")}>
            <Button>+ Create attribute</Button>
          </Link>
        </>
      }
    >
      {!areAttributesFulfilled && <LoadingIndicator />}

      {areAttributesFulfilled && (
        <Paper noPaddingTop>
          <div className="top-bar">
            <div className="labels-filter">
              <div className="filter-by">Filter by:</div>
              <div className="selected-labels">{renderSelectedLabels()}</div>
              <TagPicker
                selectedTagIds={labelIds}
                allTags={attributesLabels}
                onTagSelect={addLabelId}
                type="label"
              />
            </div>
            <div className="toggle-hidden">
              <div>Show hidden attributes</div>
              <ToggleButton
                value={showHidden}
                handleToggle={toggleShowHidden}
                size="sm"
                isLoading={isModifying}
              />
            </div>
          </div>
          <Table
            columns={columns}
            data={filteredAttributes}
            sortBy={orderBy}
            sortDir={orderDir}
            getRowClassName={attribute => classNames({ isHidden: attribute.is_hidden })}
            emptyMessage={
              isFiltering ? "Nothing found." : 'Click on the "Create Attribute" to get started.'
            }
          />
        </Paper>
      )}
    </Page>
  )
}

function HideButton({ attribute }: { attribute: AttributeFull }) {
  const modifyMutation = useModifyAttribute()

  const toggleHidden = (attribute: AttributeFull) =>
    modifyMutation.mutate({ id: attribute.id, data: { is_hidden: attribute.is_hidden ? 0 : 1 } })

  return (
    <IconButton
      color="red"
      size="xs"
      variant="outlined"
      onClick={() => toggleHidden(attribute)}
      className="left-margin"
      disabled={attribute.source.is_hidden === 1}
      iconStyle="far"
      icon={attribute.is_hidden ? "eye" : "eye-slash"}
      tooltip={
        attribute.source.is_hidden === 1 ? "Hidden source" : attribute.is_hidden ? "Show" : "Hide"
      }
      loading={modifyMutation.isLoading}
    />
  )
}
