import React, { Component } from "react"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"
import { OPTION_GROUP_ICONS, OPTION_GROUP_COLORS, TOAST } from "sharedConstants"
import IconsRadioGroup from "components/UI/components/IconsRadioGroup"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { ReactSortable } from "react-sortablejs"
import Tag from "components/UI/elements/Tag"
import { showToast } from "app/toast"
import "./DestinationForm.scss"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { identity, isNil, pickBy } from "ramda"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import TextArea from "components/UI/elements/TextArea/TextArea"
import TextInput from "components/UI/elements/TextInput/TextInput"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import AttributePicker from "components/AttributePicker/AttributePicker"

const VALUE_FROM_ERROR_MESSAGE = "Select at least one"

class DestinationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draggingData: null,
      attributeTooltip: null,
      attributeDragging: false,
      showExportedAttributesPicker: false,
      showMandatoryAttributesPicker: false,
    }
  }

  renderParameters = () => {
    const {
      parameters,
      removeParameter,
      appendParameter,
      register,
      control,
      errors,
      trigger,
      isSubmitted,
    } = this.props
    return (
      <div data-testid="parameter-content" className="parameter-content">
        {parameters.map((field, index) => (
          <div
            key={field.id}
            data-testid="parameter-wrapper"
            className={`parameter-item-wrapper ${index === parameters.length - 1 ? "last" : ""}`}
          >
            <div className="parameter-box">
              <div className="row">
                <TextInput
                  {...register(`settings.mi_workspace_variables.${index}.name`, {
                    validate: required,
                  })}
                  placeholder="MI Key"
                  label="Meiro Integrations Key"
                  className="parameter-key"
                  error={errors.settings?.mi_workspace_variables?.[index]?.name?.message}
                />
                <TextInput
                  {...register(`settings.mi_workspace_variables.${index}.title`, {
                    validate: required,
                  })}
                  placeholder="Name"
                  label="Name"
                  className="parameter-name"
                  error={errors.settings?.mi_workspace_variables?.[index]?.title?.message}
                />
              </div>
              <div className="checkboxes">
                <Controller
                  control={control}
                  name={`settings.mi_workspace_variables.${index}.required`}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      label="Required field"
                      className="divider"
                    />
                  )}
                />
                <span className="value-from">Value from:</span>
                <Controller
                  control={control}
                  rules={{
                    validate: (value, values) =>
                      !value &&
                      !values.settings?.mi_workspace_variables?.[index]?.input_mode?.user_input
                        ? VALUE_FROM_ERROR_MESSAGE
                        : undefined,
                  }}
                  name={`settings.mi_workspace_variables.${index}.input_mode.value_from_list`}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={e => {
                        field.onChange(e.target.checked)
                        if (isSubmitted)
                          trigger(`settings.mi_workspace_variables.${index}.input_mode.user_input`)
                      }}
                      label="List"
                      errorMessage={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`settings.mi_workspace_variables.${index}.input_mode.user_input`}
                  rules={{
                    validate: (value, values) =>
                      !value &&
                      !values.settings?.mi_workspace_variables?.[index]?.input_mode?.value_from_list
                        ? VALUE_FROM_ERROR_MESSAGE
                        : undefined,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={e => {
                        field.onChange(e.target.checked)
                        if (isSubmitted)
                          trigger(
                            `settings.mi_workspace_variables.${index}.input_mode.value_from_list`,
                          )
                      }}
                      label="User input"
                      errorMessage={error?.message}
                    />
                  )}
                />
              </div>
            </div>
            <IconButton
              color="red"
              onClick={() => removeParameter(index)}
              icon="trash-alt"
              data-testid="delete-parameter"
              className="trash"
              tooltip="Delete"
              variant="transparent"
            />
          </div>
        ))}
        <Button
          size="md"
          onClick={() => appendParameter({ required: true, values: {} })}
          className={parameters.length === 0 ? "add-parameter-button mt" : "add-parameter-button"}
        >
          + Add parameter
        </Button>
      </div>
    )
  }

  submitForm = values => {
    const { disabledFields = [], onSubmit } = this.props
    const data = {
      name: values.name,
      icon: `${values.icon}.png`,
      description: values.description,
      attribute_ids: values.attribute_ids,
      mandatory_attribute_ids: values.mandatory_attribute_ids,
      settings: {},
      frontend_settings: {
        color: values.color,
      },
    }

    if (!disabledFields.includes("id")) {
      data.id = values.id
    }

    if (values.mi_workspace) {
      data.mi_workspace_id = values.mi_workspace.value
    }

    if (values.mandatory_attribute_ids && values.mandatory_attribute_ids.length > 1) {
      data.settings.mandatory_attributes_operator =
        values.settings?.mandatory_attributes_operator ?? "or"
    }

    const parameters = values.settings?.mi_workspace_variables
    if (parameters) {
      const transformedParameters = parameters.map(param => ({
        ...param,
        input_mode: Object.keys(pickBy(identity, param.input_mode)),
      }))
      data.settings = {
        ...data.settings,
        mi_workspace_variables: transformedParameters,
      }
    }

    onSubmit(data)
  }

  getAttributeList = attributeIds => {
    if (isNil(attributeIds) || !this.props.areAttributesFulfilled) {
      return []
    }

    const { attributesMap } = this.props
    let attributes = []
    if (Array.isArray(attributeIds)) {
      attributes = attributeIds
    } else {
      attributes = [...attributeIds]
    }

    attributes = attributes.map(id => {
      const [attributeId, dimensionId] = id.split(".")
      const attribute = attributesMap[attributeId]

      if (!attribute) {
        return { id, name: `N/A attribute: ${id}` }
      }

      if (dimensionId) {
        const dimension = getCompoundAttributeSubAttribute(dimensionId, attribute.data_type)

        if (!dimension) {
          return { id, name: `N/A attribute: ${id}` }
        }

        return { ...attribute, id, name: `${attribute.name}: ${dimension.name}` }
      }

      return attribute
    })

    return attributes
  }

  selectExportedAttribute = attributeId => {
    if (!attributeId) return

    const { getValues, setValue } = this.props
    const attributeIds = getValues().attribute_ids
    let newAttributeIds
    if (Array.isArray(attributeIds)) {
      if (!attributeIds.includes(attributeId)) {
        newAttributeIds = [...attributeIds, attributeId]
        this.updateDraggingData(newAttributeIds)
        setValue("attribute_ids", newAttributeIds)
      } else {
        showToast(`Attribute already added.`, TOAST.TYPE.ERROR)
      }
    } else {
      newAttributeIds = [attributeId]
      this.updateDraggingData(newAttributeIds)
      setValue("attribute_ids", newAttributeIds)
    }
  }

  selectMandatoryAttribute = attributeId => {
    if (!attributeId) return

    const { getValues, setValue } = this.props
    const mandatoryAttributeIds = getValues().mandatory_attribute_ids
    let newMandatoryAttributeIds
    if (Array.isArray(mandatoryAttributeIds)) {
      if (!mandatoryAttributeIds.includes(attributeId)) {
        newMandatoryAttributeIds = [...mandatoryAttributeIds, attributeId]
        setValue("mandatory_attribute_ids", newMandatoryAttributeIds)
      } else {
        showToast(`Attribute already added.`, TOAST.TYPE.ERROR)
      }
    } else {
      newMandatoryAttributeIds = [attributeId]
      setValue("mandatory_attribute_ids", newMandatoryAttributeIds)
    }
  }

  onExportedAttributesPickerClose = () => {
    this.setState({ showExportedAttributesPicker: false })
  }

  showExportedAttributesPicker = () => {
    this.setState({ showExportedAttributesPicker: true })
  }

  onMandatoryAttributesPickerClose = () => {
    this.setState({ showMandatoryAttributesPicker: false })
  }

  showMandatoryAttributesPicker = () => {
    this.setState({ showMandatoryAttributesPicker: true })
  }

  onExportedAttributesSort = evt => {
    const { getValues, setValue } = this.props
    const attributeIds = getValues().attribute_ids
    const { oldIndex, newIndex } = evt
    if (oldIndex !== newIndex) {
      const newAttributeIds = [...attributeIds]
      newAttributeIds.splice(oldIndex, 1)
      newAttributeIds.splice(newIndex, 0, attributeIds[oldIndex])
      setValue("attribute_ids", newAttributeIds)
    }
  }

  onExportedAttrMouseOver = attributeId => {
    const { attributeDragging, attributeTooltip } = this.state
    if (attributeTooltip !== attributeId && !attributeDragging) {
      this.setState({
        attributeTooltip: attributeId,
      })
    }
  }

  onExportedAttrMouseOut = attributeId => {
    const { attributeDragging, attributeTooltip } = this.state
    if (attributeTooltip === attributeId && !attributeDragging) {
      this.setState({
        attributeTooltip: null,
      })
    }
  }

  deleteExportedAttribute = attributeId => () => {
    const { getValues, setValue } = this.props
    const attributeIds = getValues().attribute_ids
    const newAttributeIds = attributeIds.filter(id => id !== attributeId)
    setValue("attribute_ids", newAttributeIds)
    this.updateDraggingData(newAttributeIds)
  }

  deleteMandatoryAttribute = attributeId => () => {
    const { getValues, setValue } = this.props
    const mandatoryAttributeIds = getValues().mandatory_attribute_ids
    const newMandatoryAttributeIds = mandatoryAttributeIds.filter(id => id !== attributeId)
    setValue("mandatory_attribute_ids", newMandatoryAttributeIds)
  }

  updateDraggingData = attributeIds => {
    this.setState({
      draggingData: this.getAttributeList(attributeIds),
    })
  }

  renderExportedAttributesList = () => {
    const { attributeTooltip, showExportedAttributesPicker, draggingData } = this.state
    const { getValues } = this.props
    const attributeIds = getValues().attribute_ids
    const attributes = this.getAttributeList(attributeIds)

    const data = draggingData ? draggingData : attributes
    return (
      <div data-testid="exported-attributes" className="exported-attributes">
        <p className="label">Exported attributes</p>
        <div data-testid="attributes" className="attributes">
          {data.length > 0 && (
            <ReactSortable
              list={data}
              setList={newData => {
                this.setState({
                  draggingData: newData,
                })
              }}
              onStart={() => this.setState({ attributeDragging: true })}
              onEnd={() => this.setState({ attributeDragging: false })}
              onUpdate={this.onExportedAttributesSort}
              animation={200}
              handle=".drag-button"
              data-testid="button"
              className="exported-sortable"
            >
              {data.map(attr => {
                return (
                  <Tag
                    key={attr.id}
                    color={attr.is_hidden ? "white-red" : "white"}
                    clickable={false}
                    className={`attribute exported-attr ${
                      attributeTooltip === attr.id ? "show-tooltip" : ""
                    }`}
                    onMouseOver={() => {
                      this.onExportedAttrMouseOver(attr.id)
                    }}
                    onMouseOut={() => {
                      this.onExportedAttrMouseOut(attr.id)
                    }}
                  >
                    {attr.is_hidden === 1 && (
                      <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
                    )}
                    {attr.source ? `${attr.source.name}: ${attr.name}` : attr.name}
                    <span className="tag-actions">
                      <IconButton
                        className="drag-button"
                        color="grey"
                        icon="grip-vertical"
                        variant="transparent"
                      />
                      <IconButton
                        data-testid="delete-attribute"
                        color="grey"
                        onClick={this.deleteExportedAttribute(attr.id)}
                        icon="times"
                        iconStyle="far"
                        variant="transparent"
                      />
                    </span>
                  </Tag>
                )
              })}
            </ReactSortable>
          )}
          {!showExportedAttributesPicker && (
            <span>
              <Button
                className="exported-attributes-button-add"
                color="grey"
                size="xs"
                variant="outlined"
                onClick={this.showExportedAttributesPicker}
              >
                + Add attribute
              </Button>
            </span>
          )}
          {showExportedAttributesPicker && (
            <AttributePicker
              onChange={this.selectExportedAttribute}
              onClose={this.onExportedAttributesPickerClose}
              size="sm"
              autoFocus
              className="exported-attribute-picker"
              includeHidden
            />
          )}
        </div>
      </div>
    )
  }

  renderMandatoryAttributesList = () => {
    const { control, getValues } = this.props
    const { showMandatoryAttributesPicker } = this.state
    const mandatoryAttributeIds = getValues().mandatory_attribute_ids
    const attributes = this.getAttributeList(mandatoryAttributeIds)

    return (
      <div data-testid="mandatory-attributes" className="mandatory-attributes-wrapper">
        <p className="label">Mandatory attributes</p>
        {attributes.length > 1 && (
          <div className="mandatory-operator">
            <Controller
              control={control}
              name="settings.mandatory_attributes_operator"
              render={({ field: { value, onChange } }) => (
                <ToggleSwitch
                  leftValue="and"
                  rightValue="or"
                  width="80px"
                  className="and-or-toggle"
                  size="tiny"
                  handleToggle={() => onChange(value === "and" ? "or" : "and")}
                  checked={value}
                />
              )}
            />
          </div>
        )}
        <div
          data-testid="attached-attributes"
          className={`attributes ${attributes.length > 1 ? "with-operator" : ""}`}
        >
          {attributes.length > 0 && (
            <div data-testid="attached-mandatory-attributes" className="mandatory-attributes">
              {attributes.map(attribute => (
                <Tag
                  key={attribute.id}
                  color={attribute.is_hidden ? "white-red" : "white"}
                  clickable={true}
                  className="attribute"
                  onClick={this.deleteMandatoryAttribute(attribute.id)}
                >
                  {attribute.is_hidden === 1 && (
                    <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
                  )}
                  {attribute.source
                    ? `${attribute.source.name}: ${attribute.name}`
                    : attribute.name}
                </Tag>
              ))}
            </div>
          )}
          {!showMandatoryAttributesPicker && (
            <Button
              color="grey"
              size="xs"
              variant="outlined"
              onClick={this.showMandatoryAttributesPicker}
              className="mandatory-attributes-button-add"
            >
              + Add attribute
            </Button>
          )}
          {showMandatoryAttributesPicker && (
            <AttributePicker
              onChange={this.selectMandatoryAttribute}
              onClose={this.onMandatoryAttributesPickerClose}
              size="sm"
              autoFocus
              className="mandatory-attribute-picker"
              includeHidden
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      handleSubmit,
      register,
      errors,
      control,
      disabledFields = [],
      globalSettings,
      initialValues,
      workspaces,
      areWorkspacesFulfilled,
      areAttributesFulfilled,
      destinations,
    } = this.props
    const isMiApiSet = !isNil(globalSettings?.["mi_api"]?.value)
    const hasMiSettingsSection = initialValues.id > 1000

    const alreadyUsedColors = []
    destinations.forEach(destination => {
      const color = destination.frontend_settings?.color
      if (color) {
        alreadyUsedColors.push(color)
      }
    })

    return (
      <section className="destination-form">
        <form onSubmit={handleSubmit(this.submitForm)} id="destinationForm">
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <TextInput
                {...register("id", { validate: required })}
                placeholder="Destination ID"
                label="Destination ID"
                maxLength={60}
                className="destination-id"
                disabled={disabledFields.includes("id")}
                error={errors.id?.message}
              />
              <TextInput
                {...register("name", { validate: required })}
                placeholder="Name"
                label="Name"
                className="destination-name"
                disabled={disabledFields.includes("name")}
                error={errors.name?.message}
              />
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part">
              <h2>Description</h2>
            </div>
            <div className="right-part">
              <TextArea
                {...register("description")}
                placeholder="Description (optional)"
                label="Description (optional)"
                className="destination-description"
                rows={8}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="left-part">
              <h2>Icon</h2>
            </div>
            <div className="right-part">
              <div>
                <Controller
                  control={control}
                  name="icon"
                  rules={{ validate: required }}
                  render={({ field, fieldState: { error } }) => (
                    <IconsRadioGroup
                      input={field}
                      meta={{ touched: true, error: error?.message }}
                      icons={OPTION_GROUP_ICONS}
                    />
                  )}
                />
                <label className="color-label">Define destination color</label>
                <Controller
                  control={control}
                  name="color"
                  rules={{ validate: required }}
                  render={({ field, fieldState: { error } }) => (
                    <ColorRadioGroup
                      input={field}
                      meta={{ touched: true, error: error?.message }}
                      colors={OPTION_GROUP_COLORS}
                      alreadyUsedColors={alreadyUsedColors}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={`form-row grey ${!hasMiSettingsSection ? "last" : ""}`}>
            <div className="left-part">
              <h2>Attributes</h2>
            </div>
            <div className="right-part flex-wrap">
              {areAttributesFulfilled ? (
                <>
                  {this.renderExportedAttributesList()}
                  {this.renderMandatoryAttributesList()}
                </>
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </div>
          {hasMiSettingsSection && (
            <React.Fragment>
              <div className="form-row">
                <div className="left-part">
                  <h2>Meiro Integrations</h2>
                </div>
                <div className="right-part">
                  {isMiApiSet && (
                    <Controller
                      control={control}
                      name="mi_workspace"
                      render={({ field, fieldState: { error } }) => (
                        <SelectField
                          label="MI Workspace"
                          placeholder="MI Workspace"
                          options={workspaces}
                          isLoading={!areWorkspacesFulfilled}
                          className="destination-mi-workspace"
                          input={field}
                          meta={{ touched: true, error: error?.message }}
                        />
                      )}
                    />
                  )}
                  {!isMiApiSet && <p className="no-mi-api-set">No MI API credentials set</p>}
                </div>
              </div>
              <div className="form-row grey last">
                <div className="left-part">
                  <h2>Parameters</h2>
                </div>
                <div className="right-part">
                  <div className="settings">{this.renderParameters()}</div>
                </div>
              </div>
            </React.Fragment>
          )}
        </form>
      </section>
    )
  }
}

export default props => {
  const { data: attributesMap = {}, isSuccess: areAttributesFulfilled } = useFetchAttributesMap({
    includeHidden: true,
  })
  const { data: destinations = [] } = useFetchAllDestinations()
  const { data: globalSettings } = useFetchGlobalSettings()
  const { data: workspaces = [], isSuccess: areWorkspacesFulfilled } = useFetchWorkspaceOptions()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
    trigger,
    watch,
  } = useForm({ defaultValues: props.initialValues })
  const {
    fields: parameters,
    append: appendParameter,
    remove: removeParameter,
  } = useFieldArray({ name: "settings.mi_workspace_variables", control })

  // Temporary fix to solve a bug; TODO: rewrite to use Controller instead of `setValue` and
  // `getValues` for exported/mandatory attributes
  watch("mandatory_attribute_ids")

  return (
    <DestinationForm
      {...props}
      attributesMap={attributesMap}
      areAttributesFulfilled={areAttributesFulfilled}
      destinations={destinations}
      globalSettings={globalSettings}
      workspaces={workspaces}
      areWorkspacesFulfilled={areWorkspacesFulfilled}
      handleSubmit={handleSubmit}
      register={register}
      errors={errors}
      parameters={parameters}
      appendParameter={appendParameter}
      removeParameter={removeParameter}
      control={control}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      isSubmitted={isSubmitted}
    />
  )
}
