import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import EventCreate from "./EventCreate/EventCreate"
import EventDetail from "./EventDetail/EventDetail"
import EventsList, { useFiltersStore } from "./EventsList/EventsList"

export default function EventsSetup() {
  const { reset } = useFiltersStore() as { reset: () => void }
  useEffect(() => reset, [reset])

  return (
    <Switch>
      <Route path={getRoutePath("administration.events")} exact component={EventsList} />
      <Route path={getRoutePath("administration.events.create")} component={EventCreate} />
      <Route path={getRoutePath("administration.events.detail")} component={EventDetail} />
    </Switch>
  )
}
