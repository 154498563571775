import React, { useMemo, useState } from "react"
import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { useDeleteEmail, useFetchAllEmails } from "resources/email/emailQueries"
import { Email, EmailSelectionState } from "resources/email/emailTypes"

import styles from "./EmailsList.module.scss"
import create from "zustand"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { EMAILS_CHANNEL_NAME } from "sharedConstants"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import {
  useFetchChannels,
  useFetchEmailChannel,
  useModifyEmailsChannelFrequencyCap,
} from "resources/channel/channelQueries"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import EmailsImg from "../../components/EmailsImg.jpg"
import { useHasAccess } from "resources/user/currentUserQueries"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Page from "components/UI/Page/Page"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EmailsChannel } from "resources/channel/channelTypes"
import { isEmpty } from "ramda"
import ChannelFrequencyCap from "components/UI/components/ChannelFrequencyCap/ChannelFrequencyCap"
import { isLocalFrequencyConflicting } from "resources/channel/channelUtils"
import ChannelFrequencyCapModal from "components/UI/components/ChannelFrequencyCapModal/ChannelFrequencyCapModal"
import EmailTypeBadge from "../components/EmailTypeBadge/EmailTypeBadge"
import CampaignSchedulingIcon from "components/UI/components/CampaignSchedulingIcon/CampaignSchedulingIcon"
import { parseJsonContent } from "utilities/channel"
import Table, { Column, Modified, Name } from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"
import EmailsReadyToUse from "../../components/EmailsReadyToUse/EmailsReadyToUse"
import EmailsNoAccess from "../components/EmailsNoAccess/EmailsNoAccess"

const pageTitle = "Email campaigns"

export const getContentType = (jsonContent: string | null) => {
  if (!jsonContent) return null

  const parsedJsonContent = parseJsonContent(jsonContent)
  return parsedJsonContent ? (isEmpty(parsedJsonContent) ? "html" : "json") : null
}

const useStore = create<EmailSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function EmailsList() {
  const [emailToDelete, setEmailToDelete] = useState<Email | null>()
  const [affectedCampaigns, setAffectedCampaigns] = useState<Array<Email>>([])
  const [frequencyCapToSave, setFrequencyCapToSave] = useState<
    EmailsChannel["frequency_cap"] | undefined
  >()

  const history = useHistory()

  const { data: channels, isLoading: isLoadingChannels } = useFetchChannels()
  const emailsEnabled = channels
    ? channels.find(({ name }) => name === EMAILS_CHANNEL_NAME)?.is_active
    : false

  const hasAccess = useHasAccess()
  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()

  const {
    data,
    isLoading: isLoadingEmails,
    isFetching: isFetchingEmails,
  } = useFetchAllEmails(
    {
      enabled: hasAccess.emails.view && emailsEnabled,
    },
    { orderBy, orderDir, searchTerm: searchTerm.trim() },
  )

  const { mutate: deleteEmail, isLoading: isDeleting } = useDeleteEmail()

  const { data: emailChannel, isLoading: isLoadingEmailChannel } = useFetchEmailChannel({
    enabled: hasAccess.setup.channels && emailsEnabled,
  })
  const modifyMutation = useModifyEmailsChannelFrequencyCap()

  const conflictingCampaigns = useMemo(() => {
    if (!data || !emailChannel) return []

    return data.emails.filter(({ frequency_cap }) =>
      isLocalFrequencyConflicting(frequency_cap, emailChannel.frequency_cap),
    )
  }, [data, emailChannel])

  const closeDeleteModal = () => setEmailToDelete(null)

  const closeFrequencyCapModal = () => {
    setAffectedCampaigns([])
    setFrequencyCapToSave(undefined)
  }

  const modifyFrequencyCap = (formFields: EmailsChannel["frequency_cap"]) => {
    if (modifyMutation.isLoading) return

    modifyMutation.mutate(
      { data: formFields },
      {
        onSuccess: () => {
          closeFrequencyCapModal()
        },
      },
    )
  }

  if (isLoadingChannels || (isLoadingEmails && isFetchingEmails))
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )

  if (!emailsEnabled) return <EmailsReadyToUse pageTitle={pageTitle} />
  if (!hasAccess.emails.view) return <EmailsNoAccess pageTitle={pageTitle} />

  const isEmpty = data?.emails.length === 0

  const columns: Column<Email>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      onSort() {
        setSort("name")
      },
      renderCell: email => {
        const contentType = getContentType(email.content_json)

        return (
          <div className={styles.nameWrapper}>
            <CampaignSchedulingIcon schedules={email.schedules} />
            {email.frequency_cap.ignore_channel_frequency_cap && (
              <Tippy content="Ignores global frequency cap." placement="bottom">
                <FontAwesomeIcon icon={["fas", "bolt"]} className={styles.boltIcon} />
              </Tippy>
            )}
            {conflictingCampaigns.find(({ id }) => email.id === id) && (
              <Tippy content="Conflicting with global frequency cap." placement="bottom">
                <FontAwesomeIcon
                  icon={["fas", "exclamation-triangle"]}
                  className={styles.warningIcon}
                />
              </Tippy>
            )}
            <Name name={email.name} className={styles.name} />{" "}
            {contentType && <EmailTypeBadge contentType={contentType} />}
          </div>
        )
      },
    },
    {
      id: "last_activation",
      label: "Last activation",
      gridTemplate: "max-content",
      onSort() {
        setSort("last_activation")
      },
      renderCell: email =>
        email.last_activation && <Datetime precision="minute" datetime={email.last_activation} />,
    },
    {
      id: "modified",
      label: "Modified at",
      gridTemplate: "max-content",
      onSort() {
        setSort("modified")
      },
      renderCell: email => <Modified modifiedAt={email.modified} modifiedBy={email.modified_by} />,
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: email => (
        <div className={styles.actions}>
          <Link
            to={getRoutePath("channels.emails.detail", {
              id: email.id,
            })}
            className={styles.editLink}
          >
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="pencil-alt"
              tooltip="Edit"
              data-testid="edit-email"
            />
          </Link>
          <IconButton
            disabled={!hasAccess.emails.edit}
            color="red"
            icon="trash-alt"
            size="xs"
            tooltip="Delete"
            variant="outlined"
            onClick={() => setEmailToDelete(email)}
            data-testid="remove-email"
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            wrapperClassName={styles.search}
          />
          <Button
            disabled={!hasAccess.emails.edit}
            onClick={() => history.push(getRoutePath("channels.emails.create"))}
          >
            + Create Email
          </Button>
        </>
      }
    >
      {emailChannel && (
        <ChannelFrequencyCap
          channel="emails"
          description="The rule applies to all email campaigns. It restricts the number of emails within a specific time interval. The limit is set to the email address, and if a customer profile has multiple email addresses, all recognized email addresses will be considered one count toward the global limit."
          disabled={!hasAccess.emails.edit}
          isLoading={isLoadingEmailChannel}
          isSaving={modifyMutation.isLoading}
          conflictingCampaigns={conflictingCampaigns.map(({ id, name }) => ({ id, name }))}
          frequencyCap={emailChannel.frequency_cap}
          onSubmit={formValues => {
            if (data?.emails) {
              const conflictingCampaignsToBe = data.emails.filter(({ frequency_cap }) =>
                isLocalFrequencyConflicting(frequency_cap, formValues),
              )

              if (conflictingCampaignsToBe.length > 0) {
                setAffectedCampaigns(conflictingCampaignsToBe)
                setFrequencyCapToSave(formValues)
                return
              }
            }

            modifyFrequencyCap(formValues)
          }}
        />
      )}
      <Paper className={styles.paper}>
        <div className={styles.trashButtonWrapper}>
          <Link to={getRoutePath("channels.emails.trash")}>
            <Button type="button" color="grey" variant="outlined" icon="trash-alt" iconStyle="far">
              Trash
            </Button>
          </Link>
        </div>
        {data?.emails && (
          <Table
            emptyMessage="No emails found."
            columns={columns}
            data={data.emails}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        )}
      </Paper>
      {isEmpty && searchTerm === "" && (
        <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
          <h1>Emails</h1>
          <p>
            In the Emails tab, you can create{" "}
            <strong className={styles.primary}>email campaigns</strong> and deliver them to desired
            audience groups with <strong className={styles.primary}>personalized</strong> content,
            such as names, recommended products, items left in the basket, searched products, and
            more.
          </p>
          <p>
            Check out possible{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
              target="_blank"
              rel="noreferrer"
            >
              use cases
            </a>{" "}
            and access{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/chapter/email-campaigns"
              target="_blank"
              rel="noreferrer"
            >
              tutorials
            </a>{" "}
            for the Email channel.
          </p>
        </MarketingContent>
      )}
      <ConfirmModal
        isLoading={isDeleting}
        open={!!emailToDelete}
        action="delete"
        item={emailToDelete?.name}
        title="Are you sure?"
        type="delete"
        what="email"
        handleClose={closeDeleteModal}
        handleConfirm={() => deleteEmail(emailToDelete!, { onSuccess: () => closeDeleteModal() })}
      />
      {affectedCampaigns.length > 0 && (
        <ChannelFrequencyCapModal
          affectedCampaigns={affectedCampaigns.map(({ id, name }) => ({ id, name }))}
          channel="emails"
          isLoading={modifyMutation.isLoading}
          open={affectedCampaigns.length > 0}
          onSubmit={() => {
            if (frequencyCapToSave) modifyFrequencyCap(frequencyCapToSave)
          }}
          onClose={closeFrequencyCapModal}
        />
      )}
    </Page>
  )
}
