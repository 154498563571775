import React, {
  createContext,
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import Provider from "./Provider/Provider"
import StitchingCategory from "./StitchingCategory/StitchingCategory"
import VerifiedSenders from "./VerifiedSenders/VerifiedSenders"
import { useFetchEmailChannel } from "resources/channel/channelQueries"
import { EmailsChannel as EmailsChannelType } from "resources/channel/channelTypes"
import { useFetchEmailSenders } from "resources/channel/emailSenders/emailSendersQueries"
import { Sender } from "resources/channel/emailSenders/emailSendersTypes"
import { getRoutePath } from "routes"

import styles from "./EmailsChannel.module.scss"

const OptIn = lazy(() => import("./OptIn/OptIn"))
const UnsubscribeBlock = lazy(() => import("./UnsubscribeBlock/UnsubscribeBlock"))

export type EmailChannelSection = "optIn" | "unsubscribeBlock"

export const EmailsChannelItemContext = createContext({
  expandedSection: null as EmailChannelSection | null,
  setExpandedSection: (_ => {}) as Dispatch<SetStateAction<EmailChannelSection | null>>,
})

export default function EmailsChannel() {
  const [expandedSection, setExpandedSection] = useState<EmailChannelSection | null>(null)

  const { data: channel, isLoading: isLoadingChannel } = useFetchEmailChannel()
  const { data: senders = [], isLoading: isLoadingSenders } = useFetchEmailSenders()

  const isLoading = isLoadingChannel || isLoadingSenders

  return (
    <Page title="Email channel" backRouteFallback={getRoutePath("administration.channels")}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && channel && (
        <>
          {channel.is_active ? (
            <EmailsChannelItemContext.Provider value={{ expandedSection, setExpandedSection }}>
              <div className={styles.page}>
                <Provider config={channel.provider_config} />
                <StitchingCategory stitchingCategoryId={channel.emails_stitching_category_id} />
                <VerifiedSenders />
                <Suspense fallback={<LoadingIndicator />}>
                  <OptIn config={channel.opt_in_email_config} />
                </Suspense>
                <Suspense fallback={<LoadingIndicator />}>
                  <UnsubscribeBlock config={channel.email_unsubscribe_block} />
                </Suspense>
              </div>
            </EmailsChannelItemContext.Provider>
          ) : (
            <EmailsChannelWizard channel={channel} senders={senders} />
          )}
        </>
      )}
    </Page>
  )
}

function useIsFirstRender(): boolean {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

type EmailsChannelWizardProps = {
  channel: EmailsChannelType
  senders: Array<Sender>
}

const EmailsChannelWizard = ({ channel, senders }: EmailsChannelWizardProps) => {
  const isFirstRender = useIsFirstRender()
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (!isFirstRender) return

    if (!channel.provider_config) setStep(1)
    else if (!channel.emails_stitching_category_id) setStep(2)
    else if (senders.length === 0) setStep(3)
    else if (!channel.opt_in_email_config) setStep(4)
    else if (!channel.email_unsubscribe_block) setStep(5)
  }, [isFirstRender, channel, senders])

  const goBack = () => setStep(prev => prev - 1)
  const goForward = () => setStep(prev => prev + 1)

  switch (step) {
    case 1:
      return <Provider isWizard config={channel.provider_config} goForward={goForward} />
    case 2:
      return (
        <StitchingCategory
          isWizard
          stitchingCategoryId={channel.emails_stitching_category_id}
          goBack={goBack}
          goForward={goForward}
        />
      )
    case 3:
      return <VerifiedSenders isWizard goBack={goBack} goForward={goForward} />
    case 4:
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <OptIn
            isWizard
            config={channel.opt_in_email_config}
            goBack={goBack}
            goForward={goForward}
          />
        </Suspense>
      )
    case 5:
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <UnsubscribeBlock config={channel.email_unsubscribe_block} goBack={goBack} />
        </Suspense>
      )
    default:
      return null
  }
}
