import React from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"
import lookalikes from "./lookalikes.gif"

const CURRENT_NEW_FEATURE = "lookalikes"

export default function NewReleaseModal() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { id, frontend_settings } = currentUser!

  const handleClose = () => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      },
    })
  }

  const { lastReleaseModalShown } = frontend_settings ?? {}

  // @ts-ignore
  if (window.Cypress) return null

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      customHeader={
        <header>
          <h3>🎉 Check out the updates:</h3>
          <IconButton
            color="grey"
            icon="times"
            iconStyle="fas"
            tooltip="Close"
            variant="transparent"
            onClick={handleClose}
            data-testid="close-button"
          />
        </header>
      }
      handleClose={handleClose}
      className={styles.modal}
      contentClassName={styles.content}
    >
      <h3>Find more customers with Lookalike Segments</h3>
      <p>
        Our newest feature, Lookalike Segments, is an intelligent solution to expand your marketing
        campaigns' reach across any channel you choose. Find more users across your CDP database who
        share characteristics similar to your target audience profiles, increasing the reach,
        relevance, and conversion rates of your marketing initiatives.
      </p>
      <img src={lookalikes} alt="" />

      <p>
        <strong>5 ways you can use it to your benefit:</strong>
      </p>

      <ul>
        <li>
          <strong>Scaling Customer Acquisition:</strong> Looking to expand segmented audiences to
          reach more people? Lookalike Segments will harness the profile characteristics of your
          existing segments to find more similar prospects in your CDP database.
        </li>
        <li>
          <strong>Improving Targeting Accuracy:</strong> Connect with more potential customers who
          share the same interests and behaviors as your target audience to improve your marketing
          performance.
        </li>
        <li>
          <strong>Exporting Options:</strong> Send lookalike segments to other tools and platforms
          and target expanded audiences across Meta Ads, Google, Hubspot, or any other available{" "}
          <a
            href="https://docs.meiro.io/books/meiro-integrations/chapter/loaders"
            target="_blank"
            rel="noreferrer"
          >
            destination in Meiro Integrations
          </a>
          .
        </li>
        <li>
          <strong>Seamless Cross-Channel Activation:</strong> Target lookalikes via email, banner,
          and mobile push channels to market to a broader yet highly relevant audience.
        </li>
        <li>
          <strong>Rich Insights and Optimization:</strong> Dive deeper into the Lookalike Segment
          insights and gain a better understanding of your audience characteristics.
        </li>
      </ul>

      <p>
        Check out this{" "}
        <a
          href="https://docs.meiro.io/books/meiro-business-explorer/page/lookalike-segments"
          target="_blank"
          rel="noreferrer"
        >
          article
        </a>{" "}
        for more in-depth guidance on effectively utilizing Lookalike Segments.
      </p>

      <div className={styles.buttonWrapper}>
        <Button size="md" onClick={handleClose}>
          Got it
        </Button>
      </div>
    </Modal>
  )
}
