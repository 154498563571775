import { MutableRefObject, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import styles from "./WhitespaceWarning.module.scss"

type WhitespaceWarningProps = {
  className?: string
}

const useDimensions = (myRef: MutableRefObject<any>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions
}

export default function WhitespaceWarning({ className }: WhitespaceWarningProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useDimensions(ref)

  return (
    <div ref={ref} className={classNames(styles.root, className, { [styles.noWrap]: width > 100 })}>
      <FontAwesomeIcon icon={["far", "eyes"]} className={styles.icon} />
      <span>Watch out for trailing whitespace</span>
    </div>
  )
}
