import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Paper from "components/UI/elements/Paper"
import DestinationAttributes from "./components/DestinationAttributes/DestinationAttributes"
import "./Destinations.scss"
import Tippy from "@tippyjs/react"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table, { Column, Name } from "components/Table/Table"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import Datetime from "components/UI/elements/Datetime/Datetime"

export default function Destinations() {
  const { data: destinations = [], isSuccess: areDestinationsFulfilled } = useFetchAllDestinations()

  const columns: Column<SegmentExportDestination>[] = [
    {
      id: "icon",
      gridTemplate: "max-content",
      renderCell: destination => <SrcDstIcon className="icon" destination={destination} />,
    },
    {
      id: "name",
      label: "Destination",
      gridTemplate: "1fr",
      renderCell: destination => <Name name={destination.name} />,
    },
    {
      id: "description",
      label: "Description",
      gridTemplate: "1fr",
      renderCell: destination => destination.description ?? "—",
    },
    {
      id: "exported",
      label: (
        <>
          exported attributes{" "}
          <Tippy
            content="Attributes that will be exported to your chosen destination. This is a
          mandatory field."
          >
            <span>
              <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                data-tip
                data-for="exported-attributes-tooltip"
              />
            </span>
          </Tippy>
        </>
      ),
      gridTemplate: "1fr",
      renderCell: destination => (
        <DestinationAttributes destination={destination} showAttributes="exported" />
      ),
    },
    {
      id: "mandatory",
      label: (
        <>
          mandatory attributes{" "}
          <Tippy
            content="Attributes that must be present in order to export to the destination. This
                        is an optional field."
          >
            <span>
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </span>
          </Tippy>
        </>
      ),
      gridTemplate: "1fr",
      renderCell: destination => (
        <DestinationAttributes destination={destination} showAttributes="mandatory" />
      ),
    },
    {
      id: "lastExport",
      label: "Last export",
      gridTemplate: "max-content",
      renderCell: destination =>
        destination.last_export ? <Datetime datetime={destination.last_export} /> : "never",
    },
  ]

  return (
    <Page className="sources-destinations" title="Destinations">
      {!areDestinationsFulfilled && <LoadingIndicator />}

      {areDestinationsFulfilled && (
        <Paper noPaddingTop>
          <Table
            emptyMessage="No connected destination found."
            data={destinations}
            columns={columns}
          />
        </Paper>
      )}
    </Page>
  )
}
