import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import fetchAll from "helpers/fetchAll.helper"
import { equals, path, prop, sort } from "ramda"
import api from "resources/endpoints"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  PopupWebBanner,
  PopupWebBannerCreatePayload,
  PopupWebBannerModifyPayload,
  PopupWebBannerSettingsModifyPayload,
  PopupWebBannerSort,
} from "./popupWBTypes"

const POPUP_WEB_BANNER = "popupWebBanner" as const
const POPUP_WEB_BANNER_ALL_QK: QueryKey = [POPUP_WEB_BANNER, "all"]

export function useFetchAllPopupWB(
  opts: {
    orderBy: PopupWebBannerSort
    orderDir: OrderDir
    searchTerm: string
  },
  config: UseQueryOptions<PopupWebBanner[], unknown, PopupWebBanner[], QueryKey> = {},
) {
  const queryClient = useQueryClient()
  return useQuery(
    POPUP_WEB_BANNER_ALL_QK,
    () =>
      fetchAll({
        fetchFn: (offset, limit) => api.popupWebBanner.list(offset, limit),
        key: "web_banners",
      }),
    {
      ...config,
      onSuccess: webBanners => {
        webBanners.forEach(web_banner =>
          queryClient.setQueryData([POPUP_WEB_BANNER, web_banner.id], { web_banner }),
        )
      },
      select(webBanners) {
        const filteredBanners = opts.searchTerm
          ? webBanners.filter(({ name }) =>
              name.toLowerCase().includes(opts.searchTerm.toLowerCase()),
            )
          : webBanners

        const getSortingProperty =
          opts.orderBy === "priority" ? path(["settings", "priority"]) : prop(opts.orderBy)
        const comparator = opts.orderDir === "ASC" ? ascend : descend

        return sort(comparator(getSortingProperty), filteredBanners)
      },
    },
  )
}

export function useFetchPopupWBById(id: PopupWebBanner["id"]) {
  return useQuery([POPUP_WEB_BANNER, id], () => api.popupWebBanner.retrieve(id), {
    select: ({ web_banner }) => web_banner,
  })
}

export function useCreatePopupWB() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: PopupWebBannerCreatePayload }) => api.popupWebBanner.create(data),
    {
      onSuccess: ({ web_banner }) => {
        queryClient.setQueryData<PopupWebBanner[]>(POPUP_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          data.push(web_banner)
          return data
        })
        queryClient.setQueryData([POPUP_WEB_BANNER, web_banner.id], { web_banner })
        showToast("Web banner created.")
      },
    },
  )
}

export function useCopyPopupWB() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: PopupWebBanner["id"] }) => api.popupWebBanner.copy(id), {
    onSuccess: ({ web_banner }) => {
      queryClient.setQueryData<PopupWebBanner[]>(POPUP_WEB_BANNER_ALL_QK, data => {
        if (!data) {
          return
        }

        data.push(web_banner)
        return data
      })
      queryClient.setQueryData([POPUP_WEB_BANNER, web_banner.id], { web_banner })
      showToast("Web banner copied.")
    },
  })
}

export function useModifyPopupWB() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: PopupWebBanner["id"]; data: PopupWebBannerModifyPayload }) =>
      api.popupWebBanner.modify(id, data),
    {
      onSuccess: ({ web_banner }, { data }) => {
        queryClient.setQueryData<PopupWebBanner[]>(POPUP_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === web_banner.id)
          if (index === -1) {
            data.push(web_banner)
          } else {
            data[index] = web_banner
          }
          return data
        })
        queryClient.setQueryData([POPUP_WEB_BANNER, web_banner.id], { web_banner })
        showToast(
          equals(data, { disabled: true })
            ? "Web banner disabled."
            : equals(data, { disabled: false })
            ? "Web banner enabled."
            : "Web banner modified.",
        )
      },
    },
  )
}

export function useDeletePopupWB() {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: PopupWebBanner["id"] }) => api.popupWebBanner.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<PopupWebBanner[]>(POPUP_WEB_BANNER_ALL_QK, data => {
        return data?.filter(el => el.id !== id)
      })
      showToast("Web banner deleted.")
    },
  })
}

export function useFetchPopupWBSettings() {
  return useQuery(["popupWebBannerSettings"], () => api.popupWebBanner.settings.retrieve())
}

export function useModifyPopupWBSettings() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: PopupWebBannerSettingsModifyPayload }) =>
      api.popupWebBanner.settings.modify(data),
    {
      onSuccess: data => {
        queryClient.setQueryData(["popupWebBannerSettings"], data)
        showToast("Global settings modified.")
      },
    },
  )
}
