import requestFactory from "api/request"
import {
  ActivateEmailsChannelOptInPayload,
  ChannelListResponse,
  EmailsChannelResponse,
  ModifyEmailsChannelFrequencyCapPayload,
  ModifyEmailsChannelOptInPayload,
  ModifyEmailsChannelProviderConfigPayload,
  ModifyEmailsChannelStitchingCategoryPayload,
  ModifyEmailsChannelUnsubscribeBlock,
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationAppResponse,
  ListPushNotificationAppsResponse,
  PushNotificationChannelReponse,
  ListPushNotificationFirebaseAppsResponse,
} from "./channelTypes"
import senders from "./emailSenders/emailSendersEndpoints"

const channel = {
  list(): Promise<ChannelListResponse> {
    return requestFactory("get", "/channels")
  },
  pushNotifications: {
    retrieve(): Promise<PushNotificationChannelReponse> {
      return requestFactory("get", "/channels/push_notifications")
    },
    modify(data: FormData): Promise<PushNotificationChannelReponse> {
      return requestFactory(
        "patch",
        "/channels/push_notifications",
        data,
        false,
        true,
        "",
        false,
        false,
        { "Content-Type": "multipart/form-data" },
      )
    },
    apps: {
      list(): Promise<ListPushNotificationAppsResponse> {
        return requestFactory("get", "/channels/push_notifications/apps")
      },
      create(data: PushNotificationAppPayload): Promise<PushNotificationAppResponse> {
        return requestFactory("post", "/channels/push_notifications/apps", data)
      },
      modify(
        id: PushNotificationApp["id"],
        data: PushNotificationAppPayload,
      ): Promise<PushNotificationAppResponse> {
        return requestFactory("patch", `/channels/push_notifications/apps/${id}`, data)
      },
      delete(id: PushNotificationApp["id"]): Promise<void> {
        return requestFactory("delete", `/channels/push_notifications/apps/${id}`)
      },
    },
    firebase_apps: {
      list(): Promise<ListPushNotificationFirebaseAppsResponse> {
        return requestFactory("get", "/channels/push_notifications/firebase_apps")
      },
    },
  },
  emails: {
    retrieve(): Promise<EmailsChannelResponse> {
      return requestFactory("get", "/channels/emails")
    },
    modifyFrequencyCap(
      data: ModifyEmailsChannelFrequencyCapPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/frequency_cap", data)
    },
    modifyProviderConfig(
      data: ModifyEmailsChannelProviderConfigPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/provider_config", data)
    },
    modifyStitchingCategory(
      data: ModifyEmailsChannelStitchingCategoryPayload,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/stitching_category", data)
    },
    modifyOptIn(data: ModifyEmailsChannelOptInPayload): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/opt_in", data)
    },
    activateOptIn(data: ActivateEmailsChannelOptInPayload): Promise<void> {
      return requestFactory("post", "/channels/emails/opt_in_activation", data)
    },
    modifyUnsubscribeBlock(
      data: ModifyEmailsChannelUnsubscribeBlock,
    ): Promise<EmailsChannelResponse> {
      return requestFactory("post", "/channels/emails/unsubscribe_block", data)
    },
    senders,
  },
}

export default channel
