import React, { useRef } from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import CreatableSelect from "react-select/creatable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { selectStyles } from "helpers/customSelectStyle.helper"
import Avatar from "../Avatar"
import "./SelectField.scss"
import { isNil, uniq, whereEq } from "ramda"
import IconButton from "../IconButton/IconButton"
import { copyStringToClipboard } from "helpers/string.helper"
import { showToast } from "app/toast"
import { TOAST } from "sharedConstants"
import classNames from "classnames"
import ErrorTippy from "../ErrorTippy/ErrorTippy"

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={["fas", "caret-down"]}
        className="caret-down caret-down-indicator"
        data-testid="select-field-dropdown-indicator"
      />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={["far", "times"]} className="cross-icon" />
    </components.ClearIndicator>
  )
}

const SingleValue = props => {
  const { data } = props

  return (
    <components.SingleValue {...props}>
      <div className="select-single-value">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.SingleValue>
  )
}

const Input = props => {
  const { onPaste } = props.selectProps
  return <components.Input onPaste={onPaste} {...props} />
}

const Option = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="select-option" data-testid="select-field-option">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.Option>
  )
}

const SelectField = ({
  input,
  label,
  meta,
  error = "",
  options,
  onChange = _ => {},
  isSearchable = true,
  isClearable = false,
  isLoading = false,
  size = "medium",
  className = "",
  maxDropdownHeight = "300px",
  isCreatable = false,
  inputId,
  placeholder = "Select...",
  disabled = false,
  focusedBorderColor = "#FE7F66",
  noOptionsMessage,
  isMulti = false,
  allowCopy = false,
  isOptionSelected = undefined,
  hideErrorMessage = false,
  onInputChange,
  isSimpleValue = false,
}) => {
  // If `isSimpleValue`, the value passed into SelectField is the value itself, not the option
  // object, and the value expected to be passed to onChange is also the value itself, not the
  // option.
  let selectedOption = input.value

  if (isSimpleValue && !isNil(input.value)) {
    if (isMulti) {
      selectedOption = input.value.map(
        value => options.find(whereEq({ value })) ?? { value, label: value },
      )
    } else {
      selectedOption = options.find(({ value }) => value === input.value) ?? {
        value: input.value,
        label: input.value,
      }
    }
  }

  const _onChange = option => {
    let valueToSend = option

    if (isSimpleValue) {
      if (isMulti) {
        valueToSend = option.map(({ value }) => value)
      } else {
        valueToSend = option?.value ?? null
      }
    }

    input.onChange(valueToSend)
    onChange?.(valueToSend)
  }

  const errorMessage = meta ? (meta.touched ? meta.error : "") : error
  const SelectToRender = isCreatable ? CreatableSelect : Select
  const inputRef = useRef()
  const hasClearButton = isClearable && input.value && !disabled

  return (
    <div
      className={classNames("select-field", className, {
        error: errorMessage,
        disabled,
        "allow-copy": allowCopy & isMulti,
        "has-clear-button": hasClearButton,
      })}
      data-testid={`select-field-wrapper-${inputId}`}
    >
      {label !== undefined && <label data-testid="label">{label}</label>}

      <ErrorTippy disabled={!errorMessage || hideErrorMessage} content={errorMessage}>
        <div>
          <SelectToRender
            ref={inputRef}
            value={selectedOption}
            onChange={_onChange}
            options={options}
            styles={selectStyles(size, "all", maxDropdownHeight, focusedBorderColor)}
            simpleValue
            isSearchable={isCreatable ? true : isSearchable}
            isClearable={isClearable}
            isLoading={isLoading}
            className="select-input"
            createOptionPosition="first"
            formatCreateLabel={value => `add "${value}"`}
            inputId={inputId}
            placeholder={placeholder}
            components={{
              SingleValue: SingleValue,
              Option: Option,
              DropdownIndicator: DropdownIndicator,
              ClearIndicator: ClearIndicator,
              Input: Input,
            }}
            noOptionsMessage={() => {
              if (noOptionsMessage) {
                return noOptionsMessage
              } else {
                return isCreatable ? "Create new by typing" : "Empty"
              }
            }}
            isDisabled={disabled}
            classNamePrefix="react-select-redux-field"
            isMulti={isMulti}
            isOptionSelected={isOptionSelected}
            onInputChange={value => onInputChange?.(value)}
            onPaste={
              isMulti
                ? evt => {
                    evt.preventDefault()
                    const clipboard = evt.clipboardData.getData("Text")
                    if (!clipboard) return
                    const options = clipboard.split(/,+/).map(value => ({
                      label: value.trim(),
                      value: value.trim(),
                    }))
                    const prevValues = inputRef.current.props.value ?? []
                    // idk why this is happening, but sometimes it's just select.setValue and sometimes it's select.select.setValue
                    if (inputRef.current.select.setValue) {
                      inputRef.current.select.setValue(uniq(prevValues.concat(options)))
                    } else {
                      inputRef.current.select.select.setValue(uniq(prevValues.concat(options)))
                    }
                  }
                : undefined
            }
          />
        </div>
      </ErrorTippy>

      {isMulti && allowCopy && (
        <IconButton
          color="grey"
          onClick={evt => {
            evt.preventDefault()
            if (!inputRef.current?.props.value) {
              showToast("Nothing to copy.", TOAST.TYPE.ERROR)
              return
            }
            const valuesToCopy = inputRef.current.props.value.map(v => v.value).join(",")
            copyStringToClipboard(valuesToCopy)
            showToast("Values copied to a clipboard.")
          }}
          icon="clone"
          iconStyle="far"
          variant="transparent"
          className={`select-copy-icon ${label === undefined ? "no-label" : ""}`}
        />
      )}
    </div>
  )
}

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  size: PropTypes.string,
  maxDropdownHeight: PropTypes.string,
  inputId: PropTypes.string,
  focusedBorderColor: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
  onInputChange: PropTypes.func,
}

export default SelectField
