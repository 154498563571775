import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  AttributeAggregation,
  AttributeAggregationCreatePayload,
  AttributeAggregationListResponse,
  AttributeAggregationModifyPayload,
  AttributeAggregationReorderPayload,
} from "./attributeAggregationTypes"

const attributeAggregation = {
  listAll(only_with_valid_attributes = true): Promise<AttributeAggregationListResponse> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/attributes_aggregations", {
          offset,
          limit,
          order_by: "order_index",
          order_dir: "ASC",
          load_full_structure: 0,
          only_with_valid_attributes,
        }),
      key: "attribute_aggregations",
      limit: 20,
    })
  },
  create(
    data: AttributeAggregationCreatePayload,
  ): Promise<{ attribute_aggregation: AttributeAggregation }> {
    return requestFactory("post", "/attributes_aggregations", data)
  },
  modify(
    aggregation_id: AttributeAggregation["id"],
    data: AttributeAggregationModifyPayload,
  ): Promise<{ attribute_aggregation: AttributeAggregation }> {
    return requestFactory(
      "patch",
      `/attributes_aggregations/aggregations/${aggregation_id}`,
      data,
      true,
    )
  },
  delete(aggregation_id: AttributeAggregation["id"]): Promise<void> {
    return requestFactory(
      "delete",
      `/attributes_aggregations/aggregations/${aggregation_id}`,
      {},
      true,
    )
  },
  reorder(id: AttributeAggregation["id"], data: AttributeAggregationReorderPayload): Promise<void> {
    return requestFactory(
      "patch",
      `/attributes_aggregations/aggregations/${id}/reorder`,
      data,
      true,
    )
  },
}

export default attributeAggregation
