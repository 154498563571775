import { last, prop, whereEq } from "ramda"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import { Feature } from "resources/userRole/userRoleTypes"

export type FeatureItem = { label: string; tagLabel: string; feature: Feature; tooltip: string }

export type RoleFormSection = {
  title: string
  items: FeatureItem[]
}

export const featuresSections: RoleFormSection[] = [
  {
    title: "Profiles",
    items: [
      {
        label: "Search",
        tagLabel: "Profiles / Search",
        feature: "customers/search",
        tooltip: "This access enables users to access the Profiles tab and to search for profiles.",
      },
      {
        label: "Detail",
        tagLabel: "Profiles / Detail",
        feature: "customers/detail",
        tooltip:
          "This access enables users to see the Profile Detail page. Please note that if access to Profiles / Search is not allowed, then the Profile Detail tab will be possible to see only within segments available according to permissions set.",
      },
      {
        label: "Create events",
        tagLabel: "Profiles / Create events",
        feature: "customer_events/create",
        tooltip: "This access enables users to create profile events using the API.",
      },
    ],
  },
  {
    title: "Segments",
    items: [
      {
        label: "List all custom/lookalike segments",
        tagLabel: "Custom & lookalike segments / List all",
        feature: "foreign_segments/list",
        tooltip:
          "This access allows users to view a list of all custom and lookalike segments, even the ones they weren't personally invited to.",
      },
      {
        label: "View all custom/lookalike segments",
        tagLabel: "Custom & lookalike segments / View all",
        feature: "foreign_segments/view",
        tooltip:
          "This access lets users see all custom and lookalike segment details. They can edit these segments if they have direct “edit” access, otherwise, they have “view-only” access by default.",
      },
      {
        label: "Edit all custom/lookalike segments",
        tagLabel: "Custom & lookalike segments / Edit all",
        feature: "foreign_segments/edit",
        tooltip:
          "This access lets users edit all custom and lookalike segments. Users with this permission cannot have direct “view-only” access for these segments.",
      },
      {
        label: "Create custom/lookalike segments",
        tagLabel: "Custom & lookalike segments / Create",
        feature: "segments/create",
        tooltip: "This access enables creating custom and lookalike segments.",
      },

      {
        label: "List featured segments",
        tagLabel: "Featured segments / List all",
        feature: "featured_segments/list",
        tooltip: "This access enables users to see a list of all featured segments.",
      },
      {
        label: "View featured segments",
        tagLabel: "Featured segments / View all",
        feature: "featured_segments/view",
        tooltip: "This access enables users to view all featured segments details.",
      },
      {
        label: "Create & edit featured segments",
        tagLabel: "Featured segments / Create & edit all",
        feature: "featured_segments/edit",
        tooltip: "This access enables users to create new or edit all featured segments.",
      },

      {
        label: "Edit smart segments",
        tagLabel: "Smart segments / Edit",
        feature: "prebuilt_segments/edit",
        tooltip: "This access enables users to edit smart segments.",
      },

      {
        label: "View segment insights",
        tagLabel: "Segments / Insights",
        feature: "segments/insights",
        tooltip:
          "This access enables seeing the Insights tab within segments available according to permissions.",
      },
      {
        label: "Export to all",
        tagLabel: "Segments / Export to all",
        feature: "segments/export",
        tooltip:
          "This access enables to export segments to destinations and view the list of available destinations.",
      },

      {
        label: "View tags",
        tagLabel: "Segments / View Tags",
        feature: "data/tags",
        tooltip:
          "This access enables users to view the Tags tab within the Segments tab. Please note that in order to see segments marked with a particular tag, Segments/ List all or View/ Edit All needs to be enabled.",
      },
      {
        label: "Edit tags",
        tagLabel: "Segments / Edit Tags",
        feature: "settings/tags",
        tooltip: "This access enables users to edit the Tags tab within the Segments tab.",
      },
    ],
  },
  {
    title: "Analytics",
    items: [
      {
        label: "View diagnostic dashboard",
        tagLabel: "Analytics / Diagnostic Dashboard",
        feature: "data/dashboard",
        tooltip: "This access enables users to view the Diagnostic Dashboard tab.",
      },
      {
        label: "View licence usage",
        tagLabel: "Analytics / Licence Usage",
        feature: "data/licence_usage",
        tooltip:
          "This access enables to view information about the number of events processed in the last 30 days and the total number of events stored.",
      },
      {
        label: "View insights",
        tagLabel: "Analytics / Insights",
        feature: "data/insights",
        tooltip:
          "This access enables users to view the Insights tab. Please note that in order to see insights for each segment, access Segments/ Insights must be enabled.",
      },
      {
        label: "View reporting",
        tagLabel: "Analytics / Reporting",
        feature: "reports/view",
        tooltip: "This access enables users to view the Reporting tab.",
      },
    ],
  },
  {
    title: "Channels",
    items: [
      {
        label: "View web banners & promo codes",
        tagLabel: "Web banners / View",
        feature: "web_banners/view",
        tooltip: "This access enables users to view the Web Banners and Promo Codes tabs.",
      },
      {
        label: "Edit web banners & promo codes",
        tagLabel: "Web banners / Edit",
        feature: "web_banners/edit",
        tooltip: "This access enables users to edit the Web Banners and Promo Codes tabs.",
      },
      {
        label: "View mobile push campaigns",
        tagLabel: "Mobile Push / View",
        feature: "push_notifications/view",
        tooltip: "This access enables users to view the Mobile Push tab.",
      },
      {
        label: "Edit & run mobile push campaigns",
        tagLabel: "Mobile Push / Edit & Run",
        feature: "push_notifications/edit",
        tooltip: "This access enables users to edit the Mobile Pushes and run the campaigns.",
      },
      {
        label: "View email campaigns",
        tagLabel: "Emails / View",
        feature: "emails/view",
        tooltip: "This access enables users to view the Emails tab.",
      },
      {
        label: "Edit & run email campaigns",
        tagLabel: "Emails / Edit & Run",
        feature: "emails/edit",
        tooltip: "This access enables users to edit the Emails and run the campaigns.",
      },
      {
        label: "Edit email blocklist",
        tagLabel: "Emails / Edit Blocklist",
        feature: "emails/edit_blocklist",
        tooltip: "This access enables users to block and unblock profile email addresses.",
      },
    ],
  },
  {
    title: "Data library",
    items: [
      {
        label: "View attributes",
        tagLabel: "Data library / Attributes",
        feature: "data/attributes",
        tooltip: "This access enables users to view the Attributes tab.",
      },
      {
        label: "View events",
        tagLabel: "Data library / Events",
        feature: "data/events",
        tooltip: "This access enables users to view the Events tab.",
      },
      {
        label: "View sources & destinations",
        tagLabel: "Data library / Sources & Destinations",
        feature: "data/sources_and_destinations",
        tooltip: "This access enables users to view the Sources and Destinations tabs.",
      },
    ],
  },
  {
    title: "Administration",
    // @ts-ignore
    items: [
      {
        label: "Settings",
        tagLabel: "Administration / Settings",
        feature: "settings/global",
        tooltip:
          "This access enables users to view and edit the Settings tab within the Administration tab.",
      },
      {
        label: "Insights",
        tagLabel: "Administration / Insights",
        feature: "settings/insights",
        tooltip:
          "This access enables users to view and edit the Insights tab within the Administration tab.",
      },
      {
        label: "Reporting",
        tagLabel: "Administration / Reporting",
        feature: "reports/edit",
        tooltip: "This access enables users to edit reports within the Administration tab.",
      },
      {
        label: "Channels",
        tagLabel: "Administration / Channels",
        feature: "settings/channels",
        tooltip:
          "This access enables users to view and edit the Channels tab within the Administration tab.",
      },
      {
        label: "Lookalike audience",
        tagLabel: "Administration / Lookalike audience",
        feature: "settings/lookalike",
        tooltip:
          "This access enables users to view and edit the Lookalikes tab within the Administration tab.",
      },
      {
        label: "Entities",
        tagLabel: "Administration / Entities",
        feature: "settings/cdp_entities",
        tooltip:
          "This access enables users to edit Sources, Destinations, Attributes, Meta attributes, Attribute labels, Events, and Identity stitching tabs within the Administration tab.",
      },
      {
        label: "Users activity",
        tagLabel: "Administration / Users activity",
        feature: "data/user_activity",
        tooltip:
          "This access enables users to view the Users Activity tab within the Administration tab.",
      },
      {
        label: "Users & roles",
        tagLabel: "Administration / Users & Roles",
        feature: "settings/users",
        tooltip:
          "This access enables users to view and edit the Users and Roles tabs within the Administration tab.",
      },
      {
        label: "Logs",
        tagLabel: "Logs",
        feature: "redirects/logs",
        tooltip: "This access enables users to view Logs.",
      },
    ],
  },
]

const featureItems = featuresSections.flatMap(prop("items"))
export const allFeatures = featureItems.map(prop("feature"))

export function getFeatureTagLabel(
  feature: Feature,
  destinationsMap: Record<string, SegmentExportDestination>,
) {
  if (feature.startsWith("segments/export/")) {
    const destinationId = last(feature.split("/"))!
    return `Segments / Export: ${destinationsMap[destinationId]?.name}`
  }

  return featureItems.find(whereEq({ feature }))?.tagLabel ?? null
}

export function getFeaturesInitialFormValues() {
  return Object.fromEntries(featureItems.map(({ feature }) => [feature, "off"]))
}
