import React, { PureComponent, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmpty, range, whereEq } from "ramda"
import { showToast } from "app/toast"

// ui components
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import CustomerAvatar from "components/UI/elements/CustomerAvatar"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable/CompoundAttributeValuesTable"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
// api, helpers
import {
  shortenTextArroundPattern,
  shortenText,
  shortenTextWithoutDots,
} from "helpers/string.helper"
import { formatSearchNumber } from "helpers/number.helper"
import { getRoutePath } from "routes"
import {
  getUserFriendlyValueFormat,
  getBackendValueFromUserFriendly,
} from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
  getCompoundAttributeSubAttribute,
} from "resources/attribute/compoundAttributeUtils"

//constants
import { TOAST } from "sharedConstants"

import "./ProfilesList.scss"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import {
  useFetchCurrentUser,
  useHasAccess,
  useToggleFavoriteCustomer,
} from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import Page from "components/UI/Page/Page"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import {
  useCustomerSearch,
  useCustomerSearchStore,
} from "resources/customer/search/customerSearchQueries"
import create from "zustand"
import AttributePicker from "components/AttributePicker/AttributePicker"

class ProfilesList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      toggledFavoriteCustomerId: null,
      rowsExpanded: [],
    }
  }

  toggleFavoriteCustomer = async id => {
    this.setState({ toggledFavoriteCustomerId: id })
    try {
      await this.props.toggleFavoriteCustomer(id)
    } catch {
      // noop
    }
    this.setState({ toggledFavoriteCustomerId: null })
  }

  onSubmit = values => {
    const { attributesMap, setLatestSearch, currentUser, setSearch } = this.props

    const trimmedSearchText = values.searchTerm?.trim()

    if (
      trimmedSearchText !== undefined &&
      trimmedSearchText !== "" &&
      trimmedSearchText.length < 3
    ) {
      showToast("Please type in at least 3 characters into the search field.", TOAST.TYPE.ERROR)
      return
    }

    if (!values.attributeId) {
      // case 1) only search text, fulltext call
      if (trimmedSearchText) {
        let customerSearches = currentUser.frontend_settings?.latestSearch?.customers
        if (Array.isArray(customerSearches)) {
          if (!this.searchExists(customerSearches, trimmedSearchText, null)) {
            setLatestSearch([
              ...customerSearches.slice(-4),
              { searchText: trimmedSearchText, attributeId: null },
            ])
          }
        } else {
          setLatestSearch([{ searchText: trimmedSearchText }])
        }
      }

      setSearch({ searchTerm: trimmedSearchText, attributeId: null })
    } else {
      // convert search text from user friendly values to backend values
      const attribute = attributesMap[values.attributeId]
      const searchText = getBackendValueFromUserFriendly(trimmedSearchText, attribute?.data_type)

      let customerSearches = currentUser.frontend_settings?.latestSearch?.customers
      if (Array.isArray(customerSearches)) {
        if (!this.searchExists(customerSearches, trimmedSearchText, values.attributeId)) {
          setLatestSearch([
            ...customerSearches.slice(-4),
            { searchText, attributeId: values.attributeId },
          ])
        }
      } else {
        setLatestSearch([{ searchText, attributeId: values.attributeId }])
      }

      setSearch({ searchTerm: searchText, attributeId: values.attributeId })
    }
  }

  searchExists = (customerSearches, searchText, attributeId) =>
    customerSearches.find(whereEq({ searchText, attributeId }))

  setCustomersPaginationIterator = arrIndex => () => {
    this.props.setProfileIteratorIndex(arrIndex)
  }

  renderCustomerRow = ({ customer_entity_id, customer_attributes }, customerIndex, searchText) => {
    const { currentUser, queryResult, setProfileIteratorIndex, hasAccess, attributesMap } =
      this.props
    const additionalAttribute =
      (queryResult?.additional_attribute_id &&
        attributesMap[queryResult.additional_attribute_id]) ??
      null
    const additionalAttributeValues =
      additionalAttribute &&
      customer_attributes.find(whereEq({ attribute_id: additionalAttribute.id }))?.values
    let additionalAttributeFirstValue = additionalAttributeValues?.[0] ?? "—"
    const hasAdditionalAttributeMoreValues = additionalAttributeValues?.length > 1
    if (additionalAttribute && additionalAttributeFirstValue !== "—") {
      additionalAttributeFirstValue = getUserFriendlyValueFormat(
        additionalAttributeFirstValue,
        additionalAttribute.data_type,
      )
    }

    const isCustomerFavorite = currentUser.cdp_settings?.favourite_customers?.find(
      whereEq({ customer_entity_id }),
    )
    const showAsFavorite =
      // between the click and the response coming back from the server, already show the button in
      // the new state
      (isCustomerFavorite && this.state.toggledFavoriteCustomerId !== customer_entity_id) ||
      (!isCustomerFavorite && this.state.toggledFavoriteCustomerId === customer_entity_id)

    const onRowClick = evt => {
      if (!hasAccess.customers.detail) {
        evt.preventDefault()
      }
      if (hasAccess.customers.detail) {
        setProfileIteratorIndex(customerIndex)
      }
    }

    const rowHref = {
      pathname: getRoutePath("profiles.detail", { id: customer_entity_id }),
      state: { goBack: true, prev: "search" },
    }

    const favoriteButtonCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "favorite-button-cell", {
          "no-border": withoutBorder,
        })}
      >
        <Tippy content={showAsFavorite ? "Remove from favorites" : "Add to favorites"}>
          <button
            className={classNames("favorite-button", { "is-faved": showAsFavorite })}
            onClick={e => {
              e.preventDefault()
              this.toggleFavoriteCustomer(customer_entity_id)
            }}
            disabled={this.state.toggledFavoriteCustomerId === customer_entity_id}
          >
            <FontAwesomeIcon icon={[showAsFavorite ? "fas" : "far", "star"]} />
          </button>
        </Tippy>
      </Td>
    )

    const avatarCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-avatar-cell", {
          "no-border": withoutBorder,
        })}
      >
        <CustomerAvatar className="customer-avatar" />
      </Td>
    )

    const idCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-id", {
          "no-border": withoutBorder,
        })}
        textBigger
        textBlack
        textBold
      >
        {shortenText(customer_entity_id, 10)}
      </Td>
    )

    const additionalAttributeCell = withoutBorder =>
      additionalAttribute && (
        <Td className={classNames("customer-table-cell", { "no-border": withoutBorder })}>
          {(additionalAttributeFirstValue.length > 30 || hasAdditionalAttributeMoreValues) && (
            <React.Fragment>
              <Tippy
                content={(additionalAttributeValues ?? []).map((val, index) => {
                  return (
                    <span className="add-value" key={index}>
                      {getUserFriendlyValueFormat(val, additionalAttribute.data_type)}
                    </span>
                  )
                })}
              >
                <span>{shortenTextWithoutDots(additionalAttributeFirstValue, 30)}...</span>
              </Tippy>
            </React.Fragment>
          )}
          {additionalAttributeFirstValue.length <= 30 && !hasAdditionalAttributeMoreValues && (
            <React.Fragment>{additionalAttributeFirstValue}</React.Fragment>
          )}
        </Td>
      )

    const expandOrCompactRow = customerIndex => {
      this.setState(({ rowsExpanded }) => ({
        rowsExpanded: rowsExpanded.includes(customerIndex)
          ? rowsExpanded.filter(el => el !== customerIndex)
          : rowsExpanded.concat(customerIndex),
      }))
    }

    const chevronCellExpand = customerIndex => {
      const isRowExpanded = this.state.rowsExpanded.includes(customerIndex)
      return (
        <Td className={classNames("chevron-cell", "no-border")}>
          <DelayedTooltip content={isRowExpanded ? "Compact" : "Expand"}>
            <button
              className={classNames("expand-button")}
              onClick={e => {
                expandOrCompactRow(customerIndex)
                e.preventDefault()
              }}
            >
              <FontAwesomeIcon
                className="chevron-down"
                icon={["fas", isRowExpanded ? "chevron-up" : "chevron-down"]}
              />
            </button>
          </DelayedTooltip>
        </Td>
      )
    }

    const chevronCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "chevron-cell", {
          "no-border": withoutBorder,
        })}
      >
        <FontAwesomeIcon className="chevron-right" icon={["fas", "chevron-right"]} />
      </Td>
    )

    if (customer_attributes.length === 0) {
      return (
        <Tr
          key={`${customerIndex}-0`}
          href={rowHref}
          onClick={onRowClick}
          disabled={!hasAccess.customers.detail}
        >
          {favoriteButtonCell()}
          {avatarCell()}
          {idCell()}
          {additionalAttributeCell()}
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          {chevronCell()}
        </Tr>
      )
    }

    return customer_attributes.flatMap(({ attribute_id, values }, attributeIndex) => {
      const attribute = attributesMap[attribute_id]
      const attributesCount = customer_attributes.length

      return values.map((value = "N/A", valueIndex) => {
        let valueDOM = null
        if (isAttributeCompound(attribute.data_type)) {
          const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
          valueDOM = (
            <div className="compound-value-wrapper">
              <CompoundAttributeValuesTable subAttributes={subAttributes} values={[value]} />
            </div>
          )
        } else {
          if (value.length > 40) {
            valueDOM = (
              <React.Fragment>
                <Tippy content={value}>
                  <span>{shortenTextArroundPattern(searchText, value, 40)}</span>
                </Tippy>
              </React.Fragment>
            )
          } else {
            valueDOM = getUserFriendlyValueFormat(value, attribute.data_type)
          }
        }

        const numberOfLeadingEmptyCells = additionalAttribute ? 4 : 3
        const isCustomerFirstRow = attributeIndex === 0 && valueIndex === 0

        return (
          <Tr
            key={`${customerIndex}-${attributeIndex}-${valueIndex}`}
            href={rowHref}
            onClick={onRowClick}
            disabled={!hasAccess.customers.detail}
            className={
              this.state.rowsExpanded.includes(customerIndex) === false && !isCustomerFirstRow
                ? "expandedHidden"
                : "expandedVisible"
            }
          >
            {isCustomerFirstRow && (
              <>
                {favoriteButtonCell(attributesCount > 1)}
                {avatarCell(attributesCount > 1)}
                {idCell(attributesCount > 1)}
              </>
            )}
            {!isCustomerFirstRow &&
              range(0, numberOfLeadingEmptyCells).map(key => (
                <Td key={key} className={classNames("customer-table-cell", "smaller", "no-border")}>
                  &nbsp;
                </Td>
              ))}
            {isCustomerFirstRow && additionalAttributeCell(attributesCount > 1)}
            <Td className={classNames("customer-table-cell", { smaller: !isCustomerFirstRow })}>
              {attribute.source.name}
            </Td>
            <Td
              className={classNames("customer-table-cell", "no-wrap", {
                smaller: !isCustomerFirstRow,
              })}
            >
              {attribute.name.length > 20 && (
                <React.Fragment>
                  <Tippy content={attribute.name}>
                    <span>{shortenText(attribute.name, 20)}</span>
                  </Tippy>
                </React.Fragment>
              )}
              {attribute.name.length <= 20 && attribute.name}
            </Td>
            <Td
              className={classNames("customer-table-cell", "no-wrap", {
                smaller: !isCustomerFirstRow,
              })}
            >
              {valueDOM}
            </Td>
            {isCustomerFirstRow &&
              (attributesCount > 1 || values.length > 1 ? (
                chevronCellExpand(customerIndex)
              ) : (
                <Td></Td>
              ))}
            {!isCustomerFirstRow && (
              <Td className={classNames("customer-table-cell", "smaller")}>&nbsp;</Td>
            )}
          </Tr>
        )
      })
    })
  }

  repeatSearch = (attributeId, searchTerm) => {
    const {
      filters: { setSearchTerm, setAttributeId },
    } = this.props
    setSearchTerm(searchTerm)
    setAttributeId(attributeId)
    this.onSubmit({ searchTerm, attributeId })
  }

  renderLatestSearch = () => {
    const { currentUser, attributesMap } = this.props
    const searchValues = currentUser.frontend_settings?.latestSearch?.customers ?? []

    if (isEmpty(searchValues)) {
      return null
    }
    let attributeText = "Profile highlights"
    let sourceName = ""
    let lastSearches = searchValues
      .map(searchValue => {
        if (searchValue.attributeId) {
          const [attributeId, dimensionId] = searchValue.attributeId.split(".")
          const attribute = attributesMap[attributeId]

          if (!attribute || attribute.source.is_hidden) {
            return null
          }

          attributeText = dimensionId
            ? `${attribute.name}: ${
                getCompoundAttributeSubAttribute(dimensionId, attribute.data_type).name
              }`
            : attribute.name
          sourceName = attribute.source.name
        }

        return {
          sourceName,
          attributeText: attributeText,
          searchText: searchValue.searchText,
          attributeId: searchValue.attributeId,
        }
      })
      .filter(Boolean)

    return (
      <div className="latest-search">
        <div className="last-search-label">Last search:</div>
        {isEmpty(attributesMap) && <LoadingIndicator size="sm" fixedWidth />}
        {!isEmpty(attributesMap) &&
          lastSearches.map(searchedItem => {
            const searchText = searchedItem.searchText || searchedItem.sourceName

            return (
              <div
                key={`${searchedItem.attributeId}-${searchedItem.searchText}`}
                className="search-box"
                onClick={() => this.repeatSearch(searchedItem.attributeId, searchedItem.searchText)}
              >
                <div className="searched-text">
                  <Tippy content={searchText}>
                    <strong>{shortenText(searchText, 15)}</strong>
                  </Tippy>
                </div>
                <Tippy content={searchedItem.attributeText}>
                  <div className="bottom-text">
                    {searchedItem.attributeId !== null
                      ? shortenText(searchedItem.attributeText, 25)
                      : "Profile highlights"}
                  </div>
                </Tippy>
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    const { queryResult, searchTerm, isLoading, isFavoritesList, filters, attributesMap } =
      this.props
    const additionalAttribute = attributesMap[queryResult?.additional_attribute_id] ?? null

    const message = isFavoritesList ? (
      <p className="info-message">
        <span>Specify attribute</span> or <span>Search</span>.
      </p>
    ) : queryResult?.customers.length > 0 ? undefined : (
      <p className="info-message">
        <strong>No results found.</strong> Please try different criteria.
      </p>
    )

    return (
      <Page
        title="Profiles search"
        headerContent={
          <div className="customers-search-position">
            <div className="customers-search-form">
              <AttributePicker
                value={filters.attributeId}
                onChange={filters.setAttributeId}
                size="lg"
                placeholder="Profile highlights"
                isClearable
                stringOnly
                className="customers-list-attribute-picker"
                inputWrapperClassname="input-wrapper"
                withoutSource
                withDimensions
              />
              <SearchField
                input={{ value: filters.searchTerm, onChange: filters.setSearchTerm }}
                placeholder="Search attributes, profiles and more..."
                className="large search-box"
                autoFocus={true}
                type="text"
                onClear={() => filters.setSearchTerm("")}
                onSubmit={() =>
                  this.onSubmit({
                    attributeId: filters.attributeId,
                    searchTerm: filters.searchTerm,
                  })
                }
              />
            </div>
          </div>
        }
        className="customers-list"
      >
        {isLoading && <LoadingIndicator />}
        {!isLoading && queryResult && (
          <Paper hasHeader={true} className="customers-list-content">
            {queryResult.customers_total_count > 0 && (
              <p className="number-of-results">
                <strong>{formatSearchNumber(queryResult.customers_total_count)}</strong>{" "}
                {queryResult.customers_total_count === 1 ? "result" : "results"}
              </p>
            )}
            {this.renderLatestSearch()}
            {message !== "" && message}

            {queryResult.customers.length > 0 && (
              <Table className="customers-search">
                <Thead stickyHeader>
                  <Th></Th>
                  <Th className="avatar">&nbsp;</Th>
                  <Th>ID</Th>
                  {additionalAttribute && (
                    <Th className="additional-attribute">{additionalAttribute.name}</Th>
                  )}
                  <Th>Source</Th>
                  <Th>Attribute</Th>
                  <Th>Value</Th>
                  <Th className="chevron-head">&nbsp;</Th>
                </Thead>
                <Tbody>
                  {queryResult.customers.map((customer, index) =>
                    this.renderCustomerRow(customer, index, searchTerm),
                  )}
                </Tbody>
              </Table>
            )}
          </Paper>
        )}
      </Page>
    )
  }
}

const filtersInitialState = {
  searchTerm: "",
  attributeId: null,
}

export const useFiltersStore = create(set => ({
  ...filtersInitialState,

  reset: () => set(filtersInitialState),

  setSearchTerm: searchTerm => set({ searchTerm }),
  setAttributeId: attributeId => set({ attributeId }),
}))

export default props => {
  const { data: attributesMap = {}, isLoading: isLoadingAttributes } = useFetchAttributesMap()
  const toggleFavoriteCustomer = useToggleFavoriteCustomer()
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const hasAccess = useHasAccess()
  const { id, frontend_settings } = currentUser
  const { latestSearch = {} } = frontend_settings ?? {}
  const { setIndex } = useProfileIteratorStore()

  // This holds the immediate state of the search box
  const filters = useFiltersStore()
  // This holds the state of search parameters that we're getting results for after clicking '🔍'
  const { attributeId, searchTerm, isFavoritesList, setSearch, reset } = useCustomerSearchStore()

  const { data, isLoading, error } = useCustomerSearch({
    attributeId,
    searchTerm,
    isFavoritesList,
  })

  useEffect(() => {
    if (error) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const setLatestSearch = customers => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          latestSearch: {
            ...latestSearch,
            customers,
          },
        },
      },
    })
  }

  return (
    <ProfilesList
      {...props}
      attributesMap={attributesMap}
      currentUser={currentUser}
      toggleFavoriteCustomer={toggleFavoriteCustomer}
      setLatestSearch={setLatestSearch}
      hasAccess={hasAccess}
      setProfileIteratorIndex={setIndex}
      setSearch={setSearch}
      queryResult={data}
      isLoading={isLoading || isLoadingAttributes}
      isFavoritesList={isFavoritesList}
      searchTerm={searchTerm}
      filters={filters}
    />
  )
}
