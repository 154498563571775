import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import LookalikeSegmentDetail from "./LookalikeSegmentDetail/LookalikeSegmentDetail"
import LookalikeSegmentsList from "./LookalikeSegmentsList/LookalikeSegmentsList"

export default function LookalikeSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.lookalike")} exact component={LookalikeSegmentsList} />
      <Route
        path={getRoutePath("segments.lookalike.trash")}
        exact
        render={() => <Trash itemType="lookalike_segments" />}
      />
      <Route path={getRoutePath("segments.lookalike.detail")} component={LookalikeSegmentDetail} />
    </Switch>
  )
}
