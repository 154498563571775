import React from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm, { AttributeFormValues } from "../components/AttributeForm/AttributeForm"
import { getInitialAttributeFormValues } from "../components/AttributeForm/attributeInitialValues"
import "./AttributeDetail.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributeById, useModifyAttribute } from "resources/attribute/attributeQueries"
import { useHistory, useParams } from "react-router-dom"
import Page from "components/UI/Page/Page"
import { AttributeModifyPayload } from "resources/attribute/attributeTypes"
import { Flag } from "types/util"
import Tippy from "@tippyjs/react"

export default function AttributeDetail() {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const modifyMutation = useModifyAttribute()
  const toggleMutation = useModifyAttribute()
  const { data: attribute, isSuccess: isAttributeFulfilled } = useFetchAttributeById(id, {
    refetchOnMount: "always",
  })

  function modifyAttribute(data: AttributeModifyPayload) {
    modifyMutation.mutate(
      { id, data },
      { onSuccess: () => history.push(getRoutePath("administration.attributes")) },
    )
  }

  function toggleHidden() {
    toggleMutation.mutate({
      id: attribute!.id,
      data: { is_hidden: +!attribute!.is_hidden as Flag },
    })
  }

  function cloneAttribute() {
    history.push({
      pathname: getRoutePath("administration.attributes.create"),
      state: { attribute: { ...attribute, is_system: 0 } },
    })
  }

  const formInitValues = getInitialAttributeFormValues(attribute) as AttributeFormValues
  const isSystem = attribute?.is_system === 1

  return (
    <Page
      className="detail-attribute"
      title="Edit attribute"
      headerContent={
        <div className="header-buttons">
          {attribute && (
            <>
              {!isSystem && (
                <Tippy content="Hidden source" disabled={!attribute.source.is_hidden}>
                  <span>
                    <Button
                      color="red"
                      icon={attribute.is_hidden === 1 ? "eye" : "eye-slash"}
                      iconStyle="far"
                      variant="outlined"
                      onClick={toggleHidden}
                      loading={toggleMutation.isLoading}
                      disabled={modifyMutation.isLoading || Boolean(attribute.source.is_hidden)}
                    >
                      {attribute.is_hidden === 1 ? "Show" : "Hide"}
                    </Button>
                  </span>
                </Tippy>
              )}
              <Button color="grey" icon="clone" variant="outlined" onClick={cloneAttribute}>
                Copy
              </Button>
              <Button
                color="grey"
                variant="outlined"
                onClick={() => {
                  history.push(getRoutePath("administration.attributes"))
                }}
              >
                Cancel
              </Button>
              <Button loading={modifyMutation.isLoading} form="attributeForm" type="submit">
                Save
              </Button>
            </>
          )}
        </div>
      }
    >
      {isAttributeFulfilled ? (
        <AttributeForm
          onSubmit={modifyAttribute}
          initialValues={formInitValues}
          isSystem={isSystem}
        />
      ) : (
        <LoadingIndicator />
      )}
    </Page>
  )
}
