import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./NameForm.scss"
import Tippy from "@tippyjs/react"
import TextInput from "components/UI/elements/TextInput/TextInput"

export default class NameForm extends Component {
  constructor(props) {
    super(props)
    const name = this.props.initialValues.name ?? "Undefined name"
    this.state = {
      editMode: false,
      fontSize: this.getFontSizeBasedOnName(name),
      value: name,
    }
  }

  componentDidUpdate(prevProps) {
    const namePrev = prevProps.initialValues.name
    const nameNow = this.props.initialValues.name
    if (namePrev !== nameNow) {
      this.updateFontSize(nameNow)
    }
  }

  getFontSizeBasedOnName = name => {
    const { fontSizeDecreaseOffset } = this.props
    return name.length > 30 + fontSizeDecreaseOffset ? "14" : "15"
  }

  updateFontSize = name => {
    const fontSize = this.getFontSizeBasedOnName(name)
    if (this.state.fontSize !== fontSize) {
      this.setState({
        fontSize,
      })
    }
  }

  onSubmit = () => {
    const { allowEmptyValue } = this.props
    const { value } = this.state
    if (!value.name && !allowEmptyValue) {
      this.setState({
        error: true,
      })
    } else {
      const { handleNameChange } = this.props
      this.toggleNameForm()
      if (handleNameChange) {
        handleNameChange(value)
      }
    }
  }

  onChange = evt => {
    const { allowEmptyValue } = this.props
    const value = evt.target.value
    this.setState({ value })
    if (!value && !allowEmptyValue) {
      this.setState({
        error: true,
      })
    } else {
      if (this.state.error) {
        this.setState({
          error: false,
        })
      }
      this.updateFontSize(value)
    }
  }

  toggleNameForm = () => {
    const { toggleEditMode, editMode } = this.props
    if (toggleEditMode) {
      toggleEditMode(() => {
        if (!editMode) {
          // will be opened now
          this.fieldRef.focus()
        } else {
          this.setState({
            error: false,
          })
        }
      })()
    } else {
      this.setState(
        prevState => ({
          editMode: !prevState.editMode,
          error: false,
        }),
        () => {
          this.fieldRef.focus()
        },
      )
    }
  }

  cancelEditing = () => {
    const name = this.props.initialValues.name ?? "Undefined name"
    this.setState({ value: name })
    this.toggleNameForm()
    this.updateFontSize(name)
  }

  render() {
    const { isEditable, size, label = "", readOnlySign, placeholder = "" } = this.props
    const { error, fontSize, value } = this.state
    const editMode = this.props.editMode ? this.props.editMode : this.state.editMode

    let showTooltipOnHover = false
    if (this.fieldRef) {
      if (this.fieldRef.scrollWidth > this.fieldRef.clientWidth) {
        showTooltipOnHover = true
      }
    }
    return (
      <>
        {isEditable && (
          <div className={`name-form ${size === "big" ? "big" : "small"}`}>
            <Tippy content={value} disabled={!(showTooltipOnHover && !editMode)}>
              <div className={`tooltip-trigger ${editMode ? "edit" : ""}`} />
            </Tippy>
            <div
              className={
                editMode
                  ? `name edit-mode fs-${fontSize}`
                  : `name fs-${fontSize} ${value ? "" : "placeholder"}`
              }
            >
              {value ?? placeholder}
            </div>
            <TextInput
              label={label}
              disabled={!editMode}
              ref={el => {
                this.fieldRef = el
              }}
              className={
                editMode ? `text-field edit-mode fs-${fontSize}` : `text-field fs-${fontSize}`
              }
              value={value}
              onChange={this.onChange}
              maxLength={65}
              showWhitespaceWarning={false}
            />
            {editMode && (
              <>
                <button type="button" className="close-icon-btn" onClick={this.cancelEditing}>
                  <FontAwesomeIcon className="icon" icon={["far", "times"]} />
                </button>
                {!error && (
                  <button onClick={this.onSubmit} className="link-button edit-mode">
                    Save
                  </button>
                )}
              </>
            )}
            {!editMode && isEditable && (
              <button type="button" className="link-button" onClick={this.toggleNameForm}>
                Edit
              </button>
            )}
          </div>
        )}
        {!isEditable && (
          <div className={`name-not-editable fs-${fontSize}`}>
            {label !== "" && <label className="label">{label}</label>}
            <h2>
              {value}
              {readOnlySign ? (
                <>
                  {" "}
                  <span className="read-only-sign">
                    <FontAwesomeIcon icon={["fal", "eye"]} className="eye" /> View-only
                  </span>
                </>
              ) : (
                ""
              )}
            </h2>
          </div>
        )}
      </>
    )
  }
}

NameForm.defaultProps = {
  fontSizeDecreaseOffset: 0,
}

NameForm.propTypes = {
  initialValues: PropTypes.object,
  handleNameChange: PropTypes.func,
  isEditable: PropTypes.bool.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  readOnlySign: PropTypes.bool,
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  fontSizeDecreaseOffset: PropTypes.number,
  placeholder: PropTypes.string,
  allowEmptyValue: PropTypes.bool,
}
