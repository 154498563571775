import { useCallback, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import Modal from "components/UI/elements/Modal"
import SelectField from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required } from "helpers/validators.helper"
import {
  useFetchPushNotificationAndroidOptions,
  useFetchPushNotificationIosOptions,
} from "resources/channel/channelQueries"
import {
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationFirebaseApp,
} from "resources/channel/channelTypes"

import styles from "./ApplicationModal.module.scss"

type FormValues = Pick<PushNotificationApp, "name"> & {
  androidVersion: PushNotificationFirebaseApp["app_id"]
  iosVersion: PushNotificationFirebaseApp["app_id"]
}

type ApplicationModalProps = {
  isLoading: boolean
  open: boolean
  application?: PushNotificationApp
  handleClose: () => void
  onSubmit: (values: PushNotificationAppPayload) => void
}

export function ApplicationModal({
  application,
  isLoading,
  open,
  handleClose,
  onSubmit,
}: ApplicationModalProps) {
  const { data: androidOptions } = useFetchPushNotificationAndroidOptions()
  const { data: iosOptions } = useFetchPushNotificationIosOptions()

  const {
    control,
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { isSubmitted },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      androidVersion: "",
      iosVersion: "",
    },
  })

  useEffect(() => {
    if (!open) return

    if (application)
      reset({
        name: application.name,
        androidVersion:
          androidOptions?.find(({ value }) => application.app_ids.includes(value))?.value ?? "",
        iosVersion:
          iosOptions?.find(({ value }) => application.app_ids.includes(value))?.value ?? "",
      })
    else reset({ name: "", androidVersion: "", iosVersion: "" })
  }, [application, androidOptions, iosOptions, open, reset])

  const closeModal = useCallback(() => {
    handleClose()
    reset()
  }, [handleClose, reset])

  return (
    <Modal
      open={open}
      title={application ? "Edit application" : "Add application"}
      handleClose={closeModal}
    >
      <form
        onSubmit={handleSubmit(values =>
          onSubmit({
            app_ids: [values.androidVersion, values.iosVersion].filter(Boolean),
            name: values.name,
          }),
        )}
        className={styles.fields}
      >
        <TextInput
          label="Application name"
          placeholder="Application name"
          {...register("name", { validate: required })}
        />
        <Controller
          control={control}
          name="androidVersion"
          rules={{
            validate: {
              both: (androidVersion, { iosVersion }) => {
                if (!androidVersion && !iosVersion) return "At least one app has to be added."
                else return undefined
              },
            },
          }}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <SelectField
              isSimpleValue
              error={error?.message}
              input={{
                value,
                onBlur,
                onChange: (newValue: PushNotificationFirebaseApp["app_id"]) => {
                  onChange(newValue)
                  if (isSubmitted) trigger("iosVersion")
                },
              }}
              label="Android version"
              placeholder="Android version"
              options={androidOptions ?? []}
            />
          )}
        />
        <Controller
          control={control}
          name="iosVersion"
          rules={{
            validate: {
              both: (iosVersion, { androidVersion }) => {
                if (!androidVersion && !iosVersion) return "At least one app has to be added."
                else return undefined
              },
            },
          }}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <SelectField
              isSimpleValue
              error={error?.message}
              input={{
                value,
                onBlur,
                onChange: (newValue: PushNotificationFirebaseApp["app_id"]) => {
                  onChange(newValue)
                  if (isSubmitted) trigger("androidVersion")
                },
              }}
              label="iOS version"
              placeholder="iOS version"
              options={iosOptions ?? []}
            />
          )}
        />
        <div className={styles.buttons}>
          <Button color="grey" size="md" variant="text" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button loading={isLoading} color="green" size="md" type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  )
}
